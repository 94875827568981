<template>
    <!-- BEGIN: Content-->
    <div class="booking mt-3">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-3 pr-4">
                <v-col md="12" sm="12" class="d-flex py-0 my-0" style="justify-content:space-between;align-items:center">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-1 py-0" style="padding-top:0 !important;">
            <v-card class="card-shadow pb-0 pt-0">
              <div style="width:100%;height:.3rem !important">
                      <v-progress-linear
                        color="#008000"
                        buffer-value="0"
                        stream
                        height="3"
                        :active="tableloader"
                      ></v-progress-linear>
                    </div>
              <v-card-title style="display:flex;align-items:center;justify-content:flex-end;height:5rem">
                <BookingSearch/>
              </v-card-title>
              <v-card-text class="px-0 pb-0 cardtext">

                    <div class="classloader">
                       <Spinner name="circle" color="#008000" fadeIn="quarter" width="150px" height="150px" v-show="tableloader"/>
                    </div>
                    <div class="empty-state" v-if="allbookings.length === 0 && !tableloader">
                      <div class="empty-state__content">
                        <div class="empty-state__icon">
                          <v-img src="@/assets/icon.png" alt=""></v-img>
                        </div>
                        <div class="empty-state__message">No records available.</div>
                      </div>
                    </div>

                    <v-virtual-scroll
                      :items="allbookings"
                      :item-height="23"
                      renderless 
                      id="virtualscroll"
                      class="allbooking"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell" :class="item.color" @click="bookingdetailsupdate(item.id)">
                            <div class="name">{{ item.name }}</div>
                          </div>
                          <div class="tcell" :class="item.color">
                            {{ item.time }}
                          </div>
                          <div class="tcell" :class="item.color">
                            {{ item.date }}
                          </div>
                          <div class="tcell" :class="item.color">
                            <div>{{ item.telephone }}</div>
                          </div>
                          <div class="tcell" :class="item.color">
                            {{ item.organisation_name }}
                          </div>
                      
                          <div class="tcell" :class="item.color">
                            {{ item.from }}
                          </div>
                          <div class="tcell" :class="item.color">
                            {{ item.to }}
                          </div>
                         
                          <div class="tcell" :class="item.color">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        small
                                        v-on="on">
                                      <v-icon>ti-arrow-circle-down</v-icon>
                                    </v-btn>
                                  </template>
                                  
                                  <v-list-item-group
                                    color="primary"
                                    class="actions"
                                    >

                                    <v-list-item
                                      @click="bookingprofile(item.id)"
                                      >
                                          <v-list-item-icon>
                                            <v-icon color="#000000">mdi-account</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Profile</v-list-item-title>
                                          </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                      link
                                      :to='{name:"bookingEdit",params:{id:item.id}}'
                                      >
                                          <v-list-item-icon>
                                            <v-icon color="#000000">mdi-pencil</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Edit</v-list-item-title>
                                          </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                      @click="tripprocess(item.id, 'confirmtrip')"
                                      v-if="item.status == 'Active' && item.authorization == 'true' && item.authorization != 'declined'"
                                      >
                                          <v-list-item-icon>
                                            <v-icon color="#000000">mdi-check</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Confirm</v-list-item-title>
                                          </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                      @click="cancelbooking(item.id)"
                                      v-if="item.status !== 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'"
                                      >
                                          <v-list-item-icon>
                                            <v-icon color="#000000">mdi-close</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Cancel</v-list-item-title>
                                          </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        @click="bookingdocument(item.id)"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-file-document-multiple-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Documents</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                  </v-list-item-group>
                                 
                            </v-menu>
                          </div>
                          <div class="tcell" :class="item.color">
                              <div v-if="item.vehicles.length > 0" class="name"><span v-for="(res,i) in item.vehicles" :key="i">{{ res.vehicle.name }} - {{ res.vehicle.registration }},</span></div>
                              <div v-else>Pending</div>
                          </div>
                          <div class="tcell" :class="item.color">
                            <v-chip
                              dark
                              small
                            >
                              {{ item.vehicletype }}
                            </v-chip>
                          </div>
                          <div class="tcell" :class="item.color">
                            {{ item.request_category }}
                          </div>
                          <div class="tcell" :class="item.color">
                            <span v-if="item.status && item.authorization == 'true'">{{ item.status }}</span>
                            <v-chip
                              dark
                              color="danger"
                              small
                              v-if="item.authorization == 'false'"
                            >
                              <v-icon>mdi-account-lock</v-icon>
                            </v-chip>
                            <v-chip
                                  dark
                                  color="warning"
                                  small
                                  v-if="item.authorization == 'declined'"
                                >
                                  <v-icon>mdi-cancel</v-icon>
                                </v-chip><br>
                            <small style="font-size:.69rem;">
                                      ::{{ item.decline }}
                                    </small>
                          </div>
         
                          <div class="tcell" :class="item.color">
                              {{ item.flight }}
                          </div>
                          <div class="tcell" :class="item.color">
                            <span
                              v-if="item.remarks !== null"
                            >
                              {{ item.remarks }}
                            </span>
                          </div>
                       <!--    <div class="tcell" :class="item.color">
                            <span
                              v-if="item.cancellation_reason !== null"
                            >
                              {{ item.cancellation_reason }}
                            </span>
                          </div> -->
                      </template>
                    </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

     <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>

      <CancelBooking v-model="canceldialog" :selected="selected"/>
      <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
      <BookingDocument v-model="bookingdocumentdialog" v-if="bookingid !== null"/>

      <v-dialog
          v-model="progressdialog"
          hide-overlay
          persistent
          width="300"
          >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

    </div>
</template>
<style scoped>
  @import "@/assets/css/allbookings_scoped";
</style>
<style>
  @import "@/assets/css/allbookings";
</style>
<script>
import bookingcomponent from '@/components/bookings/bookingcomponent'
import bookingcounts from '@/components/bookings/bookingcounts'

import CancelBooking from '@/components/bookings/submodules/today/cancelbooking'
import ClientProfile from '@/components/bookings/submodules/today/clientprofile'
import BookingSearch from '@/components/subcomponents/bookingSearch'
import UpdateDetail from '@/components/subcomponents/updatedetails'
import BookingDocument from '@/components/subcomponents/bookingDocument'

import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import Vue from 'vue'
import { mapState } from 'vuex'
import {ability} from '@/services/ability'
import { format, subDays } from 'date-fns'
import $ from 'jquery';
import Spinner from 'vue-spinkit'
var data = { soundurl : 'https://absolutebackend.tenwebtechnologies.com/notif.wav'} 

export default {
    name:"bookingList",
    components: { VueGooglePlaces,bookingcomponent,bookingcounts, CancelBooking, ClientProfile, BookingSearch,Spinner,UpdateDetail,BookingDocument },
    data(){
        return {
          progressdialog: false,
          canceldialog: false,
          menu3: false,
          menu4: false,
           fav: true,
           height: '100%',
          message: false,
          hints: true,
          reveal: false,
          labelpickupl: "",
          labeldropl:"",
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          valid: true,
          menu1:false,
          menu2:false,
          menu:false,
          search:'',
          searchmenu:false,
          google:gmapApi,
          assignvehicledialog: false,
          profiledialog: false,
          messageclientdialog: false,
          bookingdocumentdialog: false,
          searchResults: [],
          service: null,
          late:"#FF2D2D",
          in15:"#FFA500",
          in30:"#68B8E3",
          in2:"#FF0000",
          over2:"#FFF000",
          cancelled:"#C0C0C0",
          item:{
              distance: "",
              to: "",
              from: "",
              fromlat: "",
              fromlong: "",
              tolat: "",
              tolong: "",
              name: "",
              telephone:"",
              orderedby:"",
              pickupdate:"", 
              pickuptime:"",
              organisation_id:"",
              vehicle_id:"",
              flight:"",
              frequency:"",
              enddate:"",
              remarks:"",

              updateID: "",
              _method:"patch"
            },
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'All Bookings',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Passenger', align: 'start', value: 'name' },
            { text: 'Time', value: 'time', filterable: false },
            { text: 'Date', value: 'date', filterable: false },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Company', value: 'organisation_name' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Action', value: 'action' },
            { text: 'Assigned Vehicle', value: 'assigned' },
            { text: 'Car Option', value: 'vehicletype_type' },
            { text: 'Category', value: 'request_category' },
            { text: 'Status', value: 'status' },
            { text: 'Flight', value: 'flight' },
            { text: 'Remarks', value: 'remarks' },
            // { text: 'Cancellation', value: 'reason' },
           ],
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
            updateModal: null,
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            assignuservehicle: null,
            toassinguser:[],
            toassignuserid: null,
            toassignuserdriver: "",
            toassignuservehicle: "",
            toassigneduservehicle: null,
            messageuserid: null,
            start: 0,
            rowHeight: 25,
            perPage: 50,

            timeout: null,
            searchstatus:null,
            searchStatus: '',
            dates: {},
            date: "",
            daterange: {
              start: '',
              end: ''
            },
            show: false,
            rangedetails: null,
            closeIcon: 'mdi-close',
            profiledialog: false,
            profiledetails: [],
            fromdate: null,
            todate: null,
            selected:null,
            reason: '',
            updatedetails: [],
            updatedialog: false,
          }
    },
    beforeMount() {
      if(!ability.can('booking-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      if(this.allbookings.length ==0) {
        this.getBookings()
        this.$store.state.tableloader = true
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

      this.$loaddata()
 
    },
    watch: {
        booksearch: function(newVal, oldVal) {
            this.search = newVal
        }
    },
    computed: {
      tableloader() {
        return this.$store.state.tableloader
      },
      booksearch() {
        return this.$store.state.searchbooking 
      },

      booking() {

        return this.$store.state.allbookings.filter(item => {

          this.start = 0

          let name = "",ref_no = "",time = "",date = "",telephone = "",organisation = "",from = "", to = "",vehicletype = "",orderedby = "",status = "",category = "";
          if(item.ref_no != null && item.ref_no != 'null') {ref_no = item.ref_no}
          if(item.name != null && item.name != 'null') {name = item.name}
          if(item.time != null && item.time != 'null') {time = item.time}
          if(item.date != null && item.date != 'null') {date = item.date}
          if(item.telephone != null && item.telephone != 'null') {telephone = item.telephone}
          if(item.from != null && item.from != 'null') {from = item.from}
          if(item.to != null && item.to != 'null') {to = item.to}
          if(item.organisation_name != null && item.organisation_name != 'null') {organisation = item.organisation_name}
          if(item.vehicletype != null && item.vehicletype != 'null') {vehicletype = item.vehicletype_type}
          if(item.orderedby != null && item.orderedby != 'null') {orderedby = item.orderedby}
          if(item.status != null && item.status != 'null') {status = item.status}
            if(item.request_category != null && item.request_category != 'null') {category = item.request_category}

            if(this.search == null || this.search == 'null') {
              this.search = ''
            }

          return name.toLowerCase().includes(this.search.toLowerCase()) || ref_no.toLowerCase().includes(this.search.toLowerCase()) || time.includes(this.search) || date.includes(this.search) || telephone.includes(this.search) || organisation.toLowerCase().includes(this.search.toLowerCase()) || from.toLowerCase().includes(this.search.toLowerCase()) || to.toLowerCase().includes(this.search.toLowerCase()) || vehicletype.toLowerCase().includes(this.search.toLowerCase()) || orderedby.toLowerCase().includes(this.search.toLowerCase()) || status.toLowerCase().includes(this.search.toLowerCase()) || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search)) || category.toLowerCase().includes(this.search.toLowerCase())
        })
      },

      allbookings() {

        return this.booking

      },
      bookingid() {
        return this.$store.state.bookingid
      },

    },
    methods:{
      bookingdocument(id) {
        let details = this.allbookings.filter(booking => booking.id === id )

        this.$store.state.bookingid = parseInt(details[0].id)
        this.$store.state.documents = []

        this.$store
          .dispatch('getDocuments',parseInt(details[0].id))
          .then(() => {})
          .catch(() => {})

        this.bookingdocumentdialog = true
      },

      cleardate () {
        this.date = ''
        this.menu = false
      },
      onNoResult () {},
      clearSearch() {
        this.dates = {}
        this.daterange.start = ''
        this.daterange.end = ''
      },
      clearStatus() {
        this.searchStatus = ''
      },
    bookingprofile(id) {
      this.$store.state.bookingid = id
      this.profiledialog = true
    },
    bookingdetailsupdate(id) {
        this.updatedetails = this.allbookings.filter(booking => booking.id === id )
        this.updatedialog = true
      },
     getBookings(){

        this.$store
          .dispatch('getBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },

      cancelbooking(id) {
          let index = this.booking.filter(item => item.id === id )
            if(index.length > 0) {

              this.selected = index[0]
              this.canceldialog = true

            }
        },

        tripprocess(id, status){

          this.progressdialog = true

          navigator.geolocation.getCurrentPosition(geolocation => {

              axios.get(`/booking/trip-process/${id}/${status}/${geolocation.coords.latitude}/${geolocation.coords.longitude}`).then(response=>{
                this.progressdialog = false
                if(response.data.status == parseInt(200)) {
                  this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                }else {
                  this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
                }
                
              })
              .catch(() => {
                this.progressdialog = true
                this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
                console.log("Unexpected error occured")
              })

          })

        },
 
    }
}
</script>