<template>
	<div>
		<!-- assign cancel -->
      <v-dialog
        v-model="show"
        max-width="450px"
        >
        <v-card v-if="selected">

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Cancel Booking</span>
          </v-card-title>

          <v-card-text class="cancel">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                    outlined
                      label="Client"
                      readonly
                      :value="selected.name"
                      prepend-inner-icon="mdi-account"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                    outlined
                      label="Telephone"
                      readonly
                      :value="selected.telephone"
                      prepend-inner-icon="mdi-phone"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                    outlined
                      label="From"
                      readonly
                      :value="selected.from"
                      prepend-inner-icon="mdi-map-marker"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                    outlined
                      label="To"
                      readonly
                      :value="selected.to"
                      prepend-inner-icon="mdi-map-marker"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Reason"
                      v-model="reason"
                      :rules="fieldRules"
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="sendcancel"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end cancel -->
	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'cancelbooking',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        reason: "",

	}),
	methods: {
		async sendcancel() {
          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

              const config = { headers: { 'Content-Type': 'multipart/form-data' } };
              let fd = new FormData();
              fd.append('reason',this.reason)
              fd.append('id',this.selected.id)

              await axios.post('/booking/cancel-booking', fd, config).then(response=>{
                this.loading = false
                this.disabled = false
                if(response.data.status === 200) {
                  this.$store.dispatch('bookingEdit', response.data.data)
                  this.$toast.success('Booking was cancelled successfully','Success',{position: 'topRight',timeout: 7000,})
                  this.reason = ''
                  this.show = false
                }
              }).catch(error=>{
                this.loading = false
                this.disabled = false
                  console.log(error)
              })

            }
        },
	},
	props: {
	     value: Boolean,
	     selected: Object
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
	},
	created() {
     
    }
}
</script>