<template>
	<div>
	  <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="380px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Waitings</span>
          </v-card-title>

          <v-card-text style="margin-bottom:3.5rem">
              <v-row
                v-for="(waiting, indx) in waitings" 
                  :key="indx"
                  style="display:flex;align-items:center;justify-content:space-between"
                  class="mx-0 my-2"
                >
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                  style="margin-top:-.7rem"
                  >
                  <label>Select start time*</label><br>
                  <vue-timepicker v-model="waiting.starttime" manual-input :rules="fieldRules" :name="`waitings[${indx}][starttime]`"></vue-timepicker>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                  style="margin-top:-.7rem"
                  >
                  <label>Select end time*</label><br>
                  <vue-timepicker v-model="waiting.endtime" manual-input :rules="fieldRules" :name="`waitings[${indx}][endtime]`"></vue-timepicker>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    >
                    <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                      <v-icon @click="addWaiting" class="mx-3">mdi-plus</v-icon>
                      <v-icon @click="removeWaiting(indx)">mdi-close</v-icon>
                    </div>
                </v-col>
              </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end client profile -->
	</div>
</template>

<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
    font-weight: 500;
    height: 25px !important;
}
.clientprofile td{
  white-space: normal !important; 
}
</style>
<style>
    .vue__time-picker-dropdown, .vue__time-picker .dropdown{
    z-index: 10001 !important;
  }
  .vue__time-picker input.display-time {
    flex: 1 1 auto !important;
    line-height: 20px !important;
    border: 0;
    border-bottom: 1px solid #949494 !important;
    min-width: 100% !important;
    width: 100% !important;
    height: 1.2em !important;
}
.vue__time-picker-dropdown, .vue__time-picker .dropdown{
  width: 60%;
}
.vue__time-picker-dropdown .select-list, .vue__time-picker .dropdown .select-list{
  width:100%;
}
input.display-time:focus{
  border-bottom: 1px solid #F97B12 !important;
  box-shadow: none !important;
  outline: none;
}
.vue__time-picker input.display-time {
    flex: 1 1 auto !important;
    line-height: 20px !important;
    border: 0 !important;
    border-bottom: 1px solid #949494 !important;
    min-width: 100% !important;
    width: 100% !important;
}
.vue__time-picker{
  width: 100% !important;
}
.vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker .dropdown ul li:not([disabled]).active:hover{
  background:  #F97B12;
}
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker'

export default {
	name: 'waiting',
	components: {
    VueTimepicker
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        waitings: [
          {
            starttime: "",
            endtime: "",
          }
        ],
	}),
	methods: {
    addWaiting () {
        this.waitings.push({
          starttime: "",
          endtime: "",
        })
      },
      removeWaiting (index) {
        this.waitings.splice(index, 1);
      },
    submit() {
      if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('waitings',JSON.stringify(this.waitings))
          fd.append('id',this.waitingdetails[0].id)

          axios.post('/booking/savewaitings', fd, config).then(response=>{

            if(response.data.status === parseInt(201)) {
              this.$toast.success("Waiting time added successfully",'Success',{position: 'topRight',timeout: 7000,})
            }

            this.waitings = []
            this.waitings.push({
              starttime: "",
              endtime: "",
            })

            this.show = false
            this.loading = false
            this.disabled = false
            
          })
          .catch(() => {
            this.loading = false
            this.disabled = false
          })

      }
    }
  },
	props: {
	     value: Boolean,
	     waitingdetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>