<template>
    <!-- BEGIN: Content-->
    <div class="invoiceDetail mt-3">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
           
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-0 mt-2">
              <v-row class="px-3 py-0">
                <v-col cols="12" ms="12">
                  <v-form class="py-3"  enctype="multipart/form-data" v-model="valid" ref="form">
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        xl="2"
                        class="py-0"
                        >
                      <date-range-picker
                          ref="picker"
                          :showWeekNumbers="showWeekNumbers"
                          :showDropdowns="showDropdowns"
                          :autoApply="autoApply"
                          opens="right"
                          :always-show-calendars="alwaysshowcalendars"
                          v-model="dateRange"
                          @select="updateValues"
                         >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                <v-text-field
                                  label="Select date range"
                                  :rules="fieldRules"
                                  v-model="date"
                                  solo
                                  hide-details
                                ></v-text-field>
                            </template>
                        </date-range-picker>
                    </v-col>

                    <v-col
                        cols="12"
                        md="3"
                        xl="2"
                        class="py-0"
                      >
                        <v-autocomplete
                            :items="organisations"
                            label="Select organisation"
                            v-model="item.organisation_id"
                            clearable
                            @change="selectOrg"
                            @click:clear="item.organisation_id = ''"
                            item-text="name"
                            item-value="id"
                            :search-input.sync="searchorganisation"
                            solo
                            hide-details
                          ></v-autocomplete>
                      </v-col>

                    <v-col
                        cols="12"
                        md="1"
                        sm="4"
                        xl="2"
                        class="py-0"
                      >
                        <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="fetchreport"
                          >
                            Search
                          </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        md="5"
                        sm="5"
                        xl="6"
                        class="py-0"
                        style="float:right;text-align:right !important;display:flex !important;justify-content:flex-end !important;align-items:flex-end"
                      >

                      <v-btn
                            color="#000000"
                            class="mx-1 mb-2 white--text"
                            depressed
                            :loading="loadingpdf"
                            @click="exportpdf"
                          >
                            <v-icon
                              dark
                            >
                              mdi-file-pdf-box
                            </v-icon>
                          </v-btn>

                      <v-btn
                        color="#dddddd"
                        class="mb-2 black--text"
                        depressed
                        :loading="loadingexcel"
                        @click="exportexcel"
                      >
                        <v-icon
                          dark
                        >
                          mdi-file-excel-box
                        </v-icon>
                      </v-btn>
                        
                    </v-col>

                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="position:relative !important;z-index:1 !important;margin-bottom:0 !important">
          <v-col cols="12">
            <v-card class="card-shadow py-3 mt-3">
              <v-row class="mb-1 mt-1">
                
              </v-row>
              <v-row style="margin-bottom:0 !important" class="px-3 mt-2">
                <v-col md="12" cols="12" sm="12" class="px-1 filters" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between">

                  <div class="px-3">
                    <p style="font-size:1.2rem !important;font-weight:500 !important;margin-top:-1rem !important">Invoice Details</p>
                    <p style="font-size:.85rem !important;font-weight:400 !important" v-if="orgname == null">Absolute Cabs</p>
                    <p style="font-size:.85rem !important;font-weight:400 !important" v-else>{{ orgname }}</p>
                    <p style="font-size:.85rem !important;margin-top:-1rem !important;font-weight:400 !important;margin-top:.4rem">From {{ moment(daterange.start).format('MMMM Do YYYY') }} To {{ moment(daterange.end).format('MMMM Do YYYY') }}</p>
                  </div>

                  <v-col md="2" cols="12" sm="12" class="px-1 cardheading" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                    <v-text-field
                      v-model="searchfilter"
                      label="Search"
                      solo
                    ></v-text-field>
                  </v-col>

                </v-col>
              </v-row>
              <v-data-table
                dense
                :headers="fields"
                :items="allinvoices"
                class="elevation-0"
                loading="false"
                :search="searchfilter"
                loading-text="Loading... Please wait"

                disable-pagination
                hide-default-footer
                id="virtual-scroll-table"
                v-scroll:#virtual-scroll-table="onScroll"
                fixed-header
              >

                <template
                  v-if="start > 0"
                  v-slot:body.prepend
                >
                  <tr>
                    <td
                      :colspan="fields.length"
                      :style="'padding-top:'+startHeight+'px !important'"
                    >
                    </td>
                  </tr>
                </template>
                <template
                  v-if="start + perPage < this.sinvoices.length"
                  v-slot:body.append
                >
                  <tr>
                    <td
                      :colspan="fields.length"
                      :style="'padding-top:'+endHeight+'px !important'"
                    >
                    </td>
                  </tr>
                </template>

                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                        v-for="item in items"
                        :key="item.id"
                        :search="search"
                        style="cursor:pointer !important"
                      >
                        <td>
                          <p class="text--primary mb-0" v-if="item.status == 'DRAFT'" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;color:darkgrey !important">{{ item.status }}</p>
                        <p class="text--primary mb-0" v-if="item.status == 'PAID'" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;color:darkgreen !important">{{ item.status }}</p>
                        <p class="text--primary mb-0" v-if="item.status == 'OVERDUE'" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;color:#F98525 !important">{{ item.status }}</p>
                        </td>
                        <td>{{ moment(item.date).format('MMMM Do YYYY') }}</td>
                        <td>{{ moment(item.due_date).format('MMMM Do YYYY') }}</td>
                        <td>
                          <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                          :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink>
                        </td>
                        <td>
                          <span v-if="item.organisation !== null">{{ item.organisations[0].organisation.name }}</span>
                          <span v-else>Private</span>
                        </td>
                        <td>
                          <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                          :to='{name:"showInvoice",params:{id:item.id}}'>KES<span>
                          {{ item.amount | currency(' ') }}</span></RouterLink>
                        </td>
                        <td>
                          <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                          :to='{name:"showInvoice",params:{id:item.id}}'>KES<span>{{ 
                            (((item.amount - item.payments.reduce((acc, val) => acc + val.paidamount, 0))-item.creditnotes.reduce((acc, val) => acc + val.amount, 0))+(item.debitnotes.reduce((acc, val) => acc + val.amount, 0)+item.payments.reduce((acc, val) => acc + val.reversals.reduce((acc1, val1) => acc1 + val1.amount, 0), 0))) | currency(' ') }}</span></RouterLink>
                        </td>
                    </tr>
                    <tr>
                      <td colspan="5" style="font-size:1rem !important">Total</td>
                      <td style="font-size:1rem !important">
                          KES{{ invoices.reduce((acc, val) => acc + val.amount, 0) | currency(' ') }}
                      </td>
                      <td style="font-size:1rem !important">
                        KES{{ 
                          (((invoices.reduce((acc, val) => acc + val.amount, 0) - 
                          invoices.reduce( (acc, item) => 
                          {  
                            return acc += item.payments.reduce((acc1, val) => acc1 + val.paidamount, 0); 
                          }, 0))-invoices.reduce( (acc, item) => 
                          {  
                            return acc += item.creditnotes.reduce((acc1, val) => acc1 + val.amount, 0);

                          }, 0))+((invoices.reduce( (acc, item) => 
                          {  
                            return acc += item.debitnotes.reduce((acc1, val) => acc1 + val.amount, 0);

                          }, 0)
                          )+(invoices.reduce( (acc, item) => 
                          {  
                            return acc += item.payments.reduce((acc1, val) => acc1 + val.reversals.reduce((acc2, val2) => acc2 + val2.amount, 0), 0);

                          }, 0)
                          ))) | currency(' ')
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>
</template>
<style scoped>
    .v-data-table /deep/ .sticky-header {
      position: sticky !important;
      top: var(--toolbarHeight) !important;
    }

    .v-data-table /deep/ .v-data-table__wrapper {
      overflow: unset !important;
    }

   #virtual-scroll-table {
      height: 73.5vh;
      overflow: auto;
    }

    .v-data-table__wrapper td, .v-data-table-header th{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th,.v-data-table-header > th{
        max-width: 170px !important;
        font-weight: 600;
        border:1px solid var(--border-color) !important;
        height:10px !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import {ability} from '@/services/ability'
import { format, parseISO } from 'date-fns'
import moment from 'moment'

export default {
    name:"invoiceDetail",
    components: { DateRangePicker },
    data(){
        return {
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Invoices',
              disabled: false,
              href: '/organisation/invoices',
            },
            {
              text: 'Invoice Details',
              disabled: false,
              href: '',
            },
          ],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],

          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",

          searchfilter:'',
          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           Drivers: [],
           item: {
            organisation_id: '',
            project_id: ''
           },
           fields: [
            { text: 'STATUS', value: 'status' },
            { text: 'INVOICE DATE',value: 'date' },
            { text: 'DUE DATE', value: 'duedate' },
            { text: 'INVOICE#', value: 'invoiceno' },
            { text: 'ORGANISATION', value: 'organisation' },
            { text: 'TOTAL', value: 'amount' },
            { text: 'BALANCE', value: 'balance' },
           ],
            search:null,
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            searchorganisation: null,
            searchproject: null,
            show: false,
            dates: {},
            daterange: {
              start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
              end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
            },
            allbookingreports: [],
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            projects: [],
            invoices: [],
            orgname: null,
            rowHeight: 25,
            perPage: 200,
            start: 0,
            timeout: null 
 
        }
    },
    beforeMount() {
      if(!ability.can('view-reports', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    serverPrefetch () {
      return this.getBookings()
    },
    mounted(){

      this.getInvoices()

      if(!this.organisations || this.organisations == 0) {
        this.getOrganisation()
      }

    },
    computed: {
      organisations() {
        return this.$store.state.organisations
      },
      sinvoices () {
        return this.invoices
      },
      allinvoices () {
        return this.sinvoices.slice(this.start, this.perPage+this.start)
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.sinvoices.length - this.start);
      },
    },
    methods:{
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      getOrganisation(){
        this.isBusy = true
        this.$store
          .dispatch('getOrganisation')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      onScroll(e) {
        this.timeout && clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            const { scrollTop } = e.target;
            const rows = Math.ceil(scrollTop / this.rowHeight);

            this.start = rows + this.perPage > this.sinvoices.length ?
              this.sinvoices.length - this.perPage: rows;

            this.$nextTick(() => {
              e.target.scrollTop = scrollTop;
            });
          }, 20);
   
      },
      getDate(data) {
        return Vue.filter('dateF')(data)
      },
      exportexcel(){
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/invoice-details/export/excel', fd, config).then(response=>{
          this.loadingexcel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'Invoice_details.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/invoice-details/export/pdf', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'Invoice_details.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
      getInvoices(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/organisations/invoice-details', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      fetchreport(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/organisations/invoice-details', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      selectOrg(id) {
        let index = this.organisations.filter(item => item.id === id )
        if(index.length > 0)
          this.orgname = index[0].name
        else
          this.orgname = null
      },

    }
}
</script>