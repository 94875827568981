<template>
    <!-- BEGIN: Content-->
    <div class="Settings mt-3">
      <v-container fluid>
        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </v-col>
        </v-row>
        <v-card class="card-shadow py-0 my-1">
          <v-tabs vertical>
            <v-tab class="mt-2">
              <v-icon left>
                mdi-domain
              </v-icon>
              System Config
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-currency-usd
              </v-icon>
              Standard Charges
            </v-tab>

            <v-tab>
              <v-icon left>
                mdi-clock
              </v-icon>
              Waiting Time
            </v-tab>

            <v-tab>
              <v-icon left>
                mdi-currency-usd
              </v-icon>
              VAT
            </v-tab>
    
            <v-tab-item>
              <v-card class="card-shadow" flat>
                <v-card-text>
                    <v-row class="px-4" style="padding-bottom:0 !important">
                      <v-col sm="12" md="3" cols="12">
                        <p class="text-h6 text--primary mb-0" style="font-size:.85rem !important;width:100%">Client Automation Booking</p>
                        <v-switch
                          v-model="autobooking"
                          inset
                          :label="autobooking.toString()"
                          @change="changeState(autobooking)"
                        ></v-switch>
                      </v-col>
                      <v-col sm="12" md="9" cols="12">
                        <v-form class="pt-0"  enctype="multipart/form-data" v-model="valid" ref="form">
                        <v-row class="px-3">
                          <v-col cols="12" md="12" sm="12">
                            <p class="text-h6 text--primary mb-0" style="font-size:.85rem !important;width:100%">Bulk SMS Credentials</p>
                          </v-col>
                        </v-row>
                        <v-row class="px-2" style="padding-bottom:0 !important">
                          <v-col sm="12" md="12" cols="12">
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="API Key*"
                                v-model="item.apikey"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="PartnerID*"
                                v-model="item.partnerid"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="Shortcode*"
                                v-model="item.shortcode"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-col>
                        </v-row>
                      <v-row class="px-3">
                        <v-col cols="12">
                          <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="create"
                          >
                            Save
                          </v-btn>
                        </v-col>
                       </v-row>
                     </v-form>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card class="card-shadow" flat>
                <v-card-text>
                  <v-row class="px-4" style="padding-bottom:0 !important">
                      <v-col sm="12" md="3" cols="12">
                        <p class="text--primary mb-0" style="font-size:.85rem !important;width:100%">Rate per one kilometer</p>
                      </v-col>
                      <v-col sm="12" md="9" cols="12">
                        <v-form class="pt-0"  enctype="multipart/form-data" v-model="valid" ref="Chargeform">
                        <v-row class="px-3">
                          <v-col cols="12" md="12" sm="12">
                            <p class="text-h6 text--primary mb-0" style="font-size:.85rem !important;width:100%">Charges per kilometer</p>
                          </v-col>
                        </v-row>
                        <v-row class="px-2" style="padding-bottom:0 !important">
                          <v-col sm="12" md="12" cols="12">
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="One trip rate*"
                                v-model="item.one_trip_rate"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="Round trip rate*"
                                v-model="item.round_trip_rate"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-col>
                        </v-row>
                      <v-row class="px-3">
                        <v-col cols="12">
                          <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="savecharge"
                          >
                            Save
                          </v-btn>
                        </v-col>
                       </v-row>
                     </v-form>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card class="card-shadow chargerate" flat>
                <v-card-text>
                  <v-row class="px-4" style="padding-bottom:0 !important">
                      <v-col sm="12" md="4" cols="12">
                        <p class="text-h6 text--primary mb-0" style="text-transform:uppercase;font-size:.85rem !important;width:100%">Wait Time List</p>
                        <v-list two-line style="max-height: 250px !important;overflow: auto !important;">
                          <v-list-item-group
                          >
                            <template v-for="(item,index) in ratecharges">
                              <v-list-item :key="item.type.type" @click="deleteRate(item.id)">
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.85rem !important;width:100%">
                                        {{ item.type.type }}
                                      </p>
                                    </v-list-item-title>
                                    <v-list-item-subtitle><small>Vehicle Type</small></v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-action-text>
                                      <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.85rem !important;width:100%;font-weight:bold">
                                        {{ item.minutes }} | {{ item.rate }}
                                      </p>
                                      <small>Duration(min) | Rate</small>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>

                              <v-divider
                                v-if="index < ratecharges.length - 1"
                                :key="index"
                              ></v-divider>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                      <v-col sm="12" md="8" cols="12" style="background:#F8F9FE !important">
                        <v-form class="pt-0"  enctype="multipart/form-data" v-model="valid" ref="Rateform">
                        <v-row class="px-3">
                          <v-col cols="12" md="12" sm="12">
                            <p class="text-h6 text--primary mb-0" style="text-transform:uppercase;font-size:.85rem !important;width:100%">Set Waiting Time</p>
                          </v-col>
                        </v-row>
                        <v-row class="px-2" style="padding-bottom:0 !important">
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-autocomplete
                                :items="vehicleType"
                                label="Select type"
                                v-model="item.type_id"
                                item-text="type"
                                item-value="id"
                                :rules="fieldRules"
                                :search-input.sync="search"
                              ></v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="Free wait duration(minutes)*"
                                v-model="item.minutes"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="Rate per minute*"
                                v-model="item.rate"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                        </v-row>
                      <v-row class="px-3">
                        <v-col cols="12">
                          <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="savecharges"
                          >
                            Save
                          </v-btn>
                        </v-col>
                       </v-row>
                     </v-form>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card class="card-shadow" flat>
                <v-card-text>
                    <v-row class="px-4" style="padding-bottom:0 !important">
                      <v-col sm="12" md="12" cols="12">
                        <v-form class="pt-0"  enctype="multipart/form-data" v-model="valid" ref="form">
                        <v-row class="px-3">
                          <v-col cols="12" md="12" sm="12">
                            <p class="text-h6 text--primary mb-0" style="font-size:.85rem !important;width:100%">Update VAT</p>
                          </v-col>
                        </v-row>
                        <v-row class="px-2" style="padding-bottom:0 !important">
                          <v-col sm="12" md="12" cols="12">
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                label="VAT(%)*"
                                v-model="item.vat"
                                :rules="fieldRules"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-col>
                        </v-row>
                      <v-row class="px-3">
                        <v-col cols="12">
                          <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="updatevat"
                          >
                            Update
                          </v-btn>
                        </v-col>
                       </v-row>
                     </v-form>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs>
        </v-card>
      </v-container>

      <v-dialog
          v-model="progressdialog"
          hide-overlay
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

    </div>
</template>
<style scope>
 .chargerate .v-list-item{
  padding: 0 .3rem 0 .3rem !important;
 }
 .chargerate .v-list-item__content{
  padding-left: 0 !important;
 }
 .v-tab {
  justify-content: start !important;
 }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import {ability} from '../../services/ability'

export default {
    name:"Settings",
    components: { },
    data(){
        return {
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Settings',
              disabled: false,
              href: '',
            }
          ],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          valid: true,
          search:null,
          autobooking: false,
            isBusy: false,
            loading: false,
           disabled: false,
           progressdialog: false,
           item: {
            apikey: '',
            partnerid: '',
            shortcode: '',
            one_trip_rate:"",
            round_trip_rate:"",
            type_id: "",
            rate: "",
            minutes: "",
            vat: ""
           },
           fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Date', value: 'date' },
            { text: 'To', value: 'to' },
            { text: 'From', value: 'from' },
            { text: 'Assigned Vehicle', value: 'vehicle' },
            { text: 'Distance', value: 'distance' },
            { text: 'Status', value: 'status' },
            { text: 'Booked By', value: 'bookedby' },
           ],
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            ratecharges: null,
            vehicleType: null,
            searchorganisation: null,
            searchproject: null,
            show: false,
            dates: {},
            daterange: {
              start: '',
              end: ''
            },
            allbookingreports: [],
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            projects: []
 
        }
    },
    beforeMount() {
      if(!ability.can('view-reports', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    serverPrefetch () {
    },
    mounted(){
        this.getConfigs()
    },
    computed: {
      
    },
    methods:{
      changeState(state) {
        this.progressdialog = true
       axios.get(`/settings/automated_booking/${this.autobooking}`).then(response=>{
          this.progressdialog = false
          if(response.data.status == parseInt(200)) {
            this.$toast.success('Booking automation updated successfully','Success',{position: 'topRight',timeout: 7000,})
            this.getConfigs()
          }
        }).catch(error=>{
            console.log(error)
            this.progressdialog = false
        })
      },
      async getConfigs(){
        await axios.get('/settings/config').then(response=>{
            if(response.data.status == parseInt(200)) {
              this.item.apikey = response.data.apikey
              this.item.partnerid = response.data.partnerid
              this.item.shortcode = response.data.shortcode
              this.item.vat = response.data.vat
              
              this.item.one_trip_rate = response.data.one_trip_rate
              this.item.round_trip_rate = response.data.round_trip_rate

              this.vehicleType = response.data.vehicletype
              this.ratecharges = response.data.ratecharges

              if(response.data.autobooking === 'true')
                this.autobooking = Boolean(1)
              else if(response.data.autobooking === 'false')
                this.autobooking = Boolean(0)
            }
        }).catch(error=>{
            console.log(error)
        })
      },
      async create(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('apikey',this.item.apikey)
          fd.append('partnerid',this.item.partnerid)
          fd.append('shortcode',this.item.shortcode)

          axios.post('/bulksms', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Bulk SMS credentials updated successfully','Success',{position: 'topRight',timeout: 7000,})

              this.item.apikey = response.data.apikey
              this.item.partnerid = response.data.partnerid
              this.item.shortcode = response.data.shortcode
            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },
      async updatevat(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('rate',this.item.vat)

          axios.post('/vat', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('VAT updated successfully','Success',{position: 'topRight',timeout: 7000,})

              this.item.vat = response.data.vat
            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },
      // async savecharges(){

      //     if (this.$refs.chargeform.validate()) {

      //     this.loading = true
      //     this.disabled = true

      //     const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      //     let fd = new FormData();
      //     fd.append('one_trip_rate',this.item.one_trip_rate)
      //     fd.append('round_trip_rate',this.item.round_trip_rate)

      //     axios.post('/standard-charges', fd, config).then(response=>{
      //       if(response.data.status == parseInt(200)) {

      //         this.$toast.success('Standard charges updated successfully','Success',{position: 'topRight',timeout: 7000,})

      //         this.item.one_trip_rate = response.data.one_trip_rate
      //         this.item.round_trip_rate = response.data.round_trip_rate
      //       }
      //         this.loading = false
      //         this.disabled = false
      //       })
      //       .catch(() => {
      //         this.loading = false
      //         this.disabled = false
      //       })

      //     }
      // },
      async savecharge(){

          if (this.$refs.Chargeform.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('one_trip_rate',this.item.one_trip_rate)
          fd.append('round_trip_rate',this.item.round_trip_rate)

          axios.post('/standard-charges', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Standard charges updated successfully','Success',{position: 'topRight',timeout: 7000,})

              this.item.one_trip_rate = response.data.one_trip_rate
              this.item.round_trip_rate = response.data.round_trip_rate
            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },

      async savecharges(){

          if (this.$refs.Rateform.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('type_id',this.item.type_id)
          fd.append('rate',this.item.rate)
          fd.append('minutes',this.item.minutes)

          axios.post('/rate-charges', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Waiting time added successfully','Success',{position: 'topRight',timeout: 7000,})

              this.getConfigs()

              this.$refs.Rateform.reset()

            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },

      deleteRate(id){
            if(confirm("Are you sure to delete waiting time ?")){
                axios.get(`/delete-charge/${id}`).then(response=>{
                  this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                  this.getConfigs()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

    }
}
</script>