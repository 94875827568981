<template>
	<div>
	  <div ref="map" id="maps" class= "map"></div>
	</div>
</template>
<script>
import mapstyle from '@/assets/mapstyle.json'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'

export default {
	name: 'Map',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    center: {lat:-1.3289865, lng:36.9238278},
    map: null,
    google:gmapApi,
    directionsDisplay: null,
	}),
  watch: {
      origin: function(newVal, oldVal) {
          this.routes()
      },
      destination: function(newVal, oldVal) {
          this.routes()
      },
      waypoints: function(newVal, oldVal) {
          this.routes()
      }
  },
	methods: {
    setLocationLatLng: function() {
      navigator.geolocation.getCurrentPosition(geolocation => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude
        };

      })
    },
    async initMap() {

      this.map = new google.maps.Map(this.$refs.map,{
        center: this.center,
        zoom:12,
        maxZoom:20,
        minZoom:3,
        streetViewControl:false,
        mapTypeControl:false,
        fullscreenControl:false,
        zoomControl:false,
        styles:mapstyle,
      })

    },
    routes() {

      if (this.directionsDisplay != null) {
        this.directionsDisplay.setMap(null);
      }

      let dS = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: false,preserveViewport:true});
      this.directionsDisplay.setMap(this.map)

      if(this.origin !== null && this.destination === null) {
        new google.maps.Marker({
          position:this.origin,
          map:this.map
        })
      }
      if(this.origin === null && this.destination !== null) {
        new google.maps.Marker({
          position:this.destination,
          map:this.map
        })
      }
      if(this.origin !== null && this.destination !== null) {

        dS.route({
          origin: this.origin,
          destination: this.destination,
          waypoints: this.waypoints,
          travelMode:'DRIVING'
        })
        .then((result) => {
          this.renderer(result)
        })
        .catch((e) => {
          console.log("Directions request failed due to " + e)
        });
      }
    },
    renderer(result) {
      this.directionsDisplay.setDirections(result)
      this.map.panToBounds(result.routes[0].bounds);
      this.map.setCenter(result.routes[0].bounds.getCenter()); 
      if(this.map.getZoom() < 10) this.map.setZoom(10);
    },
	},
	props: {},
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
	    origin() {
        return this.$store.state.origin 
      },
      destination() {
        return this.$store.state.destination 
      },
      waypoints() {
        return this.$store.state.waypoints 
      },
	  },
	beforeMount() {
    this.setLocationLatLng()
	},
  mounted: function () {

    this.$gmapApiPromiseLazy().then(async() => {
      this.initMap()
    })

    // let initializeWhenGoogleIsAvailable = () => {
    //   if (this.google) { 
    //     this.initMap()
    //   } else {
    //     setTimeout(initializeWhenGoogleIsAvailable, 100)
    //   }
    // };
    // initializeWhenGoogleIsAvailable();

  },
	created() {
     
  }
}
</script>