<template>
	<div>
	  <!-- assign vehicle modal -->
      <v-dialog
        v-model="show"
        max-width="380px"
        >
        <v-card>
          <div style="width:100%;height:.3rem !important">
                  <v-progress-linear
                    color="primary accent-4"
                    indeterminate
                    height="3"
                    :active="addbookingloader"
                  ></v-progress-linear>
                </div>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Assign Vehicle</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-alert
                      outlined
                      color="primary"
                      text
                    >
                      <div v-for="(assign, index) in toassinguser" :key="index">

                        <div><span>{{ assign.name }}</span></div>

                      </div>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>


                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      :items="freeDrivers" 
                      v-model="toassignuserdriver"
                      label="Select Driver"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      required
                      clearable
                      color="yellow-lighten-2"
                      :search-input.sync="searchdriver"
                      @change="onchangeDriver"
                    >
                      <template slot="selection" slot-scope="{ item, selected }">
                          {{ item.name }} - {{ item.telephone }}
                      </template>
                      <template slot="item" slot-scope="{ item, tile }">
                          {{ item.name }} - {{ item.telephone }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      :items="freeVehicles" 
                      v-model="toassignuservehicle"
                      label="Select Vehicle"
                      item-text="registration"
                      item-value="id"
                      :rules="fieldRules"
                      required
                      clearable
                      color="yellow-lighten-2"
                      :search-input.sync="searchvehicle"
                      @change="onchangeVehicle"
                    >
                      <template slot="selection" slot-scope="{ item, selected }">
                          {{ item.name }} - {{ item.registration }}
                      </template>
                      <template slot="item" slot-scope="{ item, tile }">
                          {{ item.name }} - {{ item.registration }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="assignCar"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end assign vehicle -->
	</div>
</template>
<style scoped>
  span {
    font-size: 11.5px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'assignvehicle',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        searchvehicle:null,
        searchdriver:null,
        toassignuserdriver: "",
        toassignuservehicle: "",

	}),
	methods: {
    async getAssignVehicle(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAssignVehicle')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
		onchangeVehicle(val) {

	        if (this.toassignuservehicle !== "" && this.toassignuserdriver !== "") {
	          this.assignCar()
	        }

	      },
	      onchangeDriver(val) {
	        if (this.toassignuservehicle !== "" && this.toassignuserdriver !== "") {
	          this.assignCar()
	        }
	      },
		assignCar(){

         if (this.$refs.form.validate()) {

	        this.loading = true
	        this.disabled = true

	        navigator.geolocation.getCurrentPosition(geolocation => {

	            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
	            let fd = new FormData();
	            fd.append('vehicle_id', this.toassignuservehicle)
	            fd.append('driver_id', this.toassignuserdriver)
	            fd.append('lat',geolocation.coords.latitude)
	            fd.append('long',geolocation.coords.longitude)
	            fd.append('id',this.toassignuserid)

	            axios.post('/assign-client-vehicle', fd, config).then(response=>{
	              if(response.data.status === parseInt(404)) {
	                this.$toast.error(response.data.message,'Error',{position: 'topRight',messageColor:'#ffffff',timeout: 7000,})
	              }
	              if(response.data.status === parseInt(401)) {
	                this.$toast.warning(response.data.message,'Error',{position: 'topRight',timeout: 7000,})
	              }

	              if(response.data.status === parseInt(200)) {

                  this.$store.dispatch('bookingEdit', response.data.data)
                  
	                this.$toast.show(response.data.driver,'',{
	                  position: 'topRight',
	                  image: 'https://cdn-icons-png.flaticon.com/512/619/619006.png',
	                  imageWidth: 50,
	                  balloon: false,
	                  messageColor:'#000000',
	                  messageLineHeight: '20',
	                  timeout: 7000,
	                })
	              }

                this.toassignuserdriver = ""
                this.toassignuservehicle = ""

	              this.show = false
	              this.loading = false
	              this.disabled = false
	              
	            })
	            .catch(() => {
	              this.loading = false
	              this.disabled = false
	            })

	        })

	      }

        }
	},
	props: {
	     value: Boolean,
	     toassignuserid: Number,
	     toassigneduservehicle: Number,
	     toassinguser: Array
  },
  computed: {
    addbookingloader() {
      return this.$store.state.addbookingloader
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
    freeVehicles() {
      return this.$store.state.assignvehicles
    },
    drivers() {
      return this.$store.state.assigndrivers
    },
    freeDrivers() {
      return this.drivers
    },
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          if(this.drivers.length == 0){
            this.getAssignVehicle()
          }
        }
      }
  },
	mounted() {
    
	},
	created() {
     
    }
}
</script>