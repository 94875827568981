<template>
    <!-- BEGIN: Content-->
    <div class="Reports mt-3">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="mb-0 py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>
                  <div>
                   <!--  <v-btn
                      color="#000000"
                      class="ma-1 white--text"
                      depressed
                      :loading="loadingpdf"
                      @click="exportpdf"
                    >
                      Export Pdf
                      <v-icon
                        right
                        dark
                      >
                        mdi-file-pdf-box
                      </v-icon>
                    </v-btn> -->
                    <v-btn
                      color="#ffffff"
                      class="ma-1 black--text"
                      depressed
                      :loading="loadingexcel"
                      @click="exportexcel"
                    >
                      Export Excel
                      <v-icon
                        right
                        dark
                      >
                        mdi-file-excel-box
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="py-0 my-0">
            <v-card class="card-shadow py-0">
              <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="primary accent-4"
                      indeterminate
                      height="3"
                      :active="tableloader"
                    ></v-progress-linear>
                  </div>
              <v-card-title style="display:block;" class="pt-0 mt-2">
                <v-row class="pa-1">
                  <v-col cols="12" sm="12" class="pt-0 mt-0">
                    <v-form class="pt-2 mt-0"  enctype="multipart/form-data" v-model="valid" ref="form">
                      <v-row class="pt-0 mt-0">
                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                         >
                         <date-range-picker
                            ref="picker"
                            :showWeekNumbers="showWeekNumbers"
                            :showDropdowns="showDropdowns"
                            :autoApply="autoApply"
                            opens="right"
                            :always-show-calendars="alwaysshowcalendars"
                            v-model="dateRange"
                            @select="updateValues"
                          >
                              <template v-slot:input="picker" style="min-width: 350px;">
                                  <v-text-field
                                    label="Select date range"
                                    :rules="fieldRules"
                                    v-model="date"
                                    solo
                                  ></v-text-field>
                              </template>
                          </date-range-picker>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                         >
                          <v-autocomplete
                              :items="organisations"
                              label="Select organisation"
                              v-model="item.organisation_id"
                              clearable
                              @click:clear="item.organisation_id = []"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="searchorganisation"
                              multiple
                              solo
                              chips
                            >
                              <template v-slot:selection="data">
                                  <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    @click:close="removeorganisation(data.item)"
                                  >
                                    {{ data.item.name.substring(0,8)+".." }}
                                  </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                         >
                          <v-autocomplete
                              :items="projects"
                              label="Select project"
                              v-model="item.project_id"
                              clearable
                              @click:clear="item.project_id = []"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="searchproject"
                              solo
                              multiple
                              chips
                            >
                              <template v-slot:selection="data">
                                  <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    @click:close="removeorganisation(data.item)"
                                  >
                                    {{ data.item.name.substring(0,8)+".." }}
                                  </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                          >
                          <v-autocomplete
                              :items="customers"
                              label="Select staff"
                              v-model="item.customer_id"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="searchcustomer"
                              solo
                              multiple
                              chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="removecustomer(data.item)"
                                >
                                  {{ data.item.name.substring(0,8)+".." }}
                                </v-chip>
                              </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                         >
                          <v-autocomplete
                            :items="['Client', 'Delivery']"
                            label="Select request category"
                            v-model="item.request_category"
                            :rules="fieldRules"
                            :search-input.sync="searchrequestcategory"
                            solo
                            clearable
                            @click:clear="item.request_category = ''"
                          ></v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                         >
                          <v-btn
                              depressed
                              color="primary darken-1"
                              :loading="loading"
                              :disabled="!valid"
                              @click="fetchreport"
                            >
                              Search
                            </v-btn>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row style="margin-bottom:0 !important" class="px-1 mt-2">
                  <v-col md="12" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between">
                  <div class="my-0 py-0 mx-4" style="display:flex;align-items:center;justify-content:flex-start">
                        <div style="font-size:1.3rem !important;font-weight:bold;color:#000 !important;margin-right:1rem !important;display:flex !important;flex-direction:row !important;align-items:center !important;width:185px">
                          <small style="font-size:.6rem !important;color:darkgrey" class="mr-1">Sub Total</small>
                          {{

                            (this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisationrate.length > 0) {
                                    if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else {
                                      amt = parseInt(item.organisationrate[0].base_rate)
                                    }
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0)) | currency('')

                          }}
                          
                        </div>
                        <div style="font-size:1.3rem !important;font-weight:bold;color:#000 !important;margin-right:0 !important;display:flex !important;flex-direction:row !important;align-items:center !important;width:185px">
                          <small style="font-size:.6rem !important;color:darkgrey" class="mr-1">VAT</small>
                          {{

                            ((((this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisation.vat !== '0') {
                                    if(item.organisationrate.length > 0) {
                                      if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                        amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                      }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                        amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                      }else {
                                        amt = parseInt(item.organisationrate[0].base_rate)
                                      }
                                    }
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                          }}
                          
                        </div>
                        <div style="font-size:1.3rem !important;font-weight:bold;color:#000 !important;margin-right:1rem !important;display:flex !important;flex-direction:row !important;align-items:center !important;width:185px">
                          <small style="font-size:.6rem !important;color:darkgrey" class="mr-1">Total</small>
                          {{

                            (this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0;

                              if(item.expressway.length > 0) {
                                amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                              }
                              
                              return acc += amt; 

                            }, 0) + ((((this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisation.vat !== '0') {
                                    if(item.organisationrate.length > 0) {
                                      if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else {
                                        amt = parseInt(item.organisationrate[0].base_rate)
                                      }
                                    }
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0))*(vat != null ? vat.rate : 0))/100)) + this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisationrate.length > 0) {
                                    if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else {
                                      amt = parseInt(item.organisationrate[0].base_rate)
                                    }
                                  }
                                return acc += amt; 
                              }, 0)) | currency('')

                          }}
                          
                        </div>
                      </div>
                      <v-col
                          cols="12"
                          md="2"
                          class="py-0"
                        >
                        <v-text-field
                          v-model="search"
                          label="Search"
                          solo
                        ></v-text-field>
                      </v-col>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="px-0 pb-0 cardtext">
                <v-row class="mb-2">
                  <v-col cols="12" class="mx-3 my-3 py-0 d-flex align-center" style="height:30px">
                    <label class="form-checkbox" style="font-size:.7rem !important;font-weight:600;color:#333;text-transform:uppercase;margin-right:1rem">
                      <input type="checkbox" v-model="selectAll" @click="select">
                      Select All
                    </label>

                    <v-btn
                      depressed
                      color="grey lighten-2"
                      v-if="selected.length > 0"
                      style="margin-right:2rem"
                      rounded
                      @click="updateselected"
                    >
                      Transfer records
                    </v-btn>

                  </v-col>
                </v-row>
                <v-virtual-scroll
                    :items="allbookingreports"
                    :item-height="23"
                    renderless 
                    id="virtualscroll"
                    class="report"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">
                          <label class="form-checkbox">
                            <input type="checkbox" :value="item.id" v-model="selected">
                            <i class="form-icon"></i>
                          </label>
                        </div>
                        <div class="tcell" @click="bookingdetailsupdate(item.id)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-if="item.passenger_name !== null" v-bind="attrs"
                                v-on="on">
                                {{ item.passenger_name }}
                              </span>
                            </template>
                            <span>
                              <span v-if="item.passenger_name !== null">
                                {{ item.passenger_name }}
                              </span>
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <div>{{ item.telephone }}</div>
                        </div>
                        <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs"
                                v-on="on">
                                {{ item.travelfrom }}
                              </span>
                            </template>
                            <span>
                              {{ item.travelfrom }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs"
                                v-on="on">
                                {{ item.travelto }}
                              </span>
                            </template>
                            <span>
                              {{ item.travelto }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <span v-if="item.vehicletype !== null">{{ item.vehicletype.type }}</span>
                          <span v-else>N/A</span>
                        </div>
                        <div class="tcell" @click="editdistance(item.id)">{{ item.distance | currency('') }}</div>

                        
                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>

                        <div class="tcell" @click="expressway(item.id)">
                          {{ item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0) }}
                        </div>

                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                            <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                            <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div class="tcell">{{ item.status }}</div>
                        <div class="tcell">
                           <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>ti-arrow-circle-down</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >

                                <v-list-item
                                    @click="bookingprofile(item.id)"
                                    >
                                        <v-list-item-icon>
                                          <v-icon color="#000000">mdi-account</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Profile</v-list-item-title>
                                        </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    @click="expressway(item.id)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-highway</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Expressway</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="waiting(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-clock</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Waitings</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                  link
                                  @click="voucher(item.ref_no)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-ticket-confirmation</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>E-voucher</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <EditDistance v-model="editdistancedialog" :bookingid="bookingid"/>
      <ExpresswayDetail v-model="expresswaydialog" :expresswaydetails="expresswaydetails"/>
      <WaitingDetail v-model="waitingdialog" :waitingdetails="waitingdetails"/>
      <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>
      <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
      <UpdateAll v-model="updatealldialog" :selected="selected"/>

    </div>
</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }
    .range-date-input{
      background: transparent !important;
      font-size: .9rem;
    }
    .input-group-merge .input-group-text{
      background: transparent !important;
      border-right: none !important;
    }
    .picker.range-date-picker{
      position: fixed !important;
      margin-top: 2.4rem !important;
      z-index: 100 !important;
    }

    .report .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(4%, 7.691%) !important;*/
       grid-template-columns:2% 10.691% 7.691% 7.691% 12.691% 12.691% 7.691% 4.691% 4.691% 4.691% 5.691% 5.691% 7.691% 5.691%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<style scoped>
  .v-virtual-scroll{
      height: 65vh !important;
    }
    .cardtext{
      height: 65vh !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import {ability} from '@/services/ability'

import EditDistance from '@/components/subcomponents/editDistance'
import ExpresswayDetail from '@/components/subcomponents/expressway'
import WaitingDetail from '@/components/subcomponents/waiting'
import ClientProfile from '@/components/bookings/submodules/today/clientprofile'
import UpdateDetail from '@/components/subcomponents/updatedetails'
import UpdateAll from '@/components/subcomponents/updateall'

import DateRangePicker from 'vue2-daterange-picker'

import $ from 'jquery';

export default {
    name:"Reports",
    components: { ExpresswayDetail,WaitingDetail,ClientProfile,EditDistance,DateRangePicker,UpdateDetail,UpdateAll },
    data(){
        return {
          selected: [],
          selectAll: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Reports',
              disabled: false,
              href: '',
            },
            {
              text: 'Bookings',
              disabled: false,
              href: '',
            },
          ],

          
          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",

          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           Drivers: [],
           expresswaydialog: false,
           editdistancedialog: false,
           bookingid: null,
           expresswaydetails: [],
           updatedetails: [],
           waitingdialog: false,
           waitingdetails: [],
           profiledetails: [],
           profiledialog: false,
           searchrequestcategory: "",
           item: {
            request_category: '',
            organisation_id: [],
            project_id: [],
            customer_id: []
           },
           fields: [
            { text: '', value: '' },
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            // { text: 'Project', value: 'project' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            // { text: 'Stop', value: 'stop' },
            { text: 'Vehicle Type', value: 'vehicletype' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'Xway', value: 'expressway' },
            { text: 'Vat', value: 'vat' },
            { text: 'Total', value: 'total' },
            { text: 'Status', value: 'status' },
            { text: 'Action', value: 'action' },
           ],
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            search: "",
            searchorganisation: null,
            searchproject: null,
            searchcustomer: null,
            show: false,
            dates: {},
            daterange: {
              start: '',
              end: ''
            },
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            profiledetails: [],
            profiledialog: false,
            updatedetails: [],
            updatedialog: false,
            updateall: [],
            updatealldialog: false,
 
        }
    },
    beforeMount() {
      this.$store.state.allbookingreports = []
      if(!ability.can('view-reports', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    serverPrefetch () {
      return this.getOrgCustomer()
    },
    mounted(){

      if(!this.organisations || this.organisations.length == 0 || this.allcustomers.length == 0) {
        this.getOrgCustomer()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {

      tableloader() {
        return this.$store.state.tableloader
      },

      vat() {
        return this.$store.state.vat
      },

      bookingreport() {
        return this.$store.state.allbookingreports
        
      },

      allbookingreports() {
        return this.bookingreport.filter(item => {
          this.start = 0
          let name = "";
          if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
            
          return name.toLowerCase().includes(this.search.toLowerCase())  || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search))
        })
      },
      calcbookingreports() {
        return this.$store.state.allbookingreports.filter(item => {
          this.start = 0
            if(this.selected.includes(item.id) === true) {
              return item
            }else {
              return false
            }

        })
      },
      organisations() {
        return this.$store.state.reportorganisations
      },
      allcustomers() {
        return this.$store.state.reportcustomers
      },
      projects() {
        if(this.item.organisation_id.length > 0){
          let projects = []
          let data = this.organisations.filter(organisation => this.item.organisation_id.includes(organisation.id) )
          for (let i in data) {
            if(data[i].projects.length > 0) {
              projects.push(...data[i].projects);
            }
          }

          return projects
        }else{
          return []
        }       
      },
      customers() {
        if(this.item.organisation_id.length > 0){
          let data = this.allcustomers.filter(customer => this.item.organisation_id.includes(customer.organisation_id) )
          return data
        }else{
          return []
        }       
      },
      ...mapState({
        searchFilter: 'searchFilter'
      }),
    },
    methods:{
      removeproject (item) {
        const index = this.item.project_id.indexOf(item.id)
        if (index >= 0) this.item.project_id.splice(index, 1)
      },
      removecustomer (item) {
        const index = this.item.customer_id.indexOf(item.id)
        if (index >= 0) this.item.customer_id.splice(index, 1)
      },
      removeorganisation (item) {
        const index = this.item.organisation_id.indexOf(item.id)
        if (index >= 0) this.item.organisation_id.splice(index, 1)
      },
      select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.allbookingreports) {
            this.selected.push(this.allbookingreports[i].id);
          }
        }
      },
      bookingprofile(id) {
        this.$store.state.bookingid = id
        this.profiledialog = true
      },
      bookingdetailsupdate(id) {
        this.updatedetails = this.allbookingreports.filter(booking => booking.id === id )
        this.updatedialog = true
      },
      updateselected() {
        this.updatealldialog = true
      },
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      editdistance(id) {
        this.bookingid = id
        this.editdistancedialog = true
      },
      expressway(id) {
        this.expresswaydetails = this.bookingreport.filter(booking => booking.id === id )
        this.expresswaydialog = true
      },
      waiting(id) {
        this.waitingdetails = this.bookingreport.filter(booking => booking.id === id )
        this.waitingdialog = true
      },
      voucher(ref) {
        window.open(`https://evoucher.absolutecabs.com/customer/e-voucher/${ref}`, '_blank', 'noreferrer');
      },
      getRowClass(item) {
        return 'row-color' 
      },
      getDate(data) {
        return Vue.filter('dateF')(data)
      },
      exportexcel(){
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.item.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',JSON.stringify(this.item.project_id))
          fd.append('customer_id',JSON.stringify(this.item.customer_id))
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/booking/export/excel', fd, config).then(response=>{
          this.loadingexcel = false
          let data = this.organisations.filter(organisation =>  this.item.organisation_id.includes(organisation.id) )
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if(data.length > 0) {
            fileLink.setAttribute('download', `${data[0].name}_report_${this.daterange.start}_to_${this.daterange.end}.xlsx`);
           }else {
            fileLink.setAttribute('download', 'Report.xlsx');
           }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.item.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',JSON.stringify(this.item.project_id))
          fd.append('customer_id',JSON.stringify(this.item.customer_id))
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/booking/export/pdf', fd, config).then(response=>{
         this.loadingpdf = false
         let data = this.organisations.filter(organisation =>  this.item.organisation_id.includes(organisation.id) )
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         if(data.length > 0) {
            fileLink.setAttribute('download', `${data[0].name}_report_${this.daterange.start}_to_${this.daterange.end}.pdf`);
           }else {
            fileLink.setAttribute('download', 'Report.pdf');
           }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
      fetchreport(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.item.organisation_id))
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',JSON.stringify(this.item.project_id))
          fd.append('customer_id',JSON.stringify(this.item.customer_id))
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/booking/admin/reports', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.allbookingreports = response.data.bookings
              this.$store.state.vat = response.data.vat
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      getOrgCustomer(){

        this.$store.state.tableloader = true

        this.$store
          .dispatch('getOrgCustomer')
          .then(() => {
          })
          .catch(() => {
          })
      },

    }
}
</script>