import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {ability} from '../services/ability';
Vue.use(Vuex)
import moment from "moment";

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/absolute'
axios.defaults.baseURL = 'https://absolutebackendtest.absolutecabs.com/api/absolute'
// axios.defaults.baseURL = 'https://absolutebackendtest.absolutecabs.com/api/absolute'
// axios.defaults.baseURL = 'https://absoluteadminbackend.absolutecabs.co.ke/api/absolute'
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.withCredentials = true;

export const store =  new Vuex.Store({

	state: {
		times:["00:00","00:30","01:00","01:30","02:00","02:30","03:00","03:30","04:00","04:30","05:00","05:30","06:00","06:30","07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"],
		searchdate: "",
		todaysearchdate: "",
		searchbooking: "",
		todaysearchbooking: "",
		entrances: [],
		exits: [],
		expresswayrates: [],
		freevehicles: [],
		commission_rates: [],
		allbookingreports: [],
		todaybookings: [],
		vehicletypes: [],
		rates: [],
		ratings: [],
		disciplinaries: [],
		user: null,
		// components
		bookingorganisations: [],
		bookingcustomers: [],
		bookingvehicletype: [],
		bookingvehicles: [],
		bookingdrivers: [],
		invoicedetail: null,
		paymentdetail: null,
		creditnotedetail: null,
		debitnotedetail: null,
		showinvoices: [],
		showpayments: [],
		showcreditnotes: [],
		showdebitnotes: [],
		minorganisations: [],
		componentvehicles: [],
		reportorganisations: [],
		reportcustomers: [],
		updateorganisations: [],
		assignvehicles: [],
		assigndrivers: [],
		newdata: null,

		vat: null,
		bookingid: null,
		tableloader: false,
		tptableloader: false,
		addbookingloader: false,
		userPermissions: [],
		drivers: [],
		customers: [],
		invoicebookings: [],
		creditnotes: [],
		debitnotes: [],
		organisations: [],
		invoices: [],
		payments: [],
		commissions: [],
		paymentreversals: [],
		modes: [],
		vehicles: [],
		make:[],
		vehicletype:[],
		model:[],
		ownership:[],
		orgcategory: [],
		bookings: [],
		allbookings: [],
		all_bookings: 0,
		todaycommission: 0,
		totalcommission: 0,
		orgtype: [],
		users: [],
		roles: [],
		disciplinaries: [],
		profiledetails: null,
		in15count:0,
		over15count:0,
	    in30count:0,
	    in2count:0,
	    over2count:0,
	    latecount:0,
	    bookingcount:0,
	    cancelledcount:0,
	    allcount:0,
	    dayscount: 0,
	    searchFilter: '',
	    dashboard_stats: null,
	    arrayChart: null,
	    orgarray: null,
	    categories: null,
	    orgchartbooking: null,
	    distance: 0,
	    stop1: 0,
	    stop2: 0,
	    dist: 0,
	    end: 0,
	    distanceTime: 0,
	    stop1Time: 0,
	    stop2Time: 0,
	    endTime: 0,

	    origin: null,
	  	destination: null,
	  	waypoints: [],
	  	entrances: [],
	  	exits: [],
	  	documents: [],
	  	tableloader: false,

		today: moment().format("YYYY-MM-DD"),
	},
	mutations: {
		getShowDebitNotes(state, data) {
			state.tableloader = false
			if(data.status === parseInt(200)) {
				state.showdebitnotes = data.debitnotes
			}
		},
		getShowCreditNotes(state, data) {
			state.tableloader = false
			if(data.status === parseInt(200)) {
				state.showcreditnotes = data.creditnotes
			}
		},
		getShowPayments(state, data) {
			state.tableloader = false
			if(data.status === parseInt(200)) {
				state.showpayments = data.payments
			}
		},
		getShowInvoices(state, data) {
			state.tableloader = false
			if(data.status === parseInt(200)) {
				state.showinvoices = data.invoices
			}
		},

		in15count (state, data) {

	      state.in15count = data
	    },
	    over15count (state, data) {

	      state.over15count = data
	    },
	    searchFilter (state, data) {
	      state.searchFilter = data
	    },
	    in30count (state, data) {
	      state.in30count = data
	    },
	    in2count (state, data) {
	      state.in2count = data
	    },
	    over2count (state, data) {
	      state.over2count = data
	    },
	    latecount (state, data) {
	      state.latecount = data
	    },
	    cancelledcount (state, data) {
	      state.cancelledcount = data
	    },
	    allcount (state, data) {

	      state.allcount = data
	    },
	    dayscount (state, data) {
	      state.dayscount = data
	    },

		// account
		setUserData (state, userData) {

			state.userPermissions = userData.userPermissions
			
			let formattedRules = [];
	        if (state.userPermissions.length > 0) {
	            formattedRules = state.userPermissions.map(perm => {
	                let formattedObj = {};
	                formattedObj.subject = 'all';
	                formattedObj.action = perm.substr(perm.indexOf(' ') + 1);
	                return formattedObj;
	            })
	        }
			ability.update(formattedRules)
			state.user = userData
			localStorage.setItem('user', JSON.stringify(userData))
			axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
		},

		clearUserData () {
			localStorage.removeItem('user')
			location.reload()
		},

		bookingCreated(state, data) {
			let found = state.todaybookings.find(booking => booking.id == data.id);
			if (!found) {

				let item = data
				// new booking
				const container = {};
			    container.name = item.passenger_name !== null ? item.passenger_name : '';
			    container.date = item.pickup_date !== null ? item.pickup_date : '';
			    container.time = item.pickup_time !== null ? item.pickup_time : '';
			    container.telephone = item.telephone !== null ? item.telephone : '';
			    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
			    container.from = item.travelfrom !== null ? item.travelfrom : '';
			    container.to = item.travelto !== null ? item.travelto : '';
			    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

			    container.status = item.status;
			    container.authorization = item.authorization;
			    container.remarks = item.remarks;
			    container.trip_type = item.trip_type;
			    container.request_category = item.request_category;

			    container.request_type = item.request_type;
			    container.ref_no = item.ref_no;
			    container.id = item.id;
			    container.vehicles = item.bookingvehicles;

				state.todaybookings.unshift(container)

				// return container
			}
		},
		bookingEdit(state, data) {

			let found = state.todaybookings.findIndex(booking => booking.id == data.id);
	        if (found > -1) {

	        	let item = data
				// new booking
				const container = {};
			    container.name = item.passenger_name !== null ? item.passenger_name : '';
			    container.date = item.pickup_date !== null ? item.pickup_date : '';
			    container.time = item.pickup_time !== null ? item.pickup_time : '';
			    container.telephone = item.telephone !== null ? item.telephone : '';
			    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
			    container.from = item.travelfrom !== null ? item.travelfrom : '';
			    container.to = item.travelto !== null ? item.travelto : '';
			    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

			    container.status = item.status;
			    container.authorization = item.authorization;
			    container.remarks = item.remarks;
			    container.trip_type = item.trip_type;
			    container.request_category = item.request_category;

			    container.request_type = item.request_type;
			    container.ref_no = item.ref_no;
			    container.id = item.id;
			    container.vehicles = item.bookingvehicles;
			    
	           state.todaybookings.splice(found, 1, container);
	        }
		},
		bookingDelete(state, data) {
			let index = state.todaybookings.findIndex(booking => booking.id == data.id);
			if (index > -1) {
				state.todaybookings.splice(index, 1);
			}
		},
		// users
		getUsers(state, data) {
			if(data.status === parseInt(200)) {
				state.minorganisations = data.organisation
				state.users = data.users
				state.roles = data.roles
			}
		},
		// customers
		newCustomer(state, data) {
			let found = state.customers.find(customer => customer.id == data.id);
			if (!found) {
				state.customers.unshift(data)

				return data
			}
		},
		// users
		userslist(state, data) {
			let found = state.users.find(user => user.id == data.id);
			if (!found) {
				state.users.unshift(data)
			}
		},
		userupdate(state, data) {
			let found = state.users.find(user => user.id == data.id);

			if (found) {
				found.name = data.name
                found.email = data.email
                found.telephone = data.telephone
                found.idno = data.idno
                found.id = data.id
                found.username = data.username
                found.roles = data.roles
                found.organisation = data.organisation
			}
		},
		userdelete(state, data) {
			let index = state.users.findIndex(user => user.id == data.id);
			if (index > -1) {
				state.users.splice(index, 1);
			}
		},
		// payment reversals
		getPaymentReversal(state, data) {
			if(data.status === parseInt(200)) {
				state.paymentreversals = data.reversals
				// state.payments = data.payments
			}
		},
		createreversal(state, data) {
			let found = state.paymentreversals.find(reversal => reversal.id == data.id);
			if (!found) {
				state.paymentreversals.unshift(data)
			}
		},
		editreversal(state, data) {
			let found = state.paymentreversals.find(reversal => reversal.id == data.id);

			if (found) {
                found.id = data.id
                found.reversal_no = data.reversal_no
                found.organisation_id = data.organisation_id
                found.payment_id = data.payment_id
                found.notes = data.notes
                found.date = data.date
                found.amount = data.amount
                found.payment = data.payment
                found.organisation = data.organisation
			}
		},
		deletereversal(state, data) {
			let index = state.paymentreversals.findIndex(reversal => reversal.id == data.id);
			if (index > -1) {
				state.paymentreversals.splice(index, 1);
			}
		},
		// credit notes
		getCreditNotes(state, data) {
			if(data.status === parseInt(200)) {
				state.creditnotes = data.creditnotes
				state.invoices = data.invoices
			}
		},
		createcreditnote(state, data) {
			let found = state.creditnotes.find(creditnote => creditnote.id == data.id);
			if (!found) {
				state.creditnotes.unshift(data)
			}
		},
		editcreditnote(state, data) {
			let found = state.creditnotes.find(creditnote => creditnote.id == data.id);

			if (found) {
                found.id = data.id
                found.credit_note_no = data.credit_note_no
                found.organisation_id = data.organisation_id
                found.invoice_id = data.invoice_id
                found.status = data.status
                found.notes = data.notes
                found.date = data.date
                found.amount = data.amount
                found.invoice = data.invoice
                found.organisation = data.organisation
			}
		},
		deletecreditnote(state, data) {
			let index = state.creditnotes.findIndex(creditnote => creditnote.id == data.id);
			if (index > -1) {
				state.creditnotes.splice(index, 1);
			}
		},
		// debit notes
		getDebitNotes(state, data) {
			if(data.status === parseInt(200)) {
				state.debitnotes = data.debitnotes
				state.invoices = data.invoices
			}
		},
		createdebitnote(state, data) {
			let found = state.debitnotes.find(debitnote => debitnote.id == data.id);
			if (!found) {
				state.debitnotes.unshift(data)
			}
		},
		editdebitnote(state, data) {
			let found = state.debitnotes.find(debitnote => debitnote.id == data.id);

			if (found) {
                found.id = data.id
                found.debit_note_no = data.debit_note_no
                found.organisation_id = data.organisation_id
                found.invoice_id = data.invoice_id
                found.status = data.status
                found.notes = data.notes
                found.date = data.date
                found.amount = data.amount
                found.invoice = data.invoice
                found.organisation = data.organisation
			}
		},
		deletedebitnote(state, data) {
			let index = state.debitnotes.findIndex(debitnote => debitnote.id == data.id);
			if (index > -1) {
				state.debitnotes.splice(index, 1);
			}
		},
		// payments
		getpayments(state, data) {
			if(data.status === parseInt(200)) {
				state.payments = data.payments
				// state.invoices = data.invoices
				// state.modes = data.modes
			}
		},
		createpayment(state, data) {
			let found = state.payments.find(payment => payment.id == data.id);
			if (!found) {
				state.payments.unshift(data)
			}
		},
		editpayment(state, data) {
			let found = state.payments.find(payment => payment.id == data.id);

			if (found) {
                found.id = data.id
                found.invoice_no = data.invoice_no
                found.organisation_id = data.organisation_id
                found.subject = data.subject
                found.status = data.status
                found.date = data.date
                found.due_date = data.due_date
                found.amount = data.amount
                found.bookings = data.bookings
                found.payments = data.payments
                found.organisation = data.organisation
			}
		},
		deletepayment(state, data) {
			let index = state.payments.findIndex(payment => payment.id == data.id);
			if (index > -1) {
				state.payments.splice(index, 1);
			}
		},
		// invoices
		getInvoices(state, data) {
			if(data.status === parseInt(200)) {
				state.invoices = data.invoices
			}
		},
		InvoiceBookings(state, data) {
			state.invoicebookings = data.bookings
			state.vat = data.vat
		},
		createinvoice(state, data) {
			let found = state.invoices.find(invoice => invoice.id == data.id);
			if (!found) {
				state.invoices.unshift(data)
			}
		},
		editinvoice(state, data) {
			let found = state.invoices.find(invoice => invoice.id == data.id);

			if (found) {
                found.id = data.id
                found.invoice_no = data.invoice_no
                found.organisation_id = data.organisation_id
                found.subject = data.subject
                found.status = data.status
                found.date = data.date
                found.due_date = data.due_date
                found.amount = data.amount
                found.bookings = data.bookings
                found.payments = data.payments
                found.organisation = data.organisation
			}
		},
		deleteinvoice(state, data) {
			let index = state.invoices.findIndex(invoice => invoice.id == data.id);
			if (index > -1) {
				state.invoices.splice(index, 1);
			}
		},
		// organisations
		getOrganisation(state, data) {
			if(data.status === parseInt(200)) {
				state.organisations = data.organisation
				state.orgcategory = data.category
				state.orgtype = data.type
			}
		},
		orglistindex(state, data) {
			let found = state.organisations.find(org => org.id == data.id);
			if (!found) {
				state.organisations.unshift(data)
			}
		},
		orglistupdate(state, data) {
			let found = state.organisations.find(org => org.id == data.id);

			if (found) {
				found.name = data.name
                found.email = data.email
                found.telephone = data.telephone
                found.id = data.id
                found.orgcategory = data.orgcategory
                found.orgtype = data.orgtype
                found.address = data.address
                found.status = data.status
                found.bookingauthorize = data.bookingauthorize
                found.country = data.country
                found.one_trip_rate = data.one_trip_rate
                found.round_trip_rate = data.round_trip_rate
                found.typeID = data.typeID
                found.type = data.type
                found.categoryID = data.categoryID
                found.category = data.category
			}
		},
		orglistdelete(state, data) {
			let index = state.organisations.findIndex(org => org.id == data.id);
			if (index > -1) {
				state.organisations.splice(index, 1);
			}
		},
		// vehicles
		getVehicles(state, data) {
			if(data.status === parseInt(200)) {
				state.vehicles = data.vehicle
				state.make = data.make
				state.model = data.model
				state.vehicletype = data.type
				state.ownership = data.ownership
			}
		},
		vehiclelistindex(state, data) {
			let found = state.vehicles.find(vehicle => vehicle.id == data.id);
			if (!found) {
				state.vehicles.unshift(data)
			}
		},
		vehiclelistupdate(state, data) {
			let found = state.vehicles.find(vehicle => vehicle.id == data.id);

			if (found) {
				found.name=data.name
				found.registration=data.registration
                found.color=data.color
                found.chasis=data.chasis
                found.id=data.id
                found.image=data.image
                found.type_id=data.type_id
                found.type=data.type
                found.make_id=data.make_id
                found.make=data.make
                found.model_id=data.model_id
                found.model=data.model
                found.ownership_id=data.ownership_id
                found.ownership=data.ownership
			}
		},
		vehiclelistdelete(state, data) {
			let index = state.vehicles.findIndex(vehicle => vehicle.id == data.id);
			if (index > -1) {
				state.vehicles.splice(index, 1);
			}
		},
		// customers
		getCustomers(state, data) {
			if(data.status === parseInt(200)) {
				state.customers = data.customers
				state.minorganisations = data.organisations
			}
		},
		// drivers
		getDrivers(state, data) {
			if(data.status === parseInt(200)) {
				state.drivers = data.drivers
				state.componentvehicles = data.vehicles
				state.minorganisations = data.organisation
			}
		},
		newDriverP(state, data) {
			let found = state.drivers.find(driver => driver.id == data.id);
			if (!found) {
				state.drivers.unshift(data)
			}
		},
		driverEdit(state, data) {
			let found = state.drivers.find(driver => driver.id == data.id);

			if (found) {
				found.name = data.name
                found.email = data.email
                found.idno = data.idno
                found.id = data.id
                found.profile = data.profile
                found.telephone = data.telephone
                found.vname = data.vname
                found.username = data.username
                found.organisation = data.organisation
                found.organisation_id = data.organisation_id
                found.date = data.date
			}
		},
		driverCheckId(state, data) {
			let index = state.drivers.findIndex(driver => driver.id == data.driver.id);
            if (index > -1) {
              state.drivers.splice(index, 1, data.driver);
            }else {
            	let found = state.drivers.find(driver => driver.id == data.driver.id);
				if (!found) {
					state.drivers.unshift(data.driver)
				}
            }
		},
		driverDelete(state, data) {
			let index = state.drivers.findIndex(driver => driver.id == data.id);
			if (index > -1) {
				state.drivers.splice(index, 1);
			}
		},
		getDashboard(state, data) {

            state.dashboard_stats = data.dashboard_stats
		},
		getExpressway(state, data) {
            state.entrances = data.entrance
            state.exits = data.exit
		},
		// bookings
		getOrgCustomer(state,data) {

			state.tableloader = false

			state.reportorganisations = data.organisations
			state.reportcustomers = data.customers
		},
		getAddBookings(state,data) {

			state.addbookingloader = false
			state.tableloader = false

			state.bookingorganisations = data.organisations
			state.bookingcustomers = data.customers
			state.bookingvehicletype = data.vehicletypes
			// state.bookingvehicles = data[3].data.vehicles
			state.bookingdrivers = data.drivers

			state.reportorganisations = data.organisations
			state.reportcustomers = data.customers

			// state.bookingorganisations = data[0].data.organisations
			// state.bookingcustomers = data[1].data.customers
			// state.bookingvehicletype = data[2].data.vehicletypes
			// // state.bookingvehicles = data[3].data.vehicles
			// state.bookingdrivers = data[3].data.drivers
		},
		getUpdateDetails(state,data) {

			state.addbookingloader = false
			state.tableloader = false

			state.updateorganisations = data.organisations
		},
		getAssignVehicle(state,data) {

			state.addbookingloader = false
			state.tableloader = false

			state.assignvehicles = data.vehicles
			state.assigndrivers = data.drivers
		},
		getTodayBookings(state, data) {
			if(data.status === parseInt(200)) {

				state.tableloader = false
				state.todaybookings = []

				let formattedBookings = data.allbookings.map(item => {
				    let container = {};

				    container.name = item.passenger_name !== null ? item.passenger_name : '';
				    container.date = item.pickup_date !== null ? item.pickup_date : '';
				    container.time = item.pickup_time !== null ? item.pickup_time : '';
				    container.telephone = item.telephone !== null ? item.telephone : '';
				    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
				    container.from = item.travelfrom !== null ? item.travelfrom : '';
				    container.to = item.travelto !== null ? item.travelto : '';
				    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

				    container.status = item.status;
				    container.authorization = item.authorization;
				    container.remarks = item.remarks;
				    container.trip_type = item.trip_type;
				    container.request_category = item.request_category;

				    container.request_type = item.request_type;
				    container.ref_no = item.ref_no;
				    container.id = item.id;
				    container.vehicles = item.bookingvehicles;

				    state.todaybookings.push(container)
				});
			}
		},

		getBookings(state, data) {
			if(data.status === parseInt(200)) {

				state.tableloader = false
				state.searchdate = ""

				state.bookingcount = data.count

				let formattedBookings = data.allbookings.map(item => {
				    let container = {};

				    container.name = item.passenger_name !== null ? item.passenger_name : '';
				    container.date = item.pickup_date !== null ? item.pickup_date : '';
				    container.time = item.pickup_time !== null ? item.pickup_time : '';
				    container.telephone = item.telephone !== null ? item.telephone : '';
				    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
				    container.from = item.travelfrom !== null ? item.travelfrom : '';
				    container.to = item.travelto !== null ? item.travelto : '';
				    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

				    container.status = item.status;
				    container.authorization = item.authorization;
				    container.remarks = item.remarks;
				    container.trip_type = item.trip_type;
				    container.request_category = item.request_category;

				    container.request_type = item.request_type;
				    container.ref_no = item.ref_no;
				    container.id = item.id;
				    container.vehicles = item.bookingvehicles;

				    return container;
				});
				state.allbookings = formattedBookings
			}
		},
		loaddata(state, data) {

			state.tableloader = false

			if(data.status === parseInt(200)) {

				state.bookingcount = data.count

				let formattedBookings = data.allbookings.map(item => {
				    let container = {};

				    container.name = item.passenger_name !== null ? item.passenger_name : '';
				    container.date = item.pickup_date !== null ? item.pickup_date : '';
				    container.time = item.pickup_time !== null ? item.pickup_time : '';
				    container.telephone = item.telephone !== null ? item.telephone : '';
				    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
				    container.from = item.travelfrom !== null ? item.travelfrom : '';
				    container.to = item.travelto !== null ? item.travelto : '';
				    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

				    container.status = item.status;
				    container.authorization = item.authorization;
				    container.remarks = item.remarks;
				    container.trip_type = item.trip_type;
				    container.request_category = item.request_category;

				    container.request_type = item.request_type;
				    container.ref_no = item.ref_no;
				    container.id = item.id;
				    container.vehicles = item.bookingvehicles;

				    let found = state.allbookings.find(book => book.id == container.id);
					if (!found) {
						state.allbookings.push(container)
					}

				});

			}
		},
		loadmoredata(state, data) {

			if(data.status === parseInt(200)) {

				if(data.allbookings.length > 0) {
					let formattedBookings = data.allbookings.map(item => {
					    let container = {};

					    container.name = item.passenger_name !== null ? item.passenger_name : '';
					    container.date = item.pickup_date !== null ? item.pickup_date : '';
					    container.time = item.pickup_time !== null ? item.pickup_time : '';
					    container.telephone = item.telephone !== null ? item.telephone : '';
					    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
					    container.from = item.travelfrom !== null ? item.travelfrom : '';
					    container.to = item.travelto !== null ? item.travelto : '';
					    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

					    container.status = item.status;
					    container.authorization = item.authorization;
					    container.remarks = item.remarks;
					    container.trip_type = item.trip_type;
					    container.request_category = item.request_category;

					    container.request_type = item.request_type;
					    container.ref_no = item.ref_no;
					    container.id = item.id;
					    container.vehicles = item.bookingvehicles;

					    let found = state.todaybookings.find(book => book.id == container.id);
						if (!found) {
							state.todaybookings.push(container)
						}

					});

					let audio = new Audio('/assets/tone.mp3')
            		audio.play()
				}

			}
		},
		loadtodaybookings(state, data) {
			if(data.status === parseInt(200)) {

				if(state.todaybookings.length > 0) {
					if(state.todaybookings.length < data.allbookings.length) {
						let audio = new Audio('/assets/tone.mp3')
            			audio.play()
					}
				}

				let formattedBookings = data.allbookings.map(item => {
				    let container = {};

				    container.name = item.passenger_name !== null ? item.passenger_name : '';
				    container.date = item.pickup_date !== null ? item.pickup_date : '';
				    container.time = item.pickup_time !== null ? item.pickup_time : '';
				    container.telephone = item.telephone !== null ? item.telephone : '';
				    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
				    container.from = item.travelfrom !== null ? item.travelfrom : '';
				    container.to = item.travelto !== null ? item.travelto : '';
				    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

				    container.status = item.status;
				    container.authorization = item.authorization;
				    container.remarks = item.remarks;
				    container.trip_type = item.trip_type;
				    container.request_category = item.request_category;

				    container.request_type = item.request_type;
				    container.ref_no = item.ref_no;
				    container.id = item.id;
				    container.vehicles = item.bookingvehicles;

				    let found = state.todaybookings.find(book => book.id == container.id);
					if (!found) {
						state.todaybookings.unshift(container)
					}else {
						state.todaybookings.splice(found, 1, container);
					}

				});
			}
		},
		loadbookingsbydate(state, data) {

			state.tableloader = false
			state.allbookings = []

			if(data.status === parseInt(200)) {

				state.bookingcount = data.count

				let formattedBookings = data.allbookings.map(item => {
				    let container = {};

				    container.name = item.passenger_name !== null ? item.passenger_name : '';
				    container.date = item.pickup_date !== null ? item.pickup_date : '';
				    container.time = item.pickup_time !== null ? item.pickup_time : '';
				    container.telephone = item.telephone !== null ? item.telephone : '';
				    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
				    container.from = item.travelfrom !== null ? item.travelfrom : '';
				    container.to = item.travelto !== null ? item.travelto : '';
				    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

				    container.status = item.status;
				    container.authorization = item.authorization;
				    container.remarks = item.remarks;
				    container.trip_type = item.trip_type;
				    container.request_category = item.request_category;

				    container.request_type = item.request_type;
				    container.ref_no = item.ref_no;
				    container.id = item.id;
				    container.vehicles = item.bookingvehicles;

				    state.allbookings.push(container)

				});

			}
		},
		loadtodaybookingsbydate(state, data) {

			state.tableloader = false
			state.todaybookings = []

			if(data.status === parseInt(200)) {

				let formattedBookings = data.allbookings.map(item => {
				    let container = {};

				    container.name = item.passenger_name !== null ? item.passenger_name : '';
				    container.date = item.pickup_date !== null ? item.pickup_date : '';
				    container.time = item.pickup_time !== null ? item.pickup_time : '';
				    container.telephone = item.telephone !== null ? item.telephone : '';
				    container.organisation_name = item.organisation_name !== null ? item.organisation_name : 'Private';
				    container.from = item.travelfrom !== null ? item.travelfrom : '';
				    container.to = item.travelto !== null ? item.travelto : '';
				    container.vehicletype = item.vehicle_type_name !== null ? item.vehicle_type_name : '';

				    container.status = item.status;
				    container.authorization = item.authorization;
				    container.remarks = item.remarks;
				    container.trip_type = item.trip_type;
				    container.request_category = item.request_category;

				    container.request_type = item.request_type;
				    container.ref_no = item.ref_no;
				    container.id = item.id;
				    container.vehicles = item.bookingvehicles;

				    state.todaybookings.push(container)

				});

			}
		},
		clearDate(state) {
			state.searchdate = ""
		},
		searchBooking(state,data) {
			state.searchbooking = data
		},
		todaysearchBooking(state,data) {
			state.todaysearchbooking = data
		},
		fetchdisciplines(state,data) {
			state.disciplinaries = data.displinaries
		},
		fetchcommissions(state,data) {
			state.commissions = data.trips

			state.totalcommission = state.commissions.reduce((ac,item) => {
	              let totalamount = 0;
	              if(item.bookings.length > 0){
		            totalamount = (((item.bookings.reduce((acc,i) => {

		                          let amount = 0;
		                          if(item.date === i.booking.pickup_date && i.booking.status === 'Dropped' && i.booking.organisation_id !== null && i.booking.organisationrate.length > 0) {
		                            amount = i.booking.distance * (i.booking.organisationrate.filter((itm) => {
		                             return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
		                           }).length > 0 ? parseInt(i.booking.organisationrate.filter(itm => {
		                                return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
		                              })[0].rate) : 0);
		                          }

		                          return acc += amount; 

		                        },0) - (parseInt(item.fuel !== 0 ? 0 : item.fuel) + parseInt(item.toll !== 0 ? 0 : item.toll) + parseInt(item.miscellaneous !== 0 ? 0 : item.miscellaneous))) * (
		                      item.commission != null ? item.commission.rate : 0))/100)
		          }

	              return ac += totalamount; 

	            },0)


			state.todaycommission = state.commissions.reduce((ac,item) => {
	              let totalamount = 0;
	              if(item.date === state.today){
		              if(item.bookings.length > 0){
			            totalamount = (((item.bookings.reduce((acc,i) => {

			                          let amount = 0;
			                          if(item.date === i.booking.pickup_date && i.booking.status === 'Dropped' && i.booking.organisation_id !== null && i.booking.organisationrate.length > 0) {
			                            amount = i.booking.distance * (i.booking.organisationrate.filter((itm) => {
			                             return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
			                           }).length > 0 ? parseInt(i.booking.organisationrate.filter(itm => {
			                                return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
			                              })[0].rate) : 0);
			                          }

			                          return acc += amount; 

			                        },0) - (parseInt(item.fuel !== 0 ? 0 : item.fuel) + parseInt(item.toll !== 0 ? 0 : item.toll) + parseInt(item.miscellaneous !== 0 ? 0 : item.miscellaneous))) * (
			                      item.commission != null ? item.commission.rate : 0))/100)
			          }
		          }

	              return ac += totalamount; 

	            },0)
		},
		fetchratings(state,data) {
			state.ratings = data.ratings
		},
		getDocuments(state,data) {
			state.tptableloader = false
			state.documents = data.documents
		},
		
	},
	actions: {
		getShowDebitNotes ({ commit }) {
			return axios
			.get('/viewalldebitnotes',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getShowDebitNotes', data)
			})
		},
		getShowCreditNotes ({ commit }) {
			return axios
			.get('/viewallcreditnotes',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getShowCreditNotes', data)
			})
		},
		getShowPayments ({ commit }) {
			return axios
			.get('/viewallpayments',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getShowPayments', data)
			})
		},
		getShowInvoices ({ commit }) {
			return axios
			.get('/viewallinvoices',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getShowInvoices', data)
			})
		},
		getDocuments ({ commit, state },res) {
			state.tptableloader = true
			return axios
				.get(`/booking/documents/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
				.then(({ data }) => {
					commit('getDocuments', data)
				})
		},

		fetchratings ({ commit, state },res) {
			if(state.ratings.length === 0) {
				return axios
					.get(`/driver/ratings/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
					.then(({ data }) => {
						commit('fetchratings', data)
					})
			}
		},

		fetchcommissions ({ commit, state },res) {
			if(state.commissions.length === 0) {
				return axios
					.get(`/driver/tripscommission/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
					.then(({ data }) => {
						commit('fetchcommissions', data)
					})
			}
			
		},

		fetchdisciplines ({ commit, state },res) {
			if(state.disciplinaries.length === 0) {
				return axios
					.get(`/disciplinary/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
					.then(({ data }) => {
						commit('fetchdisciplines', data)
					})
			}
		},

		loadBookingData ({ commit },res) {
			return axios
			.get(`/loaddata/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('loaddata', data)
			})
		},
		loadmoretodaybookings ({ commit },res) {
			return axios
			.get(`/loadmoredata/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('loadmoredata', data)
			})
		},
		loadtodaybookings ({ commit,state }) {

			let count = state.todaybookings.map(item => { return item.id})

			const config = { headers: { 'Content-Type': 'multipart/form-data',"Access-Control-Allow-Origin": "*" } };
            let fd = new FormData();
            fd.append('id',JSON.stringify(count))

			return axios
			.post('/loadtoday-bookings',fd,config)
			.then(({ data }) => {
				commit('loadtodaybookings', data)
			})
		},
		loadbookingsbydate ({ commit },res) {
			return axios
			.get(`/loadbookingsbydate/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('loadbookingsbydate', data)
			})
		},
		loadtodaybookingsbydate ({ commit },res) {
			return axios
			.get(`/loadtodaybookingsbydate/${res}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('loadtodaybookingsbydate', data)
			})
		},
		getExpressway ({ commit }) {
			return axios
			.get('/dashboard/expressway',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getExpressway', data)
			})
		},
		getDashboard ({ commit }) {
			return axios
			.get('/dashboard/stats',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getDashboard', data)
			})
		},
		getOrgCustomer ({ commit }) {

			// let endpoints = [
			// 				  '/bookingorganisation',
			// 				  '/bookingcustomer'
			// 				];

			// return Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(data=> {
			// 		commit('getOrgCustomer', data)
			//     });
			return axios
			.get('/customerorganisationreport',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getOrgCustomer', data)
			})

		},
		// bookings
		getAddBookings ({ commit }) {

			// let endpoints = [
			// 				  '/bookingorganisation',
			// 				  '/bookingcustomer',
			// 				  '/bookingvehicletype',
			// 				  // '/bookingvehicle',
			// 				  '/bookingdriver'
			// 				];

			// return Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(data=> {
			// 		commit('getAddBookings', data)
			//     });

			return axios
			.get('/bookingorganisation',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getAddBookings', data)
			})
	
		},
		getUpdateDetails ({ commit }) {

			return axios
			.get('/updatedetailsorganisation',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getUpdateDetails', data)
			})
	
		},
		getAssignVehicle ({ commit }) {

			return axios
			.get('/assignvehicleorganisation',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getAssignVehicle', data)
			})
	
		},
		getBookings ({ commit }) {
			return axios
			.get('/bookings',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getBookings', data)
			})
		},
		getTodayBookings ({ commit }) {
			return axios
			.get('/today-bookings',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getTodayBookings', data)
			})
		},
		bookingCreated ({ commit }, res) {
			return axios
			.get(`/pull-newbooking/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('bookingCreated', data)
			})
			// commit('bookingCreated', data)
		},
		bookingEdit ({ commit }, res) {
			return axios
			.get(`/pull-bookingedit/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('bookingEdit', data)
			})
			// commit('bookingEdit', data)
		},
		bookingDelete ({ commit }, data) {
			commit('bookingDelete', data)
		},
		// customers
		newCustomer ({ commit }, data) {
			commit('newCustomer', data)
		},
		// users
		getUsers ({ commit }) {
			return axios
			.get('/users',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getUsers', data)
			})
		},
		userprofile ({ commit }, data) {
			commit('setUserData', data)
		},
		userslist ({ commit }, data) {
			commit('userslist', data)
		},
		userupdate ({ commit }, data) {
			commit('userupdate', data)
		},
		userdelete ({ commit }, data) {
			commit('userdelete', data)
		},
		// payment reversals
		getPaymentReversal ({ commit }) {
			return axios
			.get('/reversals',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getPaymentReversal', data)
			})
		},
		createreversal ({ commit }, res) {
			return axios
			.get(`/pull-reversal/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('createreversal', data)
			})
		},
		editreversal ({ commit }, res) {
			return axios
			.get(`/pull-reversal/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('editreversal', data)
			})
		},
		deletereversal ({ commit }, data) {
			commit('deletereversal', data)
		},
		// credit notes
		getCreditNotes ({ commit }) {
			return axios
			.get('/credit-notes',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getCreditNotes', data)
			})
		},
		createcreditnote ({ commit }, res) {
			return axios
			.get(`/pull-creditnote/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('createcreditnote', data)
			})
		},
		editcreditnote ({ commit }, res) {
			return axios
			.get(`/pull-creditnote/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('editcreditnote', data)
			})
		},
		deletecreditnote ({ commit }, data) {
			commit('deletecreditnote', data)
		},
		// debit notes
		getDebitNotes ({ commit }) {
			return axios
			.get('/debit-notes',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getDebitNotes', data)
			})
		},
		createdebitnote ({ commit }, res) {
			return axios
			.get(`/pull-debitnote/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('createdebitnote', data)
			})
		},
		editdebitnote ({ commit }, res) {
			return axios
			.get(`/pull-debitnote/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('editdebitnote', data)
			})
		},
		deletedebitnote ({ commit }, data) {
			commit('deletedebitnote', data)
		},
		// payments
		getpayments ({ commit }) {
			return axios
			.get('/payments',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getpayments', data)
			})
		},
		createpayment ({ commit }, res) {
			return axios
			.get(`/pull-payment/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('createpayment', data)
			})
		},
		editpayment ({ commit }, res) {
			return axios
			.get(`/pull-payment/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('editpayment', data)
			})
		},
		deletepayment ({ commit }, data) {
			commit('deletepayment', data)
		},
		// invoices
		getInvoices ({ commit }) {
			return axios
			.get('/invoices',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getInvoices', data)
			})
		},
		InvoiceBookings ({ commit }, data) {
			commit('InvoiceBookings', data)
		},
		createinvoice ({ commit }, res) {
			return axios
			.get(`/pull-invoice/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('createinvoice', data)
			})
		},
		editinvoice ({ commit }, res) {
			return axios
			.get(`/pull-invoice/${res.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('editinvoice', data)
			})
		},
		deleteinvoice ({ commit }, data) {
			commit('deleteinvoice', data)
		},
		// organisations
		getOrganisation ({ commit }) {
			return axios
			.get('/organisation',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getOrganisation', data)
			})
		},
		orglistindex ({ commit }, data) {
			commit('orglistindex', data)
		},
		orglistupdate ({ commit }, data) {
			commit('orglistupdate', data)
		},
		orglistdelete ({ commit }, data) {
			commit('orglistdelete', data)
		},
		// vehicles
		getVehicles ({ commit }) {
			return axios
			.get('/vehicle',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getVehicles', data)
			})
		},
		vehiclelistindex ({ commit }, data) {
			commit('vehiclelistindex', data)
		},
		vehiclelistupdate ({ commit }, data) {
			commit('vehiclelistupdate', data)
		},
		vehiclelistdelete ({ commit }, data) {
			commit('vehiclelistdelete', data)
		},
		// customers
		getCustomers ({ commit }) {
			return axios
			.get('/allcustomers',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getCustomers', data)
			})
		},

		// driver
		getDrivers ({ commit }) {
			return axios
			.get('/driver',{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('getDrivers', data)
			})
		},
		newDriverP ({ commit }, data) {
			commit('newDriverP', data)
		},
		driverEdit ({ commit }, data) {
			commit('driverEdit', data)
		},
		driverCheckId ({ commit }, data) {
			return axios
			.post('/driver/pulldetails', {id:data},{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('driverCheckId', data)
			})
		},
		createDriver ({ commit }, credentials, config) {
			return axios
			.post('/driver', credentials, config)
			.then(({ data }) => {})
		},
		updateDriver ({ commit }, credentials) {
			return axios
			.post(`/driver/${credentials.id}`, credentials.data,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {})
		},
		deleteDriver ({ commit }, credentials) {
			return axios
			.delete(`/driver/${credentials.id}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {})
		},
		driverDelete ({ commit }, data) {
			commit('driverDelete', data)
		},
		// account
		login ({ commit }, credentials) {
			return axios
			.post('/login', credentials,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('setUserData', data)
			})
		},
		signup ({ commit }, credentials) {
			return axios
			.post('/signup', credentials,{ headers: { "Access-Control-Allow-Origin": "*" } })
			.then(({ data }) => {
				commit('setUserData', data)
			})
		},

		logout ({ commit, state }) {
			commit('clearUserData')
			// return axios
			// .post('/logout', 
			// 	{id: state.user.user.id})
			// .then(({ data }) => {
			// 	if(data.status == parseInt(200)) {
			// 		commit('clearUserData')
			// 	}
			// })
		}
	},
	getters : {
		isLogged: state => !!state.user
	},
	modules: {
	}
})
