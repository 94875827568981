<template>
	<div class="badges">
    <!-- 15min badge -->
      <v-btn
        class="white--text countin15 mr-1"
        style="background:#FFA500 !important"
        depressed
        small
        @click="filterRecord('in15')"
      >
        In 15mins <span> ({{ in15count }})</span>
      </v-btn>
    <!-- over 15 badge -->
      <v-btn
        class="white--text countover15 mr-1"
        style="background:#B9CD99 !important"
        depressed
        small
        @click="filterRecord('over15')"
      >
        Over 15mins <span> ({{ over15count }})</span>
      </v-btn>
    <!-- late badge -->
      <v-btn
        class="white--text countlate mr-1"
        style="background:rgba(255, 0, 0, 1) !important"
        depressed
        small
        @click="filterRecord('late')"
      >
        Late <span> ({{ latecount }})</span>
      </v-btn>
    <!-- 30min badge -->
      <v-btn
        class="white--text countin30 mr-1"
        depressed
        small
        @click="filterRecord('in30')"
        style="background:rgba(104, 184, 227, 1) !important"
      >
        In 30mins <span> ({{ in30count }})</span>
      </v-btn>
    <!-- 2hrs badge -->
      <v-btn
        class="white--text countin2 mr-1"
        style="background:rgba(255, 0, 0, 1) !important"
        depressed
        small
        @click="filterRecord('in2')"
      >
        In 2hrs <span> ({{ in2count }})</span>
      </v-btn>
    <!-- over2hrs badge -->
      <v-btn
        class="black--text countover2 mr-1"
        style="background:#FFFF99 !important"
        depressed
        small
        @click="filterRecord('over2')"
      >
        Over 2hrs <span> ({{ over2count }})</span>
      </v-btn>
    <!-- cancelled badge -->
      <v-btn
        class="white--text countcancelled mr-1"
        style="background:rgb(192, 192, 192) !important"
        depressed
        small
        @click="filterRecord('cancelled')"
      >
        Cancelled <span> ({{ cancelledcount }})</span>
      </v-btn>
    <!-- allcount badge -->
      <v-btn
        class="white--text countall"
        depressed
        small
        @click="filterRecord('')"
      >
        All <span> ({{ allcount }})</span>
      </v-btn>
  </div>
</template>
<style>
/*table color variant*/
.late, .countlate{color: #000 !important;background:rgba(255, 0, 0, 0.4) !important}
.in15, .countin15{color: #000 !important;background:#FFA500 !important}
.over15, .countover15{color: #000 !important;background:#B9CD99 !important}
.in30, .countin30{color: #000 !important;background:rgba(104, 184, 227, 1) !important}
.in2, .countin2{color: #000 !important;background:rgba(255, 0, 0, 0.4) !important}
.over2, .countover2{color: #000 !important;background:#FFFF99 !important}
.cancelled, .countcancelled{color: #000 !important;background:rgb(192, 192, 192) !important}
.black, .countblack{background:#000000 !important;color: #fff !important;}
.all, .countall{
    background-image: linear-gradient(to bottom right, rgba(255,45,45.6), rgba(255,165,0,.6), rgba(104,184,227.6), rgba(255,0,0,.6), rgba(192,192,192,.6)) !important;
}
.countover2 > .v-badge__wrapper > .v-badge__badge{
  color: #000 !important;
}
.badges .v-btn__content {
    font-size: .6rem !important;
}

.badges .v-btn:not(.v-btn--round).v-size--small {
    height: 30px !important;
    min-width: 20px !important;
}

  .badges{
    line-height: 3;
  }
  .badges .v-badge{
    margin-right: .5rem !important;
  }
  .v-badge__wrapper span{
    z-index: 1000111 !important;
  }
  .over2 .v-badge__wrapper span{
    color: #000;
  }
  .v-badge .v-btn__content{
    font-size:.2rem !important;
  }
  .v-badge, .badges .v-btn {
    border-radius: 2px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	name: 'bookingcounts',
	components: {
	},
	data: () => ({

		
	}),
	methods: {

	  filterRecord(res) {
        this.$store.commit('searchFilter', res)
      },
		
	},
	computed: mapState(['in15count','over15count','in30count','in2count','over2count','latecount','cancelledcount','allcount','dayscount']),

	mounted() {
  },
	created() {}
}
</script>