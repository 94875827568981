import { render, staticRenderFns } from "./returnbooking.vue?vue&type=template&id=2214c27a&scoped=true&"
import script from "./returnbooking.vue?vue&type=script&lang=js&"
export * from "./returnbooking.vue?vue&type=script&lang=js&"
import style0 from "./returnbooking.vue?vue&type=style&index=0&id=2214c27a&prod&scoped=true&lang=css&"
import style1 from "./returnbooking.vue?vue&type=style&index=1&id=2214c27a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2214c27a",
  null
  
)

export default component.exports