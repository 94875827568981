<template>
  <div class="dashboard">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="7">
            <v-card class="card-shadow" style="height:350px !important">
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:.9rem !important;width:100%">
                  Today Bookings
                </p>
              </v-card-title>
              <v-card-text>
                <highcharts class="todaych" id="container" :options="pieOptions" ref="piechart"></highcharts>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-card class="card-shadow" style="height:350px !important">
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:.9rem !important">
                  Booking Statistics {{ currentyear }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-simple-table dense v-if="dashboard_stats !== null">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Time frame
                        </th>
                        <th class="text-left">
                          Bookings
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      >
                        <td>Today</td>
                        <td>{{ dashboard_stats.today_booking | currency('',0) }}</td>
                      </tr>
                      <tr
                      >
                        <td>This Week</td>
                        <td>{{ dashboard_stats.weeks_booking | currency('',0) }}</td>
                      </tr>
                      <tr
                      >
                        <td>This Month</td>
                        <td>{{ dashboard_stats.months_booking | currency('',0) }}</td>
                      </tr>
                      <tr
                      >
                        <td>This Year</td>
                        <td>{{ dashboard_stats.years_booking | currency('',0) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="7">
            <v-card class="card-shadow">
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:.9rem !important;width:100%">
                  Monthly Organisation Bookings {{ currentyear }}
                </p>
              </v-card-title>
              <v-card-text>
                <highcharts class="hc" id="container" :options="chartOptions" ref="chart"></highcharts>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6">
            <v-card class="card-shadow">
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:.9rem !important">
                  Organisation Bookings
                </p>
              </v-card-title>
              <v-card-text>
                <v-simple-table dense v-if="orgchartbooking !== null">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Organisation
                        </th>
                        <th class="text-left">
                          Bookings
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(org, index) in orgchartbooking" :key="index">
                        <td>{{ org.organisation }}</td>
                        <td>{{ org.bookings | currency('',0) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col> -->
        </v-row>
      </v-container>
  </div>
</template>
<style scope>
   .bookingimg{
        width: 150px !important;
        position: absolute;
        right: -1rem;
        bottom: 3rem;
   }
</style>
<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
      font-weight: 500;
      height: 25px !important;
  }
</style>
<script>
import axios from 'axios'
// axios.defaults.withCredentials = false
export default {
    name: "dashboard",
    data: () => ({

      fields: [
        '#',
        { key: 'Name', stickyColumn: true, isRowHeader: true, variant: 'dark' },
        'Email',
        'Telephone',
        'Address',
        'Category',
        ],
        name: '',
        chartOptions: {
          chart: {
              type: 'column'
          },
          title: {
              text: ''
          },
          labels: {
              items: []
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Bookings'
              }
          },
          xAxis: {
              categories: []
          },
          tooltip: {
              headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          },
          plotOptions: {
              column: {
                  pointPadding: 0.2,
                  borderWidth: 0
              }
          },
          series: []
        },
        pieOptions: {
          chart: {
              type: 'pie',
              marginTop: 0,
              height: 280
          },
          title: {
              text: ''
          },
          tooltip: {
          headerFormat: '',
          pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
              'Bookings: <b>{point.y}</b><br/>'
          },
           plotOptions: {
              pie: {
                  size: 200
              }
          },
          series: []
        },
        monthname:"",
        currentyear: ""
    }),
    created() {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

      const d = new Date();
      this.monthname = month[d.getMonth()];
      this.currentyear = d.getFullYear();
    },
    mounted(){
      // if(this.$store.state.user == null)
      //   this.name = this.$store.state.user.user.name

      this.getDashboard()
      this.getCharts();
    },
    computed: {
      months() {
        return this.$store.state.categories
      },
      statisticsChart() {
        return this.$store.state.arrayChart
      },
      dashboard_stats() {
        return this.$store.state.dashboard_stats
      },
    },
    methods: {

      async getCharts(){
        await axios.get('/dashboard/charts').then(response=>{
            this.chartOptions.xAxis.categories = response.data.months
            this.chartOptions.series = response.data.series

            this.pieOptions.series = response.data.todayseries

        }).catch(error=>{
            console.log(error)
        })
      },

      async getDashboard(){
        this.$store
          .dispatch('getDashboard')
          .then(() => {
          })
          .catch(() => {
          })
      },
    }
}
</script>