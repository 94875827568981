<template>
    <!-- BEGIN: Content-->
    <div class="todaybooking mt-3">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important;padding-bottom: 0.3rem !important;">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-0 pr-4 pl-2">
                <v-col md="12" cols="12" sm="12" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;align-items:center;justify-content:space-between;flex-direction:row">
                    <bookingcounts/>
                    <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
                      <v-btn
                        color="#000000"
                        class="ma-1 white--text"
                        depressed
                        link
                        :to="{name:'returnBooking'}"
                        size="small"
                      >
                        Return booking
                        <v-icon
                          right
                          dark
                        >
                          mdi-plus
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="#000000"
                        class="ma-1 white--text"
                        depressed
                        link
                        :to="{name:'addBookingList'}"
                        size="small"
                      >
                        Add booking
                        <v-icon
                          right
                          dark
                        >
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-1 py-0" style="padding-top:0 !important;">
            <v-card class="card-shadow pb-0 pt-0">
              <div style="width:100%;height:.3rem !important">
                  <v-progress-linear
                    color="#008000"
                    buffer-value="0"
                    stream
                    height="3"
                    :active="tableloader"
                  ></v-progress-linear>
                </div>
              <v-card-title class="pt-0 mt-0" style="display:flex;align-items:center;justify-content:flex-end;height:5rem">
                <TodayBookingSearch/>
              </v-card-title>
              <v-card-text class="px-0 pb-0 cardtext">

                    <div class="classloader">
                       <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="tableloader"/>
                    </div>
                    <div class="empty-state" v-if="allbookings.length === 0 && !tableloader">
                      <div class="empty-state__content">
                        <div class="empty-state__icon">
                          <v-img src="@/assets/icon.png" alt=""></v-img>
                        </div>
                        <div class="empty-state__message">No records available.</div>
                      </div>
                    </div>

                    <v-virtual-scroll
                        :items="allbookings"
                        :item-height="23"
                        renderless 
                        id="virtualscroll"
                        class="todaybooking"
                       >
                        <template v-slot:default="{ item }">
                            <div class="tcell" :class="item.color" @click="bookingdetailsupdate(item.id)">
                              <div class="name">{{ item.name }}</div>
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.time }}
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.date }}
                            </div>
                            <div class="tcell" :class="item.color">
                              <div>{{ item.telephone }}</div>
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.organisation_name }}
                            </div>
                        
                            <div class="tcell" :class="item.color">
                              {{ item.from }}
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.to }}
                            </div>
                           
                            <div class="tcell" :class="item.color">
                              <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          icon
                                          v-bind="attrs"
                                          small
                                          v-on="on">
                                        <v-icon>ti-arrow-circle-down</v-icon>
                                      </v-btn>
                                    </template>
                                    
                                    <v-list-item-group
                                      color="primary"
                                      class="actions"
                                      >

                                      <v-list-item
                                        @click="bookingprofile(item.id)"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-account</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Profile</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        link
                                        :to='{name:"bookingEdit",params:{id:item.id}}'
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-pencil</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="assignvehicle(item.id)"
                                        v-if="item.status != 'Cancelled' && item.status != 'Dropped' && item.authorization == 'true' && item.authorization != 'declined'"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-taxi</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Assign Vehicle</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="tripprocess(item.id, 'confirmtrip')"
                                        v-if="item.status == 'Active' && item.authorization == 'true' && item.authorization != 'declined'"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-check</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Confirm</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="undocancel(item.id)"
                                        v-if="item.status == 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-arrow-u-left-top</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Reinstate</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="reassignride(item.id)"
                                        v-if="item.authorization == 'true' && item.authorization != 'declined'"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-redo</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Unallocate/Rollback</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="messageclient(item.id)"
                                        v-if="item.authorization == 'true' && item.authorization != 'declined'"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-chat-processing-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Send Message</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="cancelbooking(item.id)"
                                        v-if="item.status !== 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-close</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Cancel</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        @click="bookingdocument(item.id)"
                                        >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-file-document-multiple-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Documents</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                    </v-list-item-group>
                                   
                              </v-menu>
                            </div>
                            <div class="tcell" :class="item.color">
                                <div v-if="item.vehicles.length > 0" class="name"><span v-for="(res,i) in item.vehicles" :key="i">{{ res.name }} - {{ res.registration }},</span></div>
                                <div v-else>Pending</div>
                            </div>
                            <div class="tcell" :class="item.color">
                              <v-chip
                                dark
                                small
                              >
                                {{ item.vehicletype }}
                              </v-chip>
                            </div>
                            <div class="tcell" :class="item.color">
                              {{ item.request_category }}
                            </div>
                            <div class="tcell" :class="item.color">
                              <span v-if="item.status && item.authorization == 'true'">{{ item.status }}</span>
                              <v-chip
                                dark
                                color="danger"
                                small
                                v-if="item.authorization == 'false'"
                              >
                                <v-icon>mdi-account-lock</v-icon>
                              </v-chip>
                              <v-chip
                                    dark
                                    color="warning"
                                    small
                                    v-if="item.authorization == 'declined'"
                                  >
                                    <v-icon>mdi-cancel</v-icon>
                                  </v-chip><br>
                              <small style="font-size:.69rem;" v-if="item.authorization == 'declined'" v-for="res in item.declined" :key="res.id">
                                        ::{{ res.reason }}
                                      </small>
                            </div>
           
                            <div class="tcell" :class="item.color">
                                {{ item.flight }}
                            </div>
                            <div class="tcell" :class="item.color">
                              <span
                                v-if="item.remarks !== null"
                              >
                                {{ item.remarks }}
                              </span>
                            </div>
                           <!--  <div class="tcell" :class="item.color">
                              <span
                                v-if="item.cancellation_reason !== null"
                              >
                                {{ item.cancellation_reason }}
                              </span>
                            </div> -->
                        </template>
                      </v-virtual-scroll>

                  <!--   </li>
                </ul> -->

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <SendMessage v-model="messagecliendivialog" :messageuserid="messageuserid" :profiledetails="profiledetails"/>

      <AssignVehicle v-model="assignvehicledialog" :toassinguser="toassinguser" :toassignuserid="toassignuserid" :toassigneduservehicle="toassigneduservehicle"/>

      <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>

      <CancelBooking v-model="canceldialog" :selected="selected"/>
      <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
      <BookingDocument v-model="bookingdocumentdialog" v-if="bookingid !== null"/>

      <v-dialog
          v-model="progressdialog"
          hide-overlay
          persistent
          width="300"
          >
          <v-card
            color="#F9780D"
            dark
            class="loaderoverlay"
          >
            <v-card-text>
              Please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

    </div>
</template>
<style scoped>
  @import "@/assets/css/todaybooking_scoped";
</style>
<style>
  @import "@/assets/css/todaybooking";
</style>
<script>
import bookingcomponent from '@/components/bookings/bookingcomponent'
import bookingcounts from '@/components/bookings/bookingcounts'

import SendMessage from '@/components/bookings/submodules/today/sendmessage'
import ClientProfile from '@/components/bookings/submodules/today/clientprofile'
import AssignVehicle from '@/components/bookings/submodules/today/assignvehicle'
import CancelBooking from '@/components/bookings/submodules/today/cancelbooking'
import TodayBookingSearch from '@/components/subcomponents/todayBookingSearch'
import UpdateDetail from '@/components/subcomponents/updatedetails'
import BookingDocument from '@/components/subcomponents/bookingDocument'

import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import Vue from 'vue'
import { mapState } from 'vuex'
import {ability} from '@/services/ability'
import { format, subDays } from 'date-fns'
import $ from 'jquery';
import Spinner from 'vue-spinkit'
import Pusher from 'pusher-js'

let pusher = new Pusher('c70815fa280f47b78ab9', {
          cluster: 'ap2'
        });

export default {
    name:"bookingList",
    components: { VueGooglePlaces,bookingcomponent,bookingcounts, SendMessage, ClientProfile, AssignVehicle, CancelBooking,TodayBookingSearch,Spinner,UpdateDetail, BookingDocument},
    data(){
        return {
          range: {
            start: new Date(2020, 0, 1),
            end: new Date(2020, 0, 5)
          },
          progressdialog: false,
           fav: true,
           height: '100%',
          hints: true,
          reveal: false,
          labelpickupl: "",
          labeldropl:"",
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          messagecliendivialog:false,
          valid: true,
          menu1:false,
          menu2:false,
          menu:false,
          search: '',
          searchmenu:false,
          bookingdocumentdialog: false,
          assignvehicledialog: false,
          reassignvehicledialog: false,
          profiledialog: false,
          bulkchecked:false,
          country: 'ke',
          center: null,
          apiKey: 'AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8',
          isBusy: false,
            loading: false,
           disabled: false,
          searchResults: [],
          service: null,
          late:"#FF2D2D",
          in15:"#FFA500",
          in30:"#68B8E3",
          in2:"#FF0000",
          over2:"#FFF000",
          cancelled:"#C0C0C0",
          item:{
              distance: "",
              to: "",
              from: "",
              fromlat: "",
              fromlong: "",
              tolat: "",
              tolong: "",
              name: "",
              telephone:"",
              orderedby:"",
              pickupdate:"", 
              pickuptime:"",
              organisation_id:"",
              vehicle_id:"",
              flight:"",
              frequency:"",
              enddate:"",
              remarks:"",

              updateID: "",
              _method:"patch"
            },
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Bookings',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Passenger', align: 'start', value: 'name' },
            { text: 'Time', value: 'time', filterable: false },
            { text: 'Date', value: 'date', filterable: false },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Company', value: 'organisation_name' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Action', value: 'action' },
            { text: 'Assigned Vehicle', value: 'assigned' },
            { text: 'Car Option', value: 'vehicletype_type' },
            { text: 'Category', value: 'request_category' },
            { text: 'Status', value: 'status' },
            { text: 'Flight', value: 'flight' },
            { text: 'Remarks', value: 'remarks' },
            // { text: 'Cancellation', value: 'reason' },
           ],
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
            updateModal: null,
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            assignuservehicle: null,
            toassinguser:[],
            toassignuserid: null,
            toassignuserdriver: "",
            toassignuservehicle: "",
            toassigneduservehicle: null,
            messageuserid: null,
            start: 0,
            rowHeight: 24,
            perPage: 50,

            timeout: null,
            searchstatus:null,
            searchStatus: '',
            dates: {},
            date: '',
            show: false,
            rangedetails: null,
            closeIcon: 'mdi-close',
            profiledetails: [],
            selected:null,
            reason: '',
            canceldialog:false,
            search: "",
            updatedetails: [],
            updatedialog: false,
            timer: null
          }
    },
    beforeMount() {
      if(!ability.can('booking-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    mounted(){

      if(this.allbookings.length == 0) {
        this.getTodayBookings()
      }

      if(this.allbookings.length ==0) {
        this.$store.state.tableloader = true
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

      this.pusher()

    },
    serverPrefetch () {},
    watch: {
        booksearch: function(newVal, oldVal) {
            this.search = newVal
        }
    },
    computed: {
      ...mapState({
        searchFilter: 'searchFilter'
      }),

      tableloader() {
        return this.$store.state.tableloader
      },

      booking() {

        var data = []

        for (var i = 0; i < this.$store.state.todaybookings.length; i++) {

          var elem = this.$store.state.todaybookings[i];
          var minutes = Vue.filter('minutesremaining')(this.$store.state.todaybookings[i].date+' '+this.$store.state.todaybookings[i].time)

          if(minutes >= 0 && minutes <= 15) {
            elem.color = 'in15'
          }else if(minutes > 15 && minutes <= 30) {
            elem.color = 'in30'
          }else if(minutes > 30 && minutes <= 120) {
            elem.color = 'in2'
          }else if(minutes < 0 && minutes >= -15) {
            elem.color = 'over15'
          }else if(minutes < -15 && minutes >= -120) {
            elem.color = 'over2'
          }else if(minutes < -120 && minutes >= -1440) {
            elem.color = 'late'
          }else if(minutes > -1440){
            elem.color = "toolate"
          }else {
            elem.color = "waiting"
          }

          // if(this.$store.state.todaybookings[i].status == "Waiting") {
          //   elem.color = "waiting"
          // }

          if(this.$store.state.todaybookings[i].status == 'Cancelled') {
            elem.color = 'cancelled'
          }

          data.push(elem)

        }

        this.$store.commit('in15count', data.filter(res => res.color === 'in15').length)
        this.$store.commit('over15count', data.filter(res => res.color === 'over15').length)
        this.$store.commit('in30count', data.filter(res => res.color === 'in30').length)
        this.$store.commit('in2count', data.filter(res => res.color === 'in2').length)
        this.$store.commit('over2count', data.filter(res => res.color === 'over2').length)
        this.$store.commit('latecount', data.filter(res => res.color === 'late').length)
        this.$store.commit('cancelledcount', data.filter(res => res.color === 'cancelled').length)
        // this.$store.commit('dayscount', data.filter(res => res.color === 'black').length)
        this.$store.commit('allcount', data.length)

        return data.filter(row => {

          this.start = 0

          const colorfilter = row.color.toString().toLowerCase()
          const status = row.status.toString().toLowerCase()

          let searchTerm = ''
          if(this.searchFilter !== null) {
            searchTerm = this.searchFilter.toLowerCase()
          }

          return colorfilter.includes(searchTerm) || status.includes(searchTerm)

        }).filter(item => {

          this.start = 0

          let name = "",ref_no = "",time = "",date = "",telephone = "",organisation = "",from = "", to = "",vehicletype = "",orderedby = "",status = "",category = "";
          if(item.ref_no != null && item.ref_no != 'null') {ref_no = item.ref_no}
          if(item.name != null && item.name != 'null') {name = item.name}
          if(item.time != null && item.time != 'null') {time = item.time}
          if(item.date != null && item.date != 'null') {date = item.date}
          if(item.telephone != null && item.telephone != 'null') {telephone = item.telephone}
          if(item.from != null && item.from != 'null') {from = item.from}
          if(item.to != null && item.to != 'null') {to = item.to}
          if(item.organisation_name != null && item.organisation_name != 'null') {organisation = item.organisation_name}
          if(item.vehicletype != null && item.vehicletype != 'null') {vehicletype = item.vehicletype_type}
          if(item.orderedby != null && item.orderedby != 'null') {orderedby = item.orderedby}
          if(item.status != null && item.status != 'null') {status = item.status}
          if(item.request_category != null && item.request_category != 'null') {category = item.request_category}

            if(this.search == null || this.search == 'null') {
              this.search = ''
            }

          return name.toLowerCase().includes(this.search.toLowerCase()) || ref_no.toLowerCase().includes(this.search.toLowerCase()) || time.includes(this.search) || date.includes(this.search) || telephone.includes(this.search) || organisation.toLowerCase().includes(this.search.toLowerCase()) || from.toLowerCase().includes(this.search.toLowerCase()) || to.toLowerCase().includes(this.search.toLowerCase()) || vehicletype.toLowerCase().includes(this.search.toLowerCase()) || orderedby.toLowerCase().includes(this.search.toLowerCase()) || status.toLowerCase().includes(this.search.toLowerCase()) || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search)) || category.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      booksearch() {
        return this.$store.state.todaysearchbooking 
      },
      allbookings() {
        return this.booking      
      },
      bookingid() {
        return this.$store.state.bookingid
      },
    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

        // const timer = setInterval(() => {
        //   this.$settimeout();
        // }, 1000*60*1.5);

        this.$once("hook:beforeDestroy", () => {
          // clearInterval(timer);
          pusher.unsubscribe('createBooking');
          pusher.unsubscribe('editBooking');
        });
    },
    methods:{
      pusher() {

        // add booking
        var channel = pusher.subscribe('createBooking');

        channel.bind('bookingCreated', data => {
            
            this.$store.dispatch('bookingCreated', data.data)
            .then(() => {

              var audio = new Audio('/assets/tone.mp3')
              audio.play()

              this.$toast.show(`<b>Booking Request</b><br><b>${data.data.passenger_name}</b> has requested a ride`,'',{
                position: 'topRight',
                image: 'https://cdn-icons-png.flaticon.com/512/619/619006.png',
                imageWidth: 50,
                balloon: false,
                messageColor:'#000000',
                messageLineHeight: '20',
                timeout: 7000,
              })

            }).catch(() => {
            }) 

        })
        // edit booking
        var editchannel = pusher.subscribe('editBooking');
        editchannel.bind('bookingEdit', data => {
            this.$store.dispatch('bookingEdit', data.data)
        })

      },
      onNoResult () {},
      async getTodayBookings(){

        await this.$store
          .dispatch('getTodayBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },

      bookingprofile(id) {
        this.$store.state.bookingid = id
        this.profiledialog = true
      },
      bookingdetailsupdate(id) {
        this.updatedetails = this.allbookings.filter(booking => booking.id === id )
        this.updatedialog = true
      },
      bookingdocument(id) {
        let details = this.allbookings.filter(booking => booking.id === id )

        this.$store.state.bookingid = parseInt(details[0].id)
        this.$store.state.documents = []

        this.$store
          .dispatch('getDocuments',parseInt(details[0].id))
          .then(() => {})
          .catch(() => {})

        this.bookingdocumentdialog = true
      },
      clear () {
        this.$refs.form.reset()
      },
      messageclient(id) {
        let data = this.allbookings.filter(booking => booking.id === id )

        this.messageuserid = id
        this.profiledetails = data

        this.messagecliendivialog = true
      },
      assignvehicle(id) {

        let data = this.allbookings.filter(booking => booking.id === id )
        this.toassinguser = data
        this.toassignuserid = id
        this.toassigneduservehicle = data[0].vehicle_type_id
        this.assignvehicledialog = true

        // axios.get(`/fetchfreevehicles/booking/${id}`).then(response=>{

        //   if(response.data.status == parseInt(200)) {
        //     this.$store.state.freevehicles = response.data.freevehicles
        //     this.$store.state.bookingdrivers = response.data.drivers
        //   }
          
        // })
        // .catch(() => {
        //   console.log("Unexpected error occured")
        // })

      },
      reassignride(id) {

        this.progressdialog = true

        axios.get(`/booking/unallocate-ride/${id}`).then(response=>{
          this.progressdialog = false
          if(response.data.status == parseInt(200)) {
            this.$store.dispatch('bookingEdit', response.data.data)
            this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
          }else {
            this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
          }
          
        })
        .catch(() => {
          this.progressdialog = true
          this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
          console.log("Unexpected error occured")
        })

      },
      tripprocess(id, status){

        this.progressdialog = true

        navigator.geolocation.getCurrentPosition(geolocation => {

            axios.get(`/booking/trip-process/${id}/${status}/${geolocation.coords.latitude}/${geolocation.coords.longitude}`).then(response=>{
              this.progressdialog = false
              if(response.data.status == parseInt(200)) {
                this.$store.dispatch('bookingEdit', response.data.data)
                this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
              }else {
                this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
              }
              
            })
            .catch(() => {
              this.progressdialog = true
              this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
              console.log("Unexpected error occured")
            })

        })

      },
      undocancel(id){

        this.progressdialog = true

        axios.get(`/reinstate/booking/${id}`).then(response=>{

          this.progressdialog = false

          if(response.data.status == parseInt(200)) {
            this.$store.dispatch('bookingEdit', response.data.data)
            this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
          }else {
            this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
          }
          
        })
        .catch(() => {
          this.progressdialog = false
          this.$toast.warning("An error occured",'Warning',{position: 'topRight',timeout: 7000,})
          console.log("Unexpected error occured")
        })
      },
      cancelbooking(id) {
        let index = this.booking.filter(item => item.id === id )
          if(index.length > 0) {

            this.selected = index[0]
            this.canceldialog = true

          }
      },
    }
}
</script>