<template>
	<div>
    <v-row>

      <v-col
        cols="12"
        md="5"
        >
        <v-row>
          <v-col cols="12">
            <p style="font-size:.95rem !important;display:flex;align-items:center;justify-content:space-between" class="mx-1"><strong>Rates</strong>
              <v-btn
                color="#000000"
                class="white--text"
                depressed
                @click.stop="dialog = true"
              >
              Rate
                <v-icon
                  right
                  dark
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Date
                    </th>
                    <th class="text-left">
                      Rate(%)
                    </th>
                    <th class="text-left">
                      Topup
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,i) in rates"
                    :key="i"
                  >
                    <td>{{ item.start_date }}</td>
                    <td>{{ item.rate }}</td>
                    <td>{{ item.topup | currency('',2) }}</td>
                    <td>
                      <v-icon
                          small
                          class="mr-2"
                          @click="edit(item.id)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          @click="deleteItem(item.id)"
                        >
                          mdi-delete
                        </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="px-0 mx-0 pb-0 mb-0 mt-5"
       >

       <v-fade-transition>
          <div>
            <div class="classloader">
               <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
            </div>
            <div class="empty-state" v-if="commissions.length === 0 && !overlay">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <v-img src="@/assets/icon.png" alt=""></v-img>
                </div>
                <div class="empty-state__message">No records available.</div>
              </div>
            </div>
          </div>
        </v-fade-transition>

        <v-card class="pb-3 pt-0 px-0 mx-0 mb-0" style="box-shadow:none;padding-bottom:0 !important">
  
          <v-card-text class="mx-0 px-0 pb-0 mb-0 cardtext1">

            <v-virtual-scroll
              :items="commissions"
              :item-height="23"
              renderless 
              id="virtualscroll"
              class="commissions"
             >
              <template v-slot:default="{ item }">
                  <div class="tcell"> {{ moment(item.date).format('MMM Do, YYYY') }}</div>
                  <div class="tcell">
                    <span v-if="item.fuel">{{ item.fuel | currency('') }}</span>
                    <span v-else>0</span>
                  </div>
                  <div class="tcell">
                    <span v-if="item.toll">{{ item.toll | currency('') }}</span>
                    <span v-else>0</span>
                  </div>
                  <div class="tcell">
                    <span v-if="item.miscellaneous">{{ item.miscellaneous | currency('') }}</span>
                    <span v-else>0</span>
                  </div>
                  <div class="tcell">
                    {{ 

                        (item.bookings.reduce((acc,i) => {

                          let amount = 0;
                          if(item.date === i.booking.pickup_date && i.booking.status === 'Dropped' && i.booking.organisation_id !== null && i.booking.organisationrate.length > 0) {
                            amount = i.booking.distance * (i.booking.organisationrate.filter((itm) => {
                                  return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                              }).length > 0 ? parseInt(i.booking.organisationrate.filter((itm) => {
                                  return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                              })[0].rate) : 0);
                          }

                          return acc += amount; 

                        },0)) | currency(' ',0)

                    }}
                  </div>
                  <div class="tcell">
                    {{ 

                        (((item.bookings.reduce((acc,i) => {

                          let amount = 0;
                          if(item.date === i.booking.pickup_date && i.booking.status === 'Dropped' && i.booking.organisation_id !== null && i.booking.organisationrate.length > 0) {
                            amount = i.booking.distance * (i.booking.organisationrate.filter((itm) => {
                             return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                           }).length > 0 ? parseInt(i.booking.organisationrate.filter(itm => {
                                return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                              })[0].rate) : 0);
                          }

                          return acc += amount; 

                        },0) - (parseInt(item.fuel !== 0 ? 0 : item.fuel) + parseInt(item.toll !== 0 ? 0 : item.toll) + parseInt(item.miscellaneous !== 0 ? 0 : item.miscellaneous))) * (
                      item.commission != null ? item.commission.rate : 0))/100) | currency(' ',0)

                    }}
                  </div>
              </template>
            </v-virtual-scroll>

          </v-card-text>
        </v-card>

      </v-col>

    </v-row>


    <!-- add commission rate dialog -->
      <v-dialog
        v-model="dialog"
        max-width="300px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">New Rate</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Rate*"
                      v-model="item.rate"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Topup*"
                      v-model="item.topup"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end commission rate -->

      <!-- commission rate update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="300px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Rate</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Rate*"
                      v-model="item.updaterate"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Topup*"
                      v-model="item.updatetopup"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update commission rate -->

	</div>
</template>
<style>
  .commissions .v-virtual-scroll__item{
    width:100%;
    display: grid !important;
     grid-template-columns: 16.666667% 16.666667% 16.666667% 16.666667% 16.666667% 16.666667%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
  .commissions .v-virtual-scroll{
    overflow: auto !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import Spinner from 'vue-spinkit'
import $ from 'jquery';

export default {
	name: 'commissions',
	components: {
    Spinner
	},
	data: () => ({
    overlay:false,
    searchfilter: "",
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      dialog: false,
      updatedialog: false,
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    fields: [
      { text: 'Date', value: 'cdate' },
      { text: 'Fuel', value: 'fuel' },
      { text: 'Toll', value: 'toll' },
      { text: 'Miscellaneous', value: 'miscellaneous' },
      { text: 'Total Amt.', value: 'amount' },
      { text: 'Commision', value: 'commision' },
     ],
     item:{
        rate: "",
        updaterate: "",
        topup: "",
        updatetopup: "",

        updateID: "",
        _method:"patch"
    },

	}),
	methods: {
		fetchcommissions() {
      this.overlay = true
      this.$store
          .dispatch('fetchcommissions',this.driverid)
          .then(() => {
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })
      },
       create(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('rate',this.item.rate)
          fd.append('topup',this.item.topup)
          fd.append('driver_id',this.$route.params.id)

          axios.post('/commissions', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Commission rate updated successfully','Success',{position: 'topRight',timeout: 7000,})

              this.$store.state.commission_rates = response.data.commissions
              this.clear()
              this.dialog = false
            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },
     clear () {
        this.$refs.form.reset()
      },
    edit(id) {
      let index = this.rates.filter(item => item.id === id );
      this.item.updaterate = index[0].rate
      this.item.updatetopup = index[0].topup
      this.item.updateID = index[0].id
      this.updatedialog = true
    },
    update(){

      if (this.$refs.form.validate()) {

      this.loading = true
      this.disabled = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('rate',this.item.updaterate)
        fd.append('topup',this.item.updatetopup)
        fd.append('_method',this.item._method)

        axios.post(`/commissions/${this.item.updateID}`, fd, config).then(response=>{
          this.loading = false
          this.disabled = false
          if(response.data.status === parseInt(200))
            this.$toast.success("Rate updated successfully",'Success',{position: 'topRight',timeout: 7000,})
            this.updatedialog = false

            let index = this.$store.state.commission_rates.findIndex(data => data.id == response.data.commission.id);
            if (index > -1) {
              this.$store.state.commission_rates.splice(index, 1, response.data.commission);
            }

          this.clear()
          this.item.updateID = ''
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      }

    },
	},
	props: {
     driverid: Number,
  },
  computed: {
    commissions() {
      return this.$store.state.commissions
    },
    rates() {
        return this.$store.state.commission_rates
      },
  },
	mounted() {
    if(this.commissions.length == 0){
      this.fetchcommissions()
    }

    let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
    this.fields.map(function(value, key) {
         headertitle +=`<div class="thead">${value.text}</div>`;
       });
    headertitle +=`</div></div>`;
    $('.v-virtual-scroll').prepend(headertitle)
	},
	created() {
     
    }
}
</script>