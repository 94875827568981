<template>
    <!-- BEGIN: Content-->
    <div class="orgProjectList mt-3">
      <v-container fluid>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex py-0 my-0" style="justify-content:space-between;align-items:center">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>

                  <div style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between;align-items:center">
                    <v-btn
                      color="#000000"
                      class="ma-1 white--text"
                      depressed
                      @click="dialog = true"
                    >
                      Rate
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </div>

                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          
          <v-col md="12" sm="12" class="my-1 py-0">
            <v-card class="card-shadow py-0">
              <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#008000"
                      buffer-value="0"
                      stream
                      height="3"
                      :active="tableloader"
                    ></v-progress-linear>
                  </div>
              <v-card-title style="display:flex;align-items:center;justify-content:flex-end">
                <v-col md="2" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                  <v-text-field
                            v-model="search"
                            label="Search rate"
                            solo
                            hide-details="auto"
                          ></v-text-field>
                </v-col>
              </v-card-title>
              <v-card-text class="px-0 pb-0">
                <div class="classloader">
                   <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="tableloader"/>
                </div>
                <div class="empty-state" v-if="rates.length === 0 && !tableloader">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <v-img src="@/assets/icon.png" alt=""></v-img>
                    </div>
                    <div class="empty-state__message">No records available.</div>
                  </div>
                </div>
                <v-data-table
                  dense
                  :headers="fields"
                  :items="rates"
                  :item-class="getRowClass"
                  class="elevation-0"
                  :search="searchfilter"
                  item-key="id"

                  disable-pagination
                  hide-default-footer
                  id="virtual-scroll-table"
                  v-scroll:#virtual-scroll-table="onScroll"
                >

                <template
                    v-if="start > 0"
                    v-slot:body.prepend
                  >
                    <tr>
                      <td
                        :colspan="fields.length"
                        :style="'padding-top:'+startHeight+'px !important'"
                      >
                      </td>
                    </tr>
                  </template>
                  <template
                    v-if="start + perPage < this.rates.length"
                    v-slot:body.append
                  >
                    <tr>
                      <td
                        :colspan="fields.length"
                        :style="'padding-top:'+endHeight+'px !important'"
                      >
                      </td>
                    </tr>
                  </template>

                  <template v-slot:item.entrance="{ item }">
                    {{ item.entrance.name }}
                  </template>
                  <template v-slot:item.exit="{ item }">
                    {{ item.exit.name }}
                  </template>
                  <template v-slot:item.rate="{ item }">
                    {{ item.rate }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            small
                            v-on="on">
                          <v-icon>ti-arrow-circle-down</v-icon>
                        </v-btn>
                      </template>
                      
                      <v-list-item-group
                        color="primary"
                        class="actions"
                        >

                          <v-list-item
                            @click="edit(item.id)"
                          >
                              <v-list-item-icon>
                                <v-icon color="#000000">mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item-content>
                          </v-list-item>

                        <v-list-item
                          link
                          @click="deleteItem(item.id)"
                          >
                              <v-list-item-icon>
                                <v-icon>mdi-delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Remove</v-list-item-title>
                              </v-list-item-content>
                        </v-list-item>

                      </v-list-item-group>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- user update modal-->
      <v-dialog
        v-model="dialog"
        max-width="550px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Rate</span>
          </v-card-title>

          <v-card-text>
              <v-container>

                <v-row
                    v-for="(rate, indx) in grouprates" 
                      :key="indx"
                      style="display:flex;align-items:center;justify-content:space-between"
                    >
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                      >
                    
                          <v-autocomplete
                            :items="entrances"
                            label="Select Entrance"
                            v-model="rate.entrance_id" 
                            :name="`grouprates[${indx}][entrance_id]`"
                            item-text="name"
                            item-value="id"
                            required
                            :rules="fieldRules"
                            @change="(event) => updateEntrance(event,indx)"
                            hide-details
                            solo
                          >
                            <template slot="selection" slot-scope="{ item, selected }">
                                {{ item.name }}
                            </template>
                            <template slot="item" slot-scope="{ item, tile }">
                                {{ item.name }}
                            </template>

                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          >
                          <v-autocomplete
                            :items="exits"
                            label="Select Exit*"
                            v-model="rate.exit_id"
                            item-text="name"
                            item-value="id"
                            required
                            :rules="fieldRules"
                            :name="`grouprates[${indx}][entrance_id]`"
                            @change="(event) => updateExit(event,indx)"
                            hide-details
                            solo
                          >
                            <template slot="selection" slot-scope="{ item, selected }">
                                {{ item.name }}
                            </template>
                            <template slot="item" slot-scope="{ item, tile }">
                                {{ item.name }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="2"
                          class="textinput"
                          >
                          <v-text-field
                            label="Rate*"
                            required
                            v-model="rate.rate" 
                            :name="`grouprates[${indx}][rate]`"
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>
                        </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        >
                        <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                          <v-icon @click="addRate" class="mx-3">mdi-plus</v-icon>
                          <v-icon @click="removeRate(indx)">mdi-close</v-icon>
                        </div>
                    </v-col>
                  </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="450px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Rate</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      :items="entrances"
                      label="Select entrance*"
                      v-model="item.updateentrance_id"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchorg"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      :items="exits"
                      label="Select exit*"
                      v-model="item.updateexit_id"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchtype"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      label="Rate*"
                      v-model="item.updaterate"
                      :rules="fieldRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>
<style scoped>
.textinput .v-text-field {
    margin-top: -1rem !important;
}
.v-data-table /deep/ .sticky-header {
  position: sticky !important;
  top: var(--toolbarHeight) !important;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset !important;
}

 #virtual-scroll-table {
    height: 75vh;
      max-height: 100%;
    overflow: auto;
  }

  .v-data-table__wrapper td, .v-data-table-header th{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th,.v-data-table-header > th{
      max-width: 170px !important;
      font-weight: 500;
      border:1px solid var(--border-color) !important;
      height:10px !important;
  }
  ::v-deep .v-data-table__empty-wrapper {
      display: none !important;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import Spinner from 'vue-spinkit'
export default {
    name:"RateList",
    components: { Spinner},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          searchfilter:'',
          search:"",
          searchorg:"",
          searchtype:"",
          valid: true,
          tableloader: false,
          dialog: false,
          updatedialog: false,
          grouprates: [
              {
                entrance_id: "",
                exit_id: "",
                rate: ""
              }
            ],
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Expressway',
              disabled: false,
              href: '',
            },
            {
              text: 'Rates',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Entrance', value: 'entrance' },
            { text: 'Exit', value: 'exit' },
            { text: 'Rate(Kes)', value: 'rate' },
            { text: 'Action', value: 'action' },
           ],
          item:{
              updateentrance_id:"",
              updateexit_id:"",
              updaterate:"",
              updateID: "",
              _method:"patch"
          },
            isBusy: false,
            loading: false,
           disabled: false,
           height: '100%',
            rowHeight: 25,
            perPage: 150,
            start: 0,
            timeout: null 
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      this.getRates()
    },
    serverPrefetch () {
      return this.getRates()
    },
    computed: {
      rates() {
        return this.$store.state.rates.filter(item => {
          this.start = 0
          return item.entrance.name.toLowerCase().includes(this.search.toLowerCase()) || item.exit.name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      entrances() {
        return this.$store.state.expresswayrates
      },
      exits() {
        return this.$store.state.exits
      },
    },
    methods:{
       addRate () {
        this.grouprates.push({
          entrance_id: "",
          exit_id: "",
          rate: ""
        })

      },
      removeRate (index) {
        this.grouprates.splice(index, 1);
      },
      updateEntrance(value,indx) {
        if(value !== null) {
          let found = this.grouprates[indx];
          if (found) {
            found.entrance_id = value
          }
        }
      },
      updateExit(value,indx) {
        if(value !== null) {
          let found = this.grouprates[indx];
          if (found) {
            found.exit_id = value
          }
        }
      },
      getRowClass(item) {
        return 'row-color' 
      },
      async getRates(){
        this.tableloader = true
        await axios.get('/expressway/rates').then(response=>{
            this.tableloader = false
            this.$store.state.expresswayrates = response.data.entrances
            this.$store.state.exits = response.data.exits
            this.$store.state.rates = response.data.rates
        }).catch(error=>{
          this.tableloader = false
            console.log(error)
        })
      },
      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.rates.length ?
            this.rates.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('rates',JSON.stringify(this.grouprates))

            await axios.post('/expressway/rates', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {

                this.$toast.success('Rate added successfully','Success',{position: 'topRight',timeout: 7000,})
                
                this.grouprates = []
                this.addRate()

                this.getRates()

                this.dialog = false

              }
         
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('entrance_id',this.item.updateentrance_id)
            fd.append('exit_id',this.item.updateexit_id)
            fd.append('rate',this.item.updaterate)
            fd.append('_method',this.item._method)

            await axios.post(`/expressway/rates/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {

                let index = this.$store.state.expresswayrates.findIndex(data => data.id == response.data.rate.id);
                  if (index > -1) {
                    this.$store.state.expresswayrates.splice(index, 1, response.data.rate);
                  }

                this.$toast.success('Rate updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.item.updateexit_id = ''
                this.item.updateentrance_id = ''
                this.item.updaterate = ''

                this.getRates()

                this.updatedialog = false

              }
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async edit(id) {
        let index = this.rates.filter(item => item.id === id )
        if(index.length > 0) {

          this.item.updateexit_id = parseInt(index[0].exit_id)
          this.item.updateentrance_id = parseInt(index[0].entrance_id)
          this.item.updaterate = index[0].rate
          this.item.updateID = index[0].id
          this.updatedialog = true

        }
        
      },
      deleteItem(id){
            if(confirm("Are you sure to delete rate ?")){
                axios.delete(`/expressway/rates/${id}`).then(response=>{
                  if(response.data.status === 200) {

                    let index = this.$store.state.expresswayrates.findIndex(data => data.id == response.data.rate.id);
                    if (index > -1) {
                      this.$store.state.expresswayrates.splice(index, 1);
                    }

                    this.$toast.success('Rate deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                  }
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
    }
}
</script>