<template>

  <div class="Login">
       <v-container fluid fill-height style="margin-top:2rem;background:#f5f5f5 !important">
          <v-layout align-center justify-center>
             <v-flex xs12 sm8 md4 lg3 xl3>
              <v-form  enctype="multipart/form-data" v-model="valid" ref="form" class="forms">
                <v-card class="card-shadow px-3">
                   <v-card-title color="primary">
                    <v-row>
                      <v-col cols="12" class="px-2">
                        <img src="/assets/images/logo/logo.png" style="width:25% !important;text-align:center">
                        <h2 class="card-title fw-bold mt-3">Reset Password? 🔒</h2>
                        <p class="card-text mb-2">Provide details below to reset your password</p>
                      </v-col>
                    </v-row>
                  </v-card-title>
                   <v-card-text style="padding:0 !important">
                       <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                         <v-text-field
                            id="password"
                            prepend-icon="mdi-lock"
                            name="password"
                            label="Password"
                            :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'password' : 'text'"
                            v-model="form.password"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                         <v-text-field
                            id="cpassword"
                            prepend-icon="mdi-lock"
                            name="password"
                            label="Confirm Password"
                            :append-icon="cvalue ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="() => (cvalue = !cvalue)"
                            :type="cvalue ? 'password' : 'text'"
                            v-model="form.password_confirm"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                   </v-card-text>
                   <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary"
                      :loading="loading"
                      :disabled="!valid"
                      @click="resetPassword"
                      >Confirm password</v-btn>
                   </v-card-actions>
                   <p class="text-center" style="padding:1rem"><router-link :to="{ name: 'Login' }"><i data-feather="chevron-left"></i> Back to login</router-link></p>
                </v-card>
               </v-form>
             </v-flex>
          </v-layout>
       </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'ResetPasswordForm',
    components: {
    },
    data: () => ({
      value: String,
      cvalue: String,
      valid: true,
      type: 'password',
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      loading: false,
      disabled: false,
      form: {
        email: null,
        password: null,
        password_confirm: null,
      }
    }),
    computed: {
      ...mapGetters([
        'isLogged'
        ])
    },
    methods: {
       resetPassword() {

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
           let fd = new FormData();
            fd.append('token',this.$route.params.token)
            fd.append('password',this.form.password)
            fd.append('password_confirm',this.form.password_confirm)

            axios.post("/reset", fd, config).then(response=>{

              this.loading = false
              this.disabled = false

              console.log(response)

              if(response.data.status === 404) {
                this.$toast.error(response.data.message,'Error',{position: 'topRight',timeout: 7000,})
              }
              if(response.data.status === 400) {
                this.$toast.error(response.data.message,'Error',{position: 'topRight',timeout: 7000,})
              }
              if(response.data.status === 200) {
                this.$toast.success('Password reset was successful','Success',{position: 'topRight',timeout: 7000,})
                this.$router.push({ name: 'Login' })
              }

              
            })
            .catch(() => {

              this.loading = false
              this.disabled = false

              this.$toast.error('Passwords does not match','Error',{position: 'topRight',timeout: 7000,})

            })

          }

        }
    }
}
</script>