<template>
    <!-- BEGIN: Content-->
    <div class="showInvoice mt-0">
      <v-container fluid>
        <v-form class="pt-4"  enctype="multipart/form-data" v-model="valid" ref="form">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" style="padding-top:0 !important">
              <v-card class="card-shadow px-0 pb-0">
                <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-3 pr-4">
                  <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                    <v-breadcrumbs
                      :items="breadcrumbs"
                      divider="/"
                    ></v-breadcrumbs>

                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col  cols="12" md="6" lg="5" xl="6" sm="12">
              <v-card class="card-shadow py-2 px-1 invoice">
                <v-list two-line>
                    <v-list-item-group
                    >
                      <template v-for="(item,index) in allinvoices">
                        <v-list-item @click="viewInvoice(item.id)">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>
                                <p class="text--primary mb-2" style="text-transform:uppercase;font-size:.75rem !important;width:100%">
                                  <span v-for="(item,index) in item.organisations" :key="index">{{ item.organisation.name }},</span>
                                </p>
                              </v-list-item-title>
                              <v-list-item-subtitle><p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;width:100%;font-weight:500 !important"><span style="color:#F98525 !important;">{{ item.invoice_no }}</span> | {{ moment(item.date).format('D MMM YYYY') }}</p></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-list-item-action-text>
                                <p class="text--primary mb-2" style="text-transform:uppercase;font-size:.85rem !important;width:100%;">
                                  {{ item.amount | currency(' ') }}
                                </p>
                                <p class="text--primary mb-0" v-if="item.status == 'DRAFT'" style="text-align:right;text-transform:uppercase;font-size:.75rem !important;width:100%;font-weight:500 !important;color:darkgrey !important">{{ item.status }}</p>
                                <p class="text--primary mb-0" v-if="item.status == 'PAID'" style="text-align:right;text-transform:uppercase;font-size:.75rem !important;width:100%;font-weight:500 !important;color:darkgreen !important">{{ item.status }}</p>
                                <p class="text--primary mb-0" v-if="item.status == 'OVERDUE'" style="text-align:right;text-transform:uppercase;font-size:.75rem !important;width:100%;font-weight:500 !important;color:#F98525 !important">{{ item.status }}</p>
                              </v-list-item-action-text>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                          v-if="index < allinvoices.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-pagination
                    v-model="page"
                    :length="Math.ceil(allinvoices.length/perPage)"
                  ></v-pagination>
              </v-card>
            </v-col>
            <v-col  cols="12" md="6" lg="7" xl="6" sm="12">
              <v-card class="card-shadow details p-0 mx-0">
                <v-row class="mx-0" style="background:#f5f7f9 !important">
                  <v-col cols="12" style="justify-content:flex-end;display:flex">

                    <v-btn
                      color="#dedede"
                      class="mx-2 mb-3"
                      text
                      link
                      :to='{name:"editInvoice",params:{id:item.id}}'
                    >
                    <v-icon>mdi-pencil</v-icon>
                    <span style="font-size:.75rem !important;color:#444 !important">Edit</span>
                  </v-btn>

                  <v-btn
                      color="#dedede"
                      class="mx-2 mb-3"
                      text
                      :loading="loadingpdf"
                      @click="downloadpdf"
                    >
                    <v-icon>mdi-file-pdf-box</v-icon>
                    <span style="font-size:.75rem !important;color:#444 !important">Pdf</span>
                  </v-btn>

                  <v-btn
                      color="#dedede"
                      class="mx-2 mb-3"
                      text
                      :loading="loadingexcel"
                      @click="downloadexcel"
                    >
                    <v-icon>mdi-file-excel-box</v-icon>
                    <span style="font-size:.75rem !important;color:#444 !important">Excel</span>
                  </v-btn>

                  </v-col>
                </v-row>
                <v-card-text class="py-5" v-if="details !== null" style="padding-right:2rem !important;padding-left:2rem !important">
                  <div class="ribbon ribbon-top-right">
                    <span>{{ details.status }}</span>
                  </div>
                  <v-row class="mt-4">
                    <v-col cols="12" md="6">
                      <img src="/assets/images/logo/logo.png" style="width:50% !important;">
                      <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                      Kandara Road, Hse 9, Kileleshwa</br>
                      Telephone: 0202011846/2011824</br>
                      Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                      Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                      PIN NO: P051199717F | VAT NO: P051199717F</p>

                      <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                      <p style="color:#F98525;font-size:.92rem !important;margin-top:-.8rem !important"><span v-for="(organisation,index) in details.organisations" :key="index">{{ organisation.organisation.name }}<br></span></p>
                    </v-col>
                    <v-col cols="12" md="6" style="text-align:right">
                      <h2 style="font-size:1.7rem !important">INVOICE</h2>
                      <p>#{{ details.invoice_no }}</p>
                      <p style="margin-top:3rem !important;font-size:.75rem !important">Balance Due</p>
                      <p style="font-size:1.2rem !important;color:#333 !important;font-weight:500 !important;margin-top:-1.1rem !important">{{ 
                        ( 
                         parseFloat(details.amount )) | currency(' ') }}</p>
                      <p style="margin-top:5rem !important;display:flex;justify-content:end">
                        <v-simple-table>
                          <template v-slot:default>
                              <tr>
                                <td class="py-2" style="padding-right:3rem !important">Invoice Date:</td>
                                <td class="py-2">{{ moment(details.date).format('Do MMM YYYY') }}</td>
                              </tr>
                              <tr>
                                <td class="py-2" style="padding-right:3rem !important">Due Date:</td>
                                <td class="py-2">{{ moment(details.due_date).format('Do MMM YYYY') }}</td>
                              </tr>
                          </template>
                        </v-simple-table>
                      </p>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="mt-2"
                        v-if="details.subject !== null || details.subject != 'null'"
                      >
                      <p style="font-size:.88rem !important;margin-top:0rem !important;border-bottom:1px dashed #333">{{ details.subject }}</p>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="mt-2"
                      >
                        <v-virtual-scroll
                          :items="details.bookings"
                          :item-height="23"
                          renderless 
                          id="virtualscroll"
                          class="invoice1"
                         >
                          <template v-slot:default="{ item }">
                              <div class="tcell">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-if="item.booking.passenger_name !== null" v-bind="attrs"
                                      v-on="on">
                                      {{ item.booking.passenger_name }}
                                    </span>
                                    <div v-else style="display:flex;flex-direction:column !important" v-bind="attrs"
                                      v-on="on">
                                      <span v-for="(passenger,index) in item.booking.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.passenger_name }}</span>
                                    </div>
                                  </template>
                                  <span>
                                    <span v-if="item.booking.passenger_name !== null">
                                      {{ item.booking.passenger_name }}
                                    </span>
                                    <div v-else style="display:flex;flex-direction:column !important">
                                      <span v-for="(passenger,index) in item.booking.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.passenger_name }}</span>
                                    </div>
                                  </span>
                                </v-tooltip>
                              </div>
                              <div class="tcell">
                                <div v-if="item.booking.passengers.length === 0">{{ item.booking.telephone }}</div>
                                <div v-else style="display:flex;flex-direction:column !important">
                                  <span v-for="(passenger,index) in item.booking.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.telephone }}</span>
                                </div>
                              </div>
                              <div class="tcell">{{ item.booking.pickup_date }} {{ item.booking.pickup_time }}</div>
                              <div class="tcell">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                      v-on="on">
                                      {{ item.booking.travelfrom }}
                                    </span>
                                  </template>
                                  <span>
                                    {{ item.booking.travelfrom }}
                                  </span>
                                </v-tooltip>
                              </div>
                              <div class="tcell">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                      v-on="on">
                                      {{ item.booking.travelto }}
                                    </span>
                                  </template>
                                  <span>
                                    {{ item.booking.travelto }}
                                  </span>
                                </v-tooltip>
                              </div>
                              <div class="tcell">
                                <span v-if="item.booking.vehicletype !== null">{{ item.booking.vehicletype.type }}</span>
                                <span v-else>N/A</span>
                              </div>
                              <div class="tcell">{{ item.booking.distance | currency('') }}</div>
                              <div class="tcell">
                                <div v-if="item.booking.organisationrate.length > 0">
                                  <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                                  <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">{{ Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                                  <span v-else>{{ item.booking.organisationrate[0].base_rate | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div>

                              <div class="tcell">
                                <div v-if="item.booking.organisationrate.length > 0">
                                  <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                  <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">{{ ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                  <span v-else>{{ ((item.booking.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div>
                              <div class="tcell">
                                <div v-if="item.booking.organisationrate.length > 0">
                                  <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ (Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) + ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                  <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">{{ (Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) + ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                  <span v-else>{{ (parseInt(item.booking.organisationrate[0].base_rate) + ((item.booking.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div>
                          </template>
                        </v-virtual-scroll>
                    </v-col>
                  </v-row>

                  <v-row class="mt-4 d-flex" style="justify-content:end !important">
                    <v-col
                        cols="12"
                        sm="8"
                        md="6"
                        lg="5"
                      >

                      <v-list two-line>
                          <v-list-item-group
                          >
                            <template>

                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <p class="text--primary mb-0" style="font-size:.85rem !important;width:100%">
                                        Sub Total
                                      </p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-action-text>
                                      <p class="text--primary mb-0" v-if="details.bookings.length > 0" style="font-size:.85rem !important;width:100%;">
                                        {{

                                          (details.bookings.reduce( (acc, item) => 
                                            {  
                                                let amt = 0;
                                                if(item.booking.organisationrate.length > 0) {
                                                  if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                                    amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                                  }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                                    amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                                  }else {
                                                    amt = parseInt(item.booking.organisationrate[0].base_rate)
                                                  }
                                                }
                                              return acc += amt; 
                                            }, 0) + details.bookings.reduce( (acc, item) => 
                                          {  

                                            let amt = 0, waitingfee = 0, subamt = 0;
                                            if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                              if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                              }
                                            }

                                            if(item.booking.waiting.length > 0) {
                                              subamt = item.booking.waiting.reduce((acc1, value) => { 
                                                        let total = 0;
                                                        if(value.starttime !== null && value.endtime !== null) {
                                                          
                                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                          }

                                                        }

                                                        return  acc1 += total
                                                      }, 0)
                                            }

                                            amt = subamt + waitingfee;
                                            
                                            return acc += amt; 

                                          }, 0)) | currency('')

                                        }}
                                      </p>
                                      <p class="text--primary mb-0" v-else style="font-size:.85rem !important;width:100%;">0.00</p>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>
                              <v-divider
                              ></v-divider>
                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <p class="text--primary mb-0" style="font-size:.85rem !important;width:100%">
                                        VAT<span v-if="vat != null">({{ vat.rate }}%)</span>
                                      </p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-action-text>
                                      <p class="text--primary mb-0" v-if="details.bookings.length > 0" style="font-size:.85rem !important;width:100%;">
                                        {{

                                          ((((details.bookings.reduce( (acc, item) => 
                                            {  
                                                let amt = 0;
                                                if(item.booking.organisation.vat !== '0') {
                                                  if(item.booking.organisationrate.length > 0) {
                                                    if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                                      amt = Math.round(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)));
                                                    }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                                      amt = Math.round(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)));
                                                    }else {
                                                      amt = parseInt(item.booking.organisationrate[0].base_rate)
                                                    }
                                                  }
                                                }
                                              return acc += amt; 
                                            }, 0) + details.bookings.reduce( (acc, item) => 
                                          {  

                                            let amt = 0, waitingfee = 0, subamt = 0;
                                            if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                              if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                              }
                                            }

                                            if(item.booking.waiting.length > 0) {
                                              subamt = item.booking.waiting.reduce((acc1, value) => { 
                                                        let total = 0;
                                                        if(value.starttime !== null && value.endtime !== null) {
                                                          
                                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                          }

                                                        }

                                                        return  acc1 += total
                                                      }, 0)
                                            }

                                            amt = subamt + waitingfee;
                                            
                                            return acc += amt; 

                                          }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                                        }}
                                      </p>
                                      <p class="text--primary mb-0" v-else style="font-size:.85rem !important;width:100%;">
                                        0.0
                                      </p>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>
                              <v-divider
                              ></v-divider>
                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <p class="text--primary mb-0" style="font-size:.95rem !important;width:100%;font-weight:bold">
                                        Total (KES)
                                      </p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-action-text>
                                      <p class="text--primary mb-0" v-if="details.bookings.length > 0" style="font-size:.95rem !important;width:100%;font-weight:bold">
                                        {{

                                          (details.bookings.reduce( (acc, item) => 
                                          {  

                                            let amt = 0, waitingfee = 0, subamt = 0;
                                            if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                              if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                              }
                                            }

                                            if(item.booking.waiting.length > 0) {
                                              subamt = item.booking.waiting.reduce((acc1, value) => { 
                                                        let total = 0;
                                                        if(value.starttime !== null && value.endtime !== null) {
                                                          
                                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                          }

                                                        }

                                                        return  acc1 += total
                                                      }, 0)
                                            }

                                            amt = subamt + waitingfee;
                                            
                                            return acc += amt; 

                                          }, 0) + details.bookings.reduce( (acc, item) => 
                                          {  

                                            let amt = 0;

                                            if(item.booking.expressway.length > 0) {
                                              amt = Math.round(item.booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                                            }
                                            
                                            return acc += amt; 

                                          }, 0) + ((((details.bookings.reduce( (acc, item) => 
                                            {  
                                                let amt = 0;
                                                if(item.booking.organisation.vat !== '0') {
                                                  if(item.booking.organisationrate.length > 0) {
                                                    if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                                      amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                                    }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                                      amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                                    }else {
                                                      amt = parseInt(item.booking.organisationrate[0].base_rate)
                                                    }
                                                  }
                                                }
                                              return acc += amt; 
                                            }, 0) + details.bookings.reduce( (acc, item) => 
                                          {  

                                            let amt = 0, waitingfee = 0, subamt = 0;
                                            if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                              if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                                  waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                              }
                                            }

                                            if(item.booking.waiting.length > 0) {
                                              subamt = item.booking.waiting.reduce((acc1, value) => { 
                                                        let total = 0;
                                                        if(value.starttime !== null && value.endtime !== null) {
                                                          
                                                          if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                            total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                          }

                                                        }

                                                        return  acc1 += total
                                                      }, 0)
                                            }

                                            amt = subamt + waitingfee;
                                            
                                            return acc += amt; 

                                          }, 0))*(vat != null ? vat.rate : 0))/100)) + details.bookings.reduce( (acc, item) => 
                                            {  
                                                let amt = 0;
                                                if(item.booking.organisationrate.length > 0) {
                                                  if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                                    amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                                  }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                                    amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                                  }else {
                                                    amt = parseInt(item.booking.organisationrate[0].base_rate)
                                                  }
                                                }
                                              return acc += amt; 
                                            }, 0)) | currency('')

                                        }}
                                      </p>
                                      <p class="text--primary mb-0" v-else style="font-size:.95rem !important;width:100%;font-weight:bold">
                                        0.00
                                      </p>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>

                            </template>
                          </v-list-item-group>
                        </v-list>

                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-form>
      </v-container>
    </div>

</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }
    .invoice1 .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(4%, 7.691%) !important;*/
       grid-template-columns:13% 13% 13% 13% 13% 7% 7% 7% 7% 7%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<style scope>
 .invoice1 .col-12 {
    padding: 4px 12px !important;
  }
  .invoice1 .v-list--two-line .v-list-item, .invoice1 .v-list-item--two-line {
      min-height: 4px !important;
  }
  .v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
    z-index: 99 !important;
  }
  .v-bottom-navigation{
    align-items: center !important;
  }

  /*ribbon*/
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }

  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #F9A825;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 7px 0;
    background-color: #F9A825;
    box-shadow: 0 2px 1px rgba(0,0,0,.1);
    color: #fff;
    font: 600 14px/1 'Lato', sans-serif !important;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    right: 22px;
  }
  .ribbon-top-right::after {
    bottom: 22px;
    left: 0;
  }
  .ribbon-top-right span {
    right: -27px;
    top: 29px;
    transform: rotate(-45deg);
  }

  .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"showInvoice",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          page: 1,
          perPage: 10,
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:'',
          search:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Invoices',
              disabled: false,
              href: '/organisation/invoices',
            },
            {
              text: 'Invoice Details',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'VehicleType', value: 'vehicletype' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'Vat', value: 'vat' },
            { text: 'Total', value: 'total' },
           ],
          item:{
             id: null
          },
            isBusy: false,
            loading: false,
            loading1: false,
            loadingpdf: false,
            loadingexcel: false,
           disabled: false,
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      this.item.id = this.$route.params.id
      this.getDetails()
      if(!this.allinvoices || this.allinvoices.length == 0) {
        this.getInvoices()
      }

    },
    computed: {
      allinvoices() {
        return this.$store.state.showinvoices
      },
      details() {
        return this.$store.state.invoicedetail
      },
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      vat() {
        return this.$store.state.vat
      },
    },
    methods:{

      viewInvoice(id) {
        this.item.id = id
        this.getDetails()
      },
      async getInvoices(){
        // this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowInvoices')
      },
      async getDetails() {
        // this.$store.state.tableloader1 = true
        await axios.get(`/invoice/vat/${this.item.id}`).then(response=>{
          // this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.vat = response.data.vat
              this.$store.state.invoicedetail = response.data.invoice
            }
        }).catch(error=>{
          // this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/invoice/download', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisations[0].organisation.name}-${this.details.invoice_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },

      async downloadexcel() {
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/invoice/export-excel', fd, config).then(response=>{
          this.loadingexcel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.details.organisations[0].organisation.name}-${this.details.invoice_no}.xlsx`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },

      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      },

    }
}
</script>