<template>
    <!-- BEGIN: Content-->
    <div class="invoicePayment mt-5">
      <v-container fluid>
        <v-form class="pt-4"  enctype="multipart/form-data" v-model="valid" ref="form">
          <v-row>
            <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
              <v-breadcrumbs
                :items="breadcrumbs"
                divider="/"
              ></v-breadcrumbs>

            </v-col>
          </v-row>

          <v-row>
            <v-col  cols="12" md="10" lg="8" sm="12">
              <v-card class="card-shadow py-3 px-4 invoice">
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-autocomplete
                          :items="organisations"
                          label="Select Customer*"
                          v-model="item.organisation_id"
                          :value="item.organisation_id"
                          item-text="name"
                          item-value="id"
                          :rules="fieldRules"
                          :search-input.sync="search"
                          @change="selectOrg"
                          @click:clear="item.organisation_id = ''"
                        ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-autocomplete
                          :items="invoices"
                          label="Select Invoice"
                          v-model="item.invoice_no_id"
                          :value="item.invoice_no_id"
                          item-text="invoice_no"
                          item-value="id"
                          :search-input.sync="searchinvoice"
                        ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-text-field
                          label="Amount Received*"
                          v-model="item.amount"
                          :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="9"
                      >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Payment date*"
                            :rules="fieldRules"
                            :value="timepickerdate"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          @input="menu = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-autocomplete
                          :items="modes"
                          label="Payment Mode*"
                          v-model="item.payment_mode_id"
                          :value="item.payment_mode_id"
                          item-text="name"
                          item-value="id"
                          :rules="fieldRules"
                          :search-input.sync="paymentmodesearch"
                          @click:clear="item.payment_mode_id = ''"
                        ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-text-field
                          label="Reference No"
                          v-model="item.reference_no"
                        ></v-text-field>
                    </v-col>
                  </v-row>

              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-bottom-navigation
                fixed
                horizontal
              >
              
                <v-btn
                  color="#F98525"
                  class="mx-2"
                  style="height:35px !important;border-radius:3px;"
                  :loading="loading1"
                  :disabled="!valid"
                  @click="save"
                >
                  <span style="font-size:.82rem !important;color:#111 !important">Save</span>
                </v-btn>

                <v-btn
                    color="#dedede"
                    class="mx-2"
                    depressed
                    link
                    :to="{name:'paymentList'}"
                    style="height:35px !important;border-radius:3px;"
                  >
                  <span style="font-size:.82rem !important;color:#111 !important">Cancel</span>
                </v-btn>

              </v-bottom-navigation>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

</template>
<style scoped>
  .invoice .col-12 {
    padding: 5px 12px !important;
  }
  .invoice .v-list--two-line .v-list-item, .invoice .v-list-item--two-line {
      min-height: 4px !important;
  }
  .v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
    z-index: 99 !important;
  }
  .v-bottom-navigation{
    align-items: center !important;
  }
  #virtual-scroll-table {
    max-height: 70vh;
    overflow: auto !important;
  }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '../../../services/ability'

export default {
    name:"invoicePayment",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:'',
          search:null,
          searchinvoice:null,
          paymentmodesearch:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Invoices',
              disabled: false,
              href: '/organisation/invoices',
            },
            {
              text: 'Payment',
              disabled: false,
              href: '',
            },
          ],
          item:{
             organisation_id: "",
             invoice_no_id: "",
             amount: "",
             payment_mode_id: "",
             reference_no: ""
          },
            isBusy: false,
            loading: false,
            loading1: false,
           disabled: false,
           organisations: [],
           payment: [],
           invoices: [],
           modes: []
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.item.invoice_no_id = this.$route.params.id
      this.item.organisation_id = this.$route.params.organisation_id

      this.getData()
      this.selectOrg()

    },
    computed: {
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.duedate ? moment(this.duedate).format('Do MMM YYYY') : ''
      },
    },
    methods:{

      async save(){

        if (this.$refs.form.validate()) {

          this.loading1 = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('invoice_no_id',this.item.invoice_no_id)
          fd.append('payment_mode_id',this.item.payment_mode_id)
          fd.append('amount',this.item.amount)
          fd.append('reference_no',this.item.reference_no)
          fd.append('date',this.date)

          axios.post('/payments', fd, config).then(response=>{

            if(response.data.status == parseInt(200)) {

              this.$toast.success('Payment submitted successfully','Success',{position: 'topRight',timeout: 7000,})

              this.clear()
            }
              this.loading1 = false
              this.disabled = false
            })
            .catch(() => {
              this.loading1 = false
              this.disabled = false
            })

          }
      },

      async getData(){
        await axios.get('/new/payment').then(response=>{
            if(response.data.status == parseInt(200)) {
              this.organisations = response.data.organisations
              this.modes = response.data.modes
            }
        }).catch(error=>{
            console.log(error)
        })
      },

      async selectOrg() {
        await axios.get(`/payment/invoices/${this.$route.params.id}`).then(response=>{
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
            console.log(error)
        })
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        }
    }
}
</script>