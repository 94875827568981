<template>
    <!-- BEGIN: Content-->
    <div class="editDebitNote mt-5">
      <v-container fluid>
        <v-form class="pt-4"  enctype="multipart/form-data" v-model="valid" ref="form">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col cols="12" md="11" lg="10" sm="12" style="padding-top:0 !important">
              <v-card class="card-shadow px-0 pb-0">
                <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-3 pr-4">
                  <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                    <v-breadcrumbs
                      :items="breadcrumbs"
                      divider="/"
                    ></v-breadcrumbs>

                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col  cols="12" md="11" lg="10" sm="12" class="my-1 py-0">
              <v-card class="card-shadow py-3 px-4 invoice">
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-autocomplete
                          :items="organisations"
                          label="Select Organisation*"
                          v-model="item.organisation_id"
                          :value="item.organisation_id"
                          item-text="name"
                          item-value="id"
                          :rules="fieldRules"
                          :search-input.sync="search"
                          @change="selectOrg"
                          @click:clear="item.organisation_id = ''"
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-autocomplete
                          :items="invoices"
                          label="Select Invoice"
                          v-model="item.invoice_no_id"
                          :value="item.invoice_no_id"
                          item-text="invoice_no"
                          item-value="id"
                          :search-input.sync="searchinvoice"
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-text-field
                          label="Credit Amount*"
                          v-model="item.amount"
                          :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Payment date*"
                            :rules="fieldRules"
                            :value="timepickerdate"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          @input="menu = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          label="Notes"
                          v-model="item.notes"
                        ></v-text-field>
                    </v-col>
                  </v-row>

              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-if="invoices.length > 0">
            <v-col cols="12" md="11" lg="10" sm="12" style="padding-bottom: .4rem !important">
              <h2 style="font-weight: 400 !important;font-size: .9rem !important;">Organisation Invoices</h2>
            </v-col>
            <v-col cols="12" md="11" lg="10" sm="12" style="padding-top: 0 !important">
              <v-card class="card-shadow py-3">
                <v-data-table
                  dense
                  :headers="fields"
                  :items="invoices"
                  class="elevation-0"
                  loading-text="Loading... Please wait"

                  disable-pagination
                  hide-default-footer
                  id="virtual-scroll-table"
                  v-scroll:#virtual-scroll-table="onScroll"
                  height="height"
                  fixed-header
                  >
                  
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                          v-for="item in items"
                          :key="item.id"
                          @mouseover="selectItem(item)"
                          @mouseleave="unSelectItem()"
                          style="cursor:pointer !important"
                        >
                          <td>{{ moment(item.date).format('MMMM Do YYYY') }}</td>
                          <td>
                            <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                              :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink>
                          </td>
                          <td>{{ item.organisations[0].organisation.name }}</td>
                          <td>{{ item.status }}</td>
                          <td>{{ moment(item.due_date).format('MMMM Do YYYY') }}</td>
                          <td>
                            KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span>
                          </td>
                          <td>
                            <div v-if="item === selectedItem" style="position:absolute;margin-top:-1.5rem;">
                          
                              <v-btn
                                color="secondary"
                                fab
                                x-small
                                dark
                                class="mx-2"
                                style="width:25px !important;height:25px !important"
                                link
                                :to='{name:"editInvoice",params:{id:item.id}}'
                              >
                                <v-icon size="17">mdi-pencil</v-icon>
                              </v-btn>

                              <v-btn
                                color="secondary"
                                fab
                                x-small
                                dark
                                style="width:25px !important;height:25px !important"
                                @click="deleteInvoice(item.id)"
                              >
                                <v-icon size="17">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-bottom-navigation
                fixed
                horizontal
              >
              
                <v-btn
                  color="#F98525"
                  class="mx-2"
                  style="height:35px !important;border-radius:3px;"
                  :loading="loading1"
                  :disabled="!valid"
                  @click="save"
                >
                  <span style="font-size:.82rem !important;color:#111 !important">Save</span>
                </v-btn>

                <v-btn
                    color="#dedede"
                    class="mx-2"
                    depressed
                    link
                    :to="{name:'paymentList'}"
                    style="height:35px !important;border-radius:3px;"
                  >
                  <span style="font-size:.82rem !important;color:#111 !important">Cancel</span>
                </v-btn>

              </v-bottom-navigation>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

</template>
<style scoped>
  .invoice .col-12 {
    padding: 5px 12px !important;
  }
  .invoice .v-list--two-line .v-list-item, .invoice .v-list-item--two-line {
      min-height: 4px !important;
  }
  .v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
    z-index: 99 !important;
  }
  .v-bottom-navigation{
    align-items: center !important;
  }
  #virtual-scroll-table {
    max-height: 70vh;
    overflow: auto !important;
  }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '../../../services/ability'

export default {
    name:"editDebitNote",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:'',
          search:null,
          searchinvoice:null,
          paymentmodesearch:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Debit Notes',
              disabled: false,
              href: '/organisation/debit-notes',
            },
            {
              text: 'Edit Debit Note',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'INVOICE#', value: 'invoiceno' },
            { text: 'CUSTOMER NAME', value: 'customer' },
            { text: 'STATUS', value: 'status' },
            { text: 'DUE DATE', value: 'duedate' },
            { text: 'AMOUNT', value: 'amount' },
            { text: '', value: 'balance' },
           ],
          item:{
             organisation_id: "",
             invoice_no_id: "",
             amount: "",
             notes: "",
              _method:"patch"
          },
            isBusy: false,
            loading: false,
            loading1: false,
           disabled: false,
           organisations: [],
           debit_notes: [],
           invoices: [],
           modes: [],
           height: '100%',
            rowHeight: 24,
            perPage: 32,
            start: 0,
            timeout: null,
            selectedItem: false
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.item.id = this.$route.params.id

      this.getData()

    },
    computed: {
      details() {
        let data = this.$store.state.payments.filter(row => {
          return row.id == this.item.id
        })

        if(data.length > 0) {

          this.item.organisation_id = data[0].organisation_id
          this.item.invoice_no = data[0].invoice_no
          this.item.subject = data[0].subject
          this.date = data[0].date
          this.duedate = data[0].due_date

        }

        return data
      },
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.duedate ? moment(this.duedate).format('Do MMM YYYY') : ''
      },
    },
    methods:{

      selectItem (item) {
        this.selectedItem = item
      },
      unSelectItem (item) {
        this.selectedItem = false
      },

      onScroll(e) {
        this.timeout && clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            const { scrollTop } = e.target;
            const rows = Math.ceil(scrollTop / this.rowHeight);

            this.start = rows + this.perPage > this.invoices.length ?
              this.invoices.length - this.perPage: rows;

            this.$nextTick(() => {
              e.target.scrollTop = scrollTop;
            });
          }, 20);
   
      },

      async save(){

        if (this.$refs.form.validate()) {

          this.loading1 = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('invoice_no_id',this.item.invoice_no_id)
          fd.append('amount',this.item.amount)
          fd.append('notes',this.item.notes)
          fd.append('date',this.date)
          fd.append('_method',this.item._method)

          axios.post(`/debit-notes/${this.$route.params.id}`, fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Debit note updated successfully','Success',{position: 'topRight',timeout: 7000,})

              this.$router.push({ name: 'debitnotesList' })
            }
              this.loading1 = false
              this.disabled = false
            })
            .catch(() => {
              this.loading1 = false
              this.disabled = false
            })

          }
      },

      async getData(){
        await axios.get(`/debit-note/edit/${this.item.id}`).then(response=>{
            if(response.data.status == parseInt(200)) {
              this.organisations = response.data.organisations
              this.debit_notes = response.data.credits
              this.invoices = response.data.invoices

              this.item.organisation_id = parseInt(this.credit_notes.organisation.id)
              if(this.debit_notes.invoice !== null)
                this.item.invoice_no_id = parseInt(this.debit_notes.invoice.id)
              this.date = this.debit_notes.date
              this.item.notes = this.debit_notes.notes
              this.item.amount = this.debit_notes.amount
            }
        }).catch(error=>{
            console.log(error)
        })
      },

      async selectOrg(id) {
        await axios.get(`/debit-note/invoices/${id}`).then(response=>{
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
            console.log(error)
        })
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        }
    }
}
</script>