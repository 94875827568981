<template>
    <!-- BEGIN: Content-->
    <div class="organisationRating mt-5">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col cols="12"
                md="9"
                xl="8" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="mb-0 py-3 pr-4">
                <v-col md="12" sm="12" class="d-flex py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-1 py-0">
            <v-row>
              <v-col
                cols="12"
                md="9"
                xl="8"
              >

                <v-card class="card-shadow py-0" flat>
                  <v-card-text>
                    <v-list two-line>
                      <v-list-item-group
                      >
                        <template v-for="(item, index) in visiblePages">
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-avatar>
                                <v-img src="/assets/images/no_user.png"></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.customer.name"></v-list-item-title>

                                <v-list-item-subtitle v-if="item.comment == 'null' || item.comment == null">N/A</v-list-item-subtitle>
                                <v-list-item-subtitle v-text="item.comment" v-else></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text>{{ moment(item.created_at).format('MMMM Do YYYY, H:mm:ss A') }}</v-list-item-action-text>

                                <star-rating v-bind:increment="0.5"
                                           v-bind:max-rating="5"
                                           v-model:rating="item.itemstar"
                                           inactive-color="#c8c8c8"
                                           active-color="#F9780D"
                                           v-bind:star-size="15">
                              </star-rating>

                              </v-list-item-action>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < visiblePages.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-pagination
                            v-model="page"
                            :length="Math.ceil(ratings.length/perPage)"
                          ></v-pagination>
                  </v-card-text>
                </v-card>

              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-container>

    </div>

</template>
<style scope>
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }
    .v-list-item__subtitle {
        font-size: .65rem !important;
    }
    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }
    #virtual-scroll-table {
      max-height: 70vh;
      overflow: auto;
    }
    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    .theme--light.v-tabs>.v-tabs-bar{
      background: transparent !important;
    }
    .v-window.v-item-group{
      margin-top:3rem !important;
    }
    .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }
</style>
<script>
import axios from 'axios'
import StarRating from 'vue-star-rating'
import {ability} from '../../services/ability'

export default {
    name:"organisationRating",
    components: {
      StarRating
    },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          details: null,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Organisation Ratings',
              disabled: false,
              href: '',
            },
          ],
          page: 1,
          perPage: 10,
           rating:0,
           ratings:[],
           menu: false,
           date: "", 
           previewImage: null,
           documents: null,
           valid: true,
          item:{},
            latitude: null,
            longitude: null,
            isBusy: false,
            loading: false,
           disabled: false    
          }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      this.showRatings()
    },
    computed: {
      visiblePages () {
        return this.ratings.map(item => { let container = item; container.itemstar = parseFloat(item.stars); return container; }).slice((this.page - 1)* this.perPage, this.page* this.perPage)
      }
    },
    methods:{

      async showRatings() {
        await axios.get('/ratings').then(response=>{
            this.ratings = response.data.ratings
        }).catch(error=>{
            console.log(error)
            this.ratings = null        
        })
      }
    }
}
</script>