<template>
  <v-app id="app">
      <v-app-bar app elevation="0" class="appbar" color="primary" v-if="isLogged">

          <router-link link :to="{name: 'Dashboard'}" style="text-decoration:none !important;color:inherit !important;margin:0 !important;padding:0 !important;line-height:0 !important">
            <img src="@/assets/absolutelogotext.jpg" style="width:108% !important;text-align:center;">
          </router-link>
          <v-spacer></v-spacer>
            <v-badge
              color="red"
              :content="`${allrequests.length}`"
              class="notificationbell mr-10"
            >
            <v-btn class="notificationsrequest" icon @click="filterRequest('Waiting')">
              <v-icon color="#333" class="mt-3">mdi-account-clock-outline</v-icon>
            </v-btn>
          </v-badge>

          <v-list-item two-line>
            <v-list-item-avatar>
              <img src="@/assets/no_user.png">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="white--text">{{ $store.state.user.user.name }}</v-list-item-title>
              <v-list-item-subtitle><small v-if="$store.state.user.user.organisation !== null" class="white--text">{{ $store.state.user.user.organisation.name }}</small> </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                
                <v-list-item-group
                  color="primary"
                  class="actions"
                  >

                    <v-list-item
                      @click="logout()"
                    >
                        <v-list-item-icon>
                          <v-icon color="#000000">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>Logout</span>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
              </v-menu>

            </v-list-item-action>

          </v-list-item>
      </v-app-bar>
      <!-- v-model="toggleMini" :mini-variant.sync="mini"  -->
        <v-navigation-drawer 
            v-if="isLogged"
            app
            elevation="2"
            stateless
            light
            class="menudrawer"
            mini-variant
            permanent
            expand-on-hover
            >
             <!-- @click="mini = !mini" -->
            <v-list-item class="pr-6 py-2 atitle" v-if="isLogged">
                <v-list-item-avatar>
                    <v-icon>mdi-account-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="text-truncate font-weight-bold">
                    {{ $store.state.user.user.name }}
                </v-list-item-content>
                <v-btn icon small>
                    <v-icon @click.stop="mini = !mini">mdi-chevron-left</v-icon>
                </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list class="sidenav" v-if="isLogged">
              <!-- first list group -->
              <v-list-group
                v-for="item in business"
                :key="item.title"
                no-action
                >
                <template v-slot:activator>
                  <v-list-item-icon class="list1">
                      <v-icon :color="item.color">{{ item.icon }}</v-icon><span>{{ item.title }}</span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="subItem in item.items"
                  :key="subItem.title"
                  link 
                  :to="subItem.href"
                >
                  <!-- <v-list-item-content> -->
                      <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                      <v-list-item-icon>
                        <v-avatar
                          color="rgba(0,0,0,.05)"
                          size="25"
                        >
                          <span class="black--text" v-text="subItem.icon"></span>
                        </v-avatar>
                      </v-list-item-icon>
                  <!-- </v-list-item-content> -->
                  
                </v-list-item>
                <v-divider></v-divider>
              </v-list-group>
              <!-- end first group -->

              <!-- group 2 -->
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-icon class="list1">
                      <v-icon color="#333333">mdi-office-building-cog</v-icon><span>Bs Config</span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Business Config</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-group
                  v-for="item in businessconfig"
                  :key="item.title"
                  no-action
                  sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="subItem in item.items"
                    :key="subItem.title"
                    link 
                    :to="subItem.href"
                  >
                    <!-- <v-list-item-content> -->
                      <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                      <v-list-item-icon>
                        <v-avatar
                          color="rgba(0,0,0,.05)"
                          size="25"
                        >
                          <span class="black--text" v-text="subItem.icon"></span>
                        </v-avatar>
                      </v-list-item-icon>
                    <!-- </v-list-item-content> -->
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list-group>
              </v-list-group>
              <!-- end group 2 -->

              <!-- third list group -->
              <v-list-group
                v-for="item in reports"
                :key="item.title"
                no-action
                >
                <template v-slot:activator>
                  <v-list-item-icon class="list1">
                      <v-icon :color="item.color">{{ item.icon }}</v-icon><span>{{ item.title }}</span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="subItem in item.items"
                  :key="subItem.title"
                  link 
                  :to="subItem.href"
                >
                  <!-- <v-list-item-content> -->
                    <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                    <v-list-item-icon>
                        <v-avatar
                          color="rgba(0,0,0,.05)"
                          size="25"
                        >
                          <span class="black--text" v-text="subItem.icon"></span>
                        </v-avatar>
                      </v-list-item-icon>
                  <!-- </v-list-item-content> -->
                </v-list-item>
                <v-divider></v-divider>
              </v-list-group>
              <!-- end third group -->


              <v-list-item link to="/settings">
                <v-list-item-icon class="list1">
                    <v-icon color="#333333">mdi-cog-outline</v-icon><span>Settings</span>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


            </v-list>
        </v-navigation-drawer>
      <v-main :class= "[isLogged ? '' : 'authpadding']">
          <v-container fluid class="pt-5 appcontent">
              <v-row>
                  <v-col cols="12" style="margin-top:.3rem !important;padding: 12px 3px;">
                      <router-view></router-view>
                  </v-col>
              </v-row>
          </v-container>
      </v-main>
      <!-- <v-footer style="padding:7px 5px !important">
          <span class="ml-auto">Abslute Cabs &copy;2021</span>
      </v-footer> -->
  </v-app>
</template>
<style>
  .notificationbell .v-badge__badge{
      inset: auto auto calc(100% - 24px) calc(100% - 24px) !important;
}
</style>
<style scoped>
.v-navigation-drawer--fixed {
    z-index: 99 !important;
}
.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group>.v-list-group__items>.v-list-item {
    padding-left: 64px !important;
}
.authpadding{
      padding: 0 !important;
}
  .v-list.brand{
    padding: 0 !important;
  }
  .v-list, .v-list-item, .v-list-item__title{
    font-size: 12px !important;
  }
 
  .v-list-item{
    font-weight: normal !important;
  }
</style>
<script>
import { mapGetters } from 'vuex'
import { db } from '@/db'

 export default {
  name: 'App',
  components: {},

  computed: {
    ...mapGetters([
      'isLogged'
    ]),
    allrequests () {

      return this.$store.state.allbookings
        .filter(value => value.vehicle_id === null || value.vehicle_id === 0 || value.vehicle_id === 'null')
        .filter(item => {

          let filterdate = format(new Date(), 'yyyy-MM-dd')

          return item.date.includes(filterdate)
        })
    },
    alldashboard () {
      return this.$store.state.bookings
    },
    newdata() {
      return this.$store.state.newdata
    }
  },
  data: () => ({
      sidebarMenu: true,
      toggleMini: true,
      mini: false,
      active: false,
      business: [
        {
          title: 'Business',
          icon: 'mdi-domain',
          color: '#333333',
          items: [
            { title:"All Bookings", href:"/booking/list",icon:"B"},
            { title:"Today Bookings", href:"/today-booking/list",icon:"B"},
            { title:"Add Booking", href:"/new/booking",icon:"AB"},
            { title:"Return Booking", href:"/new/return/booking",icon:"RB"},
            { title:"Organisation Rating", href:"/organisation/ratings",icon:"OR"}
          ]
        }
      ],
      businessconfig: [
        {
          title: 'Organisations',
          color: '#333333',
          items: [
            { title:"List", href:"/organisation/list",icon:"OL"},
            { title:"Projects", href:"/organisation/projects/list",icon:"OP"},
            { title:"Categories", href:"/organisation/category/list",icon:"OC"},
            { title:"Types", href:"/organisation/type/list",icon:"OT"},
            { title:"Rates", href:"/organisation/rates",icon:"R"}
          ]
        },
        {
          title: 'Vehicles',
          icon: 'mdi-taxi', 
          color: '#333333',
          items: [
            { title:"List", href:"/vehicle/list",icon:"VL"},
            { title:"Makes", href:"/vehicle/make/list",icon:"VM"},
            { title:"Types", href:"/vehicle/type/list",icon:"VT"},
            { title:"Models", href:"/vehicle/model/list",icon:"VM"},
            { title:"Ownerships", href:"/vehicle/ownership/list",icon:"VO"}
          ]
        },
        {
          title: 'Drivers',
          icon: 'mdi-badge-account-outline', 
          color: '#333333',
          items: [{title: 'List', href:"/drivers/list", icon:"DL"}]
        }
      ],
      reports: [
        {
          title: 'Reports',
          icon: 'mdi-chart-bar',
          color: '#333333',
          items: [
            { title:"Full Report", href:"/booking/reports",icon:"FR"},
            { title:"Invoice Report", href:"/booking/invoice",icon:"INV"},
            { title:"Vouchers", href:"/booking/vouchers",icon:"V"},
          ]
        },
        {
          title: 'Finance',
          icon: 'mdi-finance', 
          color: '#333333',
          items: [
            { title:"Invoices", href:"/organisation/invoices",icon:"INV"},
            { title:"Invoice Details", href:"/organisation/invoice-details",icon:"ID"},
            { title:"Received Payments", href:"/organisation/payments",icon:"RP"},
            { title:"Payment Reversals", href:"/organisation/payment-reversals",icon:"RP"},
            { title:"Credit Notes", href:"/organisation/credit-notes",icon:"CN"},
            { title:"Debit Notes", href:"/organisation/debit-notes",icon:"DN"},
            { title:"Customer Statement", href:"/organisation/statements",icon:"CS"},
          ]
        },
        {
          title: 'User Config',
          icon: 'mdi-account-cog-outline', 
          color: '#333333',
          items: [
            { title:"User Accounts", href:"/users/list",icon:"UA"},
            { title:"Roles", href:"/role/list",icon:"R"},
            { title:"Permissions", href:"/permission/list",icon:"P"},
            { title:"Customers", href:"/customers/list",icon:"C"}
          ]
        },
        {
          title: 'Expressway',
          icon: 'mdi-highway', 
          color: '#333333',
          items: [
            { title:"Entrances", href:"/expressway/entrances",icon:"E"},
            { title:"Exits", href:"/expressway/exits",icon:"E"},
            { title:"Rates", href:"/expressway/rates",icon:"R"},
          ]
        }
      ]
  }),
  mounted() {
    // if(this.isLogged) {
    //   db.collection('bookings')
    //   .onSnapshot(snapshot => {

    //     snapshot.docChanges().forEach(change => {

    //       if(change.doc.data().status == "fromserver") {
         
    //         if(change.type == "added") {
    //           // console.log("New data: ", change.doc.data());
    //            const source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
    //           if(source == "Server") {
    //             this.$store.dispatch('bookingCreated', change.doc.data())
    //                 .then(() => {

    //                   var audio = new Audio('/assets/tone.mp3')
    //                   audio.play()

    //                   this.$toast.show('<b>Booking Request</b><br><b>You have new ride request','',{
    //                       position: 'topRight',
    //                       image: 'https://cdn-icons-png.flaticon.com/512/619/619006.png',
    //                       imageWidth: 50,
    //                       balloon: false,
    //                       messageColor:'#000000',
    //                       messageLineHeight: '20',
    //                       timeout: 7000,
    //                     })

    //                 }).catch(() => {
    //                 })
    //           }
    //         }
    //         if(change.type == "modified") {
    //           this.$store.dispatch('bookingEdit', change.doc.data())
    //         }
    //         if(change.type == "removed") {
    //           console.log("Removed data: ", change.doc.data());
    //         }
    //       }

    //       const update = change.doc.data()
    //       update.status = "local"
    //       db.collection('bookings').doc(`${change.doc.data().id}-request`).set(update)

    //     });
        
    //   });
    // }
  },
  methods: {
    filterRequest(res) {
        this.$store.commit('searchFilter', res)
      },
    // getDashboard(){
    //   this.$store
    //     .dispatch('getDashboard')
    //     .then(() => {
    //     })
    //     .catch(() => {
    //     })
    // },
    logout () {
      this.$store.dispatch('logout')
    }
  }
};
</script>
