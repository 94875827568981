<template>
	<div>
		<!-- assign message client -->
      <v-dialog
        max-width="450px"
        v-model="show"
        >
          <v-card v-if="profiledetails.length > 0">
	        <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
	          <v-card-title class="d-flex justify-space-between">
	            <span class="text-h6">Send Message</span>
	            <div class="d-flex flex-column">
		            <v-btn
				      rounded
				      color="green lighten-1 mb-2"
				      dark
				      depressed
				      :loading="resendloading"
		                @click="resendmessage"
				    >
				      Resend booking message
				    </v-btn>

				    <v-btn
				      rounded
				      color="light-green lighten-1"
				      dark
				      depressed
				      :loading="driverloading"
		                @click="drivermessage"
				    >
				      Resend driver details
				    </v-btn>
				</div>
	          </v-card-title>

	          <v-card-text>
	              <v-container>
	                <v-row>
	                  <v-col cols="12" sm="12" md="12">
	                    <v-text-field
	                    outlined
	                      label="Recipient"
	                      readonly
	                      :value="profiledetails[0].name"
	                      hide-details
	                    ></v-text-field>
	                  </v-col>
	                  <v-col cols="12" sm="12" md="12">
	                    <v-text-field
	                    outlined
	                      label="Telephone"
	                      readonly
	                      :value="profiledetails[0].telephone"
	                      hide-details
	                    ></v-text-field>
	                  </v-col>
	                  <v-col cols="12" sm="12" md="12">
	                    <v-textarea
	                      outlined
	                      name="input-7-4"
	                      label="Message body"
	                      v-model="messagetext"
	                      :rules="fieldRules"
	                    ></v-textarea>
	                  </v-col>
	                </v-row>
	              </v-container>
	          </v-card-text>

	          <v-card-actions>
	            <v-spacer></v-spacer>
	              <v-btn
	                color="default darken-1"
	                text
	                @click.stop="show = false"
	              >
	                Close
	              </v-btn>
	              <v-btn
	                color="primary darken-1"
	                text
	                :loading="loading"
	                :disabled="!valid"
	                @click="sendmessage"
	              >
	                Save
	              </v-btn>
	          </v-card-actions>

	        </v-form>
        </v-card>
      </v-dialog>
      <!-- end message client -->
	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'sendmessage',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        resendloading: false,
        driverloading: false,
        messagetext: "",

	}),
	methods: {
		sendmessage(){

	        if (this.$refs.form.validate()) {

	          this.loading = true
	          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.messageuserid)
            fd.append('messagetext',this.messagetext)

            axios.post('/send-client-message', fd, config).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toast.success('Message sent successfully','Success',{position: 'topRight',timeout: 7000,})
                }
                this.show = false
                this.loading = false
                this.disabled = false
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

          }

        },
        resendmessage(){

	        this.resendloading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.messageuserid)
            fd.append('messagetext',this.messagetext)

            axios.post('/resend-client-message', fd, config).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toast.success('Message sent successfully','Success',{position: 'topRight',timeout: 7000,})
                }
                this.show = false
                this.resendloading = false
              })
              .catch(() => {
                this.resendloading = false
              })

        },
        drivermessage(){

	        this.driverloading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.messageuserid)
            fd.append('messagetext',this.messagetext)

            axios.post('/resend-driver-message', fd, config).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toast.success('Message sent successfully','Success',{position: 'topRight',timeout: 7000,})
                }
                if(response.data.status === parseInt(404)) {
                  this.$toast.warning('No driver has been dispatched','Warning',{position: 'topRight',timeout: 7000,})
                }
                this.show = false
                this.driverloading = false
              })
              .catch(() => {
                this.driverloading = false
              })

        },
	},
	props: {
	     value: Boolean,
	     messageuserid: Number,
	     profiledetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {

	},
	created() {
     
    }
}
</script>