import Vue from 'vue'
import Vuetify from 'vuetify'
// import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

const opts = {
	theme: {
	    themes: {
	      light: {
	        primary: '#F9780D',
	        secondary: '#2B303B',
	        accent: '#2B303B',
	        error: '#b71c1c',
	      },
	    },
	  },
}

export default new Vuetify(opts)
