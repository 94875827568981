<template>
    <!-- BEGIN: Content-->
    <div class="invoiceList mt-3">
      <v-container fluid>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>

                  <div style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between;align-items:center">
                    <v-btn
                        color="#000000"
                        class="ma-1 white--text"
                        depressed
                        link
                        :to="{name:'newInvoice'}"
                      >
                        Invoice
                        <v-icon
                          right
                          dark
                        >
                          mdi-plus
                        </v-icon>
                      </v-btn>

                    </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-1 py-0">
            <v-card class="card-shadow py-0">
              <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#008000"
                      buffer-value="0"
                      stream
                      height="3"
                      :active="tableloader"
                    ></v-progress-linear>
                  </div>
              <v-card-title style="display:flex;align-items:center;justify-content:flex-end">
                <v-col md="2" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                  <v-text-field
                        v-model="searchfilter"
                        label="Search invoice"
                        solo
                        hide-details="auto"
                      ></v-text-field>
                </v-col>
              </v-card-title>
              <v-card-text class="px-0 pb-0">
                <div class="classloader">
                   <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="tableloader"/>
                </div>
                <div class="empty-state" v-if="allinvoices.length === 0 && !tableloader">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <v-img src="@/assets/icon.png" alt=""></v-img>
                    </div>
                    <div class="empty-state__message">No records available.</div>
                  </div>
                </div>
                <v-data-table
                  dense
                  :headers="fields"
                  :items="allinvoices"
                  :item-class="getRowClass"
                  class="elevation-0"
                  loading-text="Loading... Please wait"

                  disable-pagination
                  hide-default-footer
                  item-key="id"
                  id="virtual-scroll-table"
                  v-scroll:#virtual-scroll-table="onScroll"
                  fixed-header
                >

                <template
                    v-if="start > 0"
                    v-slot:body.prepend
                  >
                    <tr>
                      <td
                        :colspan="fields.length"
                        :style="'padding-top:'+startHeight+'px !important'"
                      >
                      </td>
                    </tr>
                  </template>
                  <template
                    v-if="start + perPage < this.invoices.length"
                    v-slot:body.append
                  >
                    <tr>
                      <td
                        :colspan="fields.length"
                        :style="'padding-top:'+endHeight+'px !important'"
                      >
                      </td>
                    </tr>
                  </template>

                <template v-slot:body="{ items }">
                  <tbody class="finance">
                    <tr
                        v-for="item in items"
                        :key="item.id"
                        :search="search"
                        @mouseover="selectItem(item)"
                        @mouseleave="unSelectItem()"
                        style="cursor:pointer !important"
                        class="row-color"
                      >
                        <td>{{ moment(item.date).format('MMMM Do YYYY') }}</td>
                        <td>
                          <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                            :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink>
                        </td>
                        <td>
                          <span v-if="item.organisations.length > 0"><v-chip v-for="(organisation,index) in item.organisations" :key="index">{{ organisation.organisation.name }}</v-chip></span>
                          <span v-else>Private</span>
                        </td>
                        <td>{{ item.status }}</td>
                        <td>{{ moment(item.due_date).format('MMMM Do YYYY') }}</td>
                        <td>
                          KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span>
                        </td>
                        <td>
                          <div>

                            <v-btn
                              color="dark"
                              fab
                              x-small
                              dark
                              class="mx-2"
                              style="width:25px !important;height:25px !important"
                              @click="reverseInvoice(item.id,item.invoice_no)"
                            >
                              <v-icon size="17">mdi-file-undo-outline</v-icon>
                            </v-btn>
                        
                            <v-btn
                              color="secondary"
                              fab
                              x-small
                              dark
                              class="mx-2"
                              style="width:25px !important;height:25px !important"
                              link
                              :to='{name:"editInvoice",params:{id:item.id}}'
                            >
                              <v-icon size="17">mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn
                              color="secondary"
                              fab
                              x-small
                              dark
                              style="width:25px !important;height:25px !important"
                              @click="deleteInvoice(item.id)"
                            >
                              <v-icon size="17">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </td>
                    </tr>
                  </tbody>
                </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- reverse invoices -->
      <v-dialog
        v-model="reverseinvoicesdialog"
        width="70%"
        >
        <v-card>

          <v-card-title class="mb-3">
            <span style="font-weight:normal !important;font-size:.97rem !important">Reverse Invoice <span style="color:#d95e00;font-size:.97rem !important">{{ this.item.invoice_no }} </span></span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    
                    <v-data-table
                      v-model="reversebookings"
                      dense
                      :headers="reversefields"
                      :items="bookings"
                      class="elevation-0"
                      loading="false"
                      :search="searchfilter"
                      loading-text="Loading... Please wait"
                      show-select
                      disable-pagination
                      hide-default-footer
                      id="virtual-scroll-table1"
                      v-scroll:#virtual-scroll-table="onScroll"
                      fixed-header
                      >
                      <template v-slot:item.#="{ index }">
                        {{ ++index }}
                      </template>
                      <template v-slot:item.date="{ item }">
                        {{ moment(item.booking.pickup_date).format('Y-m-d') }}
                      </template>
                      <template v-slot:item.time="{ item }">
                        {{ item.booking.pickup_time }}
                      </template>
                      <template v-slot:item.ref="{ item }">
                        {{ item.booking.ref_no }}
                      </template>
                      <template v-slot:item.from="{ item }">
                        {{ item.booking.travelfrom }}
                      </template>
                      <template v-slot:item.to="{ item }">
                        {{ item.booking.travelto }}
                      </template>
                      <template v-slot:item.passenger="{ item }">
                        <div v-if="item.booking.passengers.length === 0">{{ item.passenger_name }}</div>
                        <div v-else style="display:flex;flex-direction:column !important">
                          <span v-for="(passenger,index) in item.booking.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.passenger_name }}</span>
                        </div>
                      </template>
                      <template v-slot:item.amount="{ item }">
                        <div v-if="item.booking.organisationrate.length > 0">
                            <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">KES {{ ((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">KES {{ ((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else>KES {{ item.booking.organisationrate[0].base_rate | currency('') }}</span>
                        </div>
                        <div v-else>0.00</div>
                        <!-- KES {{ parseFloat(item.booking.distance) * parseFloat(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate) | currency('') }} -->
                      </template>
                    </v-data-table>

                  </v-col>
                  
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="reverseinvoicesdialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                @click="reverseForm"
              >
                Reverse
              </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <!-- end reverse invoices -->

    </div>

</template>
<style scoped>
  .v-data-table /deep/ .sticky-header {
    position: sticky !important;
    top: var(--toolbarHeight) !important;
  }

  .v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset !important;
  }

 #virtual-scroll-table {
    height: 80vh;
      max-height: 100%;
    overflow: auto;
  }

  #virtual-scroll-table1 {
    max-height: 50vh;
    overflow: auto;
  }

  .v-data-table__wrapper td, .v-data-table-header th{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th,.v-data-table-header > th{
      max-width: 170px !important;
      font-weight: 500;
      border:1px solid var(--border-color) !important;
      height:10px !important;
  }
  ::v-deep .v-data-table__empty-wrapper {
      display: none !important;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import Spinner from 'vue-spinkit'
export default {
    name:"invoiceList",
    components: { Spinner},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          search:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          tableloader: false,
          dialog: false,
          reverseinvoicesdialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Invoices',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'INVOICE#', value: 'invoiceno' },
            { text: 'ORGANISATION NAME', value: 'organisation' },
            { text: 'STATUS', value: 'status' },
            { text: 'DUE DATE', value: 'duedate' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'BALANCE DUE', value: 'balance' },
           ],
           reversefields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'Time', value: 'time' },
            { text: 'Ref', value: 'ref' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Passenger', value: 'passenger' },
            { text: 'Amount', value: 'amount' },

           ],
          item:{
            invoice_id: "",
            invoice_no: ""
          },
            isBusy: false,
            loading: false,
           disabled: false,
           bookings: [],
           reversebookings: [],
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            selectedItem: false
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      // if(!this.allinvoices || this.allinvoices.length == 0) {
        
      // }
      this.getInvoices()

    },
    serverPrefetch () {
      return this.getInvoices()
    },
    computed: {
      invoices() {
        return this.$store.state.invoices.filter(item => {

          this.start = 0

          let invoice_no = "";
          let organisation = "";
          let amount = "";
          let date = "";
          // if(item.organisation !== null) {
          //   organisation = item.organisation.name
          // }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
          if(item.amount !== null) {
            amount = item.amount
          }
          if(item.date !== null) {
            date = item.date
          }
          return invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || amount.includes(this.searchfilter) || date.includes(this.searchfilter)
        })
      },
      allinvoices() {
        return this.invoices.slice(this.start, this.perPage+this.start)
      },
      organisations() {
        return this.$store.state.organisations
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.invoices.length - this.start);
      }
    },
    methods:{
      getRowClass(item) {
        return 'row-color' 
      },
      selectItem (item) {
        this.selectedItem = item
      },
      unSelectItem (item) {
        this.selectedItem = false
      },
      onScroll(e) {
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.invoices.length ?
            this.invoices.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
 
    },
      async getInvoices(){
        this.tableloader = true
        this.$store
          .dispatch('getInvoices')
          .then(() => {
            this.tableloader = false
          })
          .catch(() => {
            this.tableloader = false
          })
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        },
      deleteInvoice(id){
            if(confirm("Are you sure to delete invoice ?")){
                axios.delete(`/invoices/${id}`).then(response=>{
                  this.$toast.success('Invoice deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
        reverseInvoice(id,invoice_no){

          this.item.invoice_id = id
          this.item.invoice_no = invoice_no

          this.reverseinvoicesdialog = true

            axios.get(`/reverse-invoices/${id}`).then(response=>{

              this.bookings = response.data.bookings

            }).catch(error=>{
                console.log(error)
            })
        },
        reverseForm(){

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('invoice_id',this.item.invoice_id)
          fd.append('bookings',JSON.stringify(this.reversebookings))

          axios.post('/submit-reverse-invoices', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Invoices reversed successfully','Success',{position: 'topRight',timeout: 7000,})

              this.reverseinvoicesdialog = false

              this.getInvoices()

            }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }
    }
}
</script>