<template>
    <!-- BEGIN: Content-->
    <div class="Reports mt-3">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="mb-0 py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>
                  <div>
                    <v-btn
                      color="#000000"
                      class="ma-1 white--text"
                      depressed
                      :loading="loadingpdf"
                      @click="exportpdf"
                    >
                      Export Pdf
                      <v-icon
                        right
                        dark
                      >
                        mdi-file-pdf-box
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-0 py-0">
            <v-card class="card-shadow py-0">
              <v-card-title style="display:block;">
                <v-row class="pa-1">
                  <v-col cols="12" ms="12">
                    <v-form class="pt-2"  enctype="multipart/form-data" v-model="valid" ref="form">
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                          xl="2"
                          class="py-0"
                        >
                        <date-range-picker
                          ref="picker"
                          :showWeekNumbers="showWeekNumbers"
                          :showDropdowns="showDropdowns"
                          :autoApply="autoApply"
                          opens="right"
                          :always-show-calendars="alwaysshowcalendars"
                          v-model="dateRange"
                          @select="updateValues"
                         >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                <v-text-field
                                  label="Select date range"
                                  :rules="fieldRules"
                                  v-model="date"
                                  solo
                                ></v-text-field>
                            </template>
                        </date-range-picker>
                      </v-col>

                      <v-col
                        cols="12"
                        xl="2"
                        md="3"
                        class="py-0"
                      >
                        <v-autocomplete
                            :items="organisations"
                            label="Select organisation"
                            v-model="item.organisation_id"
                            clearable
                            @click:clear="item.organisation_id = ''"
                            item-text="name"
                            item-value="id"
                            :search-input.sync="searchorganisation"
                            solo
                          ></v-autocomplete>
                      </v-col>

                        <v-col
                          cols="12"
                          md="3"
                          xl="2"
                          class="py-0"
                        >
                          <v-autocomplete
                              :items="projects"
                              label="Select project"
                              v-model="item.project_id"
                              clearable
                              @click:clear="item.project_id = ''"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="searchproject"
                              solo
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          xl="2"
                          md="2"
                          class="py-0"
                        >
                          <v-autocomplete
                            :items="['Client', 'Delivery']"
                            label="Select request category"
                            v-model="item.request_category"
                            :rules="fieldRules"
                            :search-input.sync="searchrequestcategory"
                            solo
                            clearable
                            @click:clear="item.request_category = ''"
                          ></v-autocomplete>
                        </v-col>

                         <v-col
                          cols="12"
                          md="2"
                          xl="2"
                          class="py-0"
                        >
                          <v-btn
                              depressed
                              color="primary darken-1"
                              :loading="loading"
                              :disabled="!valid"
                              @click="fetchreport"
                            >
                              Search
                            </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row style="margin-bottom:0 !important" class="px-3 mt-2">
                  <v-col md="12" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:flex-end">

                    <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                      >
                      <v-text-field
                        v-model="search"
                        label="Search"
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="px-0 pb-0 cardtext">


                <v-virtual-scroll
                    :items="allbookingreports"
                    :item-height="23"
                    renderless 
                    id="virtualscroll"
                    class="voucher"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-if="item.passenger_name !== null" v-bind="attrs"
                                v-on="on">
                                {{ item.passenger_name }}
                              </span>
                            </template>
                            <span>
                              <span v-if="item.passenger_name !== null">
                                {{ item.passenger_name }}
                              </span>
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <div v-if="item.passengers.length === 0">{{ item.telephone }}</div>
                          <div v-else style="display:flex;flex-direction:column !important">
                            <span v-for="(passenger,index) in item.passengers" style="display:flex;"><v-icon class="mr-1">mdi-circle-small</v-icon> {{ passenger.telephone }}</span>
                          </div>
                        </div>
                        <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs"
                                v-on="on">
                                {{ item.travelfrom }}
                              </span>
                            </template>
                            <span>
                              {{ item.travelfrom }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs"
                                v-on="on">
                                {{ item.travelto }}
                              </span>
                            </template>
                            <span>
                              {{ item.travelto }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="tcell">
                          <div style="display:flex;flex-direction:column !important">
                            <span v-if="item.travelstop1 !== null" style="display:flex;">
                              <v-icon class="mr-2">mdi-circle-small</v-icon> {{ item.travelstop1 }}
                            </span>
                            <span v-if="item.travelstop2 !== null" style="display:flex;align-items:center">
                              <v-icon>mdi.dots-vertical</v-icon>
                            </span>
                            <span v-if="item.travelstop2 !== null" style="display:flex;align-items:center">
                              <v-icon class="mr-2">mdi-circle-small</v-icon> {{ item.travelstop2 }}
                            </span>
                          </div>
                        </div>
                        <div class="tcell">{{ item.distance | currency('') }}</div>
                        <div class="tcell">
                          <div v-if="item.organisationrate.length > 0">
                            <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                            <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div class="tcell"><a :href="`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${item.ref_no}`" target="_blank">View voucher</a></div>
                        <div class="tcell">{{ item.status }}</div>
                    </template>
                  </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>
</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }
    .range-date-input{
      background: transparent !important;
      font-size: .9rem;
    }
    .input-group-merge .input-group-text{
      background: transparent !important;
      border-right: none !important;
    }
    .picker.range-date-picker{
      position: fixed !important;
      margin-top: 2.4rem !important;
      z-index: 100 !important;
    }

    .voucher .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(7%, 10%) !important;*/
       grid-template-columns: 14% 10% 10% 14% 14% 12% 6% 6% 7% 7%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<style scoped>
  .v-virtual-scroll{
      height: 65vh !important;
    }
    .cardtext{
      height: 65vh !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"InvoiceReport",
    components: { DateRangePicker },
    data(){
        return {
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Reports',
              disabled: false,
              href: '',
            },
            {
              text: 'Client Vouchers',
              disabled: false,
              href: '',
            },
          ],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],

          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",

          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           searchfilter: "",
           searchrequestcategory: "",
           search: "",
           Drivers: [],
           item: {
            request_category: '',
            organisation_id: '',
            project_id: '',
            customer_id: ''
           },
           fields: [
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Stop', value: 'stop' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'E-voucher', value: 'evoucher' },
            { text: 'Status', value: 'status' },
           ],
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            searchorganisation: null,
            searchproject: null,
            searchcustomer: null,
            show: false,
            dates: {},
            daterange: {
              start: '',
              end: ''
            },
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            rowHeight: 25,
            perPage: 200,
            start: 0,
            timeout: null 
 
        }
    },
    beforeMount() {
      this.$store.state.allbookingreports = []
      // if(!ability.can('view-reports', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    serverPrefetch () {
      return this.getOrganisation()
    },
    mounted(){

      if(!this.organisations || this.organisations.length == 0) {
        this.getOrganisation()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {
      vat() {
        return this.$store.state.vat
      },
      bookingreports() {
        return this.$store.state.allbookingreports.filter(item => {
          this.start = 0
          let name = "";
          if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
            
          return name.toLowerCase().includes(this.search.toLowerCase())  || item.passengers.some(item => item.passenger_name.includes(this.search) || item.telephone.includes(this.search))
        })
      },
      allbookingreports() {
        return this.bookingreports
      },
      organisations() {
        return this.$store.state.organisations
      },
      projects() {
        if(this.item.organisation_id){
          let data = this.organisations.filter(organisation => organisation.id === parseInt(this.item.organisation_id) )
          let res = data[0].projects
          return res
        }else{
          return []
        }       
      },
      customers() {
        return this.$store.state.customers
      },
      ...mapState({
        searchFilter: 'searchFilter'
      }),
    },
    methods:{
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.organisation_id)
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',this.item.project_id)
          fd.append('customer_id',this.item.customer_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/booking/invoice/voucher/pdf', fd, config).then(response=>{
         this.loadingpdf = false
         let data = this.organisations.filter(organisation => organisation.id === parseInt(this.item.organisation_id) )
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         if(data.length > 0) {
          fileLink.setAttribute('download', `${data[0].name}_booking_voucher_${this.daterange.start}_to_${this.daterange.end}.pdf`);
         }else {
          fileLink.setAttribute('download', 'booking_voucher.pdf');
         }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
      fetchreport(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('id',this.item.organisation_id)
          fd.append('request_category',this.item.request_category)
          fd.append('project_id',this.item.project_id)
          fd.append('customer_id',this.item.customer_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/booking/invoice/reports', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.allbookingreports = response.data.bookings
              this.$store.state.vat = response.data.vat
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      getOrganisation(){
        this.$store
          .dispatch('getOrganisation')
          .then(() => {
          })
          .catch(() => {
          })
      },

    }
}
</script>\