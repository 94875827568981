<template>
    <!-- BEGIN: Content-->
    <div class="vehicleList mt-3">
      <v-container fluid>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between;align-items:center">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>

                  <div style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between;align-items:center">
                    <v-btn
                      color="#000000"
                      class="ma-1 white--text"
                      depressed
                      @click.stop="dialog = true"
                    >
                      Vehicle
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          
          <v-col md="12" sm="12" class="my-2 py-0">
            <v-card class="card-shadow py-0">
              <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#008000"
                      buffer-value="0"
                      stream
                      height="3"
                      :active="tableloader"
                    ></v-progress-linear>
                  </div>
              <v-card-title style="display:flex;align-items:center;justify-content:flex-end">
                <v-col md="2" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                  <v-text-field
                        v-model="searchfilter"
                        label="Search vehicle"
                        class="mx-4"
                        solo
                        hide-details="auto"
                      ></v-text-field>
                </v-col>
              </v-card-title>
              <v-card-text class="px-0 pb-0 cardtext">
                <div class="classloader">
                       <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="tableloader"/>
                    </div>
                <v-virtual-scroll
                    :items="allvehicles"
                    :item-height="23"
                    renderless 
                    id="virtualscroll"
                    class="vehicles"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell"> {{ item.name }}</div>
                        <div class="tcell">{{ item.registration }}</div>
                        <!-- <div class="tcell">{{ item.ownership.ownership }}</div>
                        <div class="tcell">{{ item.type.type }}</div>
                        <div class="tcell">{{ item.make.make }}</div>
                        <div class="tcell">{{ item.model.model }}</div> -->
                        <div class="tcell">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>ti-arrow-circle-down</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                                <v-list-item
                                  @click="editOrg(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                              <v-list-item
                                link
                                @click="deleteItem(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="480px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Vehicle</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Name*"
                      v-model="item.name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Registration*"
                      required
                      v-model="item.registration"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vtype"
                      label="Select type"
                      v-model="item.type_id"
                      item-text="type"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="search"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vmake"
                      label="Select make"
                      v-model="item.make_id"
                      item-text="make"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchmake"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vmodel"
                      label="Select model"
                      v-model="item.model_id"
                      item-text="model"
                      item-value="id"
                      :search-input.sync="searchmodel"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Color"
                      v-model="item.color"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vownership"
                      label="Select ownership"
                      v-model="item.ownership_id"
                      item-text="ownership"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchownership"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Chasis"
                      v-model="item.chasis"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="dark darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="480px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Vehicle</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Name*"
                      v-model="item.updatename"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Registration*"
                      required
                      v-model="item.updateregistration"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vtype"
                      label="Select type"
                      v-model="item.updatetype_id"
                      :value="item.updatetype_id"
                      item-text="type"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="search"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vmake"
                      label="Select make"
                      v-model="item.updatemake_id"
                      :value="item.updatemake_id"
                      item-text="make"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchmake"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vmodel"
                      label="Select model"
                      v-model="item.updatemodel_id"
                      :value="item.updatemodel_id"
                      item-text="model"
                      item-value="id"
                      :search-input.sync="searchmodel"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Color"
                      v-model="item.updatecolor"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vownership"
                      label="Select ownership"
                      v-model="item.updateownership_id"
                      :value="item.updateownership_id"
                      item-text="ownership"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchownership"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Chasis"
                      v-model="item.updatechasis"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="dark darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>
<style>
  .vehicles .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     grid-auto-columns: 40% 40% 20% !important;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '../../../services/ability'
import $ from 'jquery';
import Spinner from 'vue-spinkit'

export default {
    name:"vehicleList",
    components: { Spinner},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          searchfilter: '',
          search:null,
          valid: true,
          searchmake:null,
          searchmodel:null,
          searchownership:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Business Config',
              disabled: false,
              href: '',
            },
            {
              text: 'Vehicles',
              disabled: false,
              href: '',
            },
            {
              text: 'Vehicle List',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Name', value: 'name' },
            { text: 'Registration', value: 'registration' },
            // { text: 'Ownership', value: 'ownership' },
            // { text: 'Type', value: 'type' },
            // { text: 'Make', value: 'make' },
            // { text: 'Model', value: 'model' },
            { text: 'Action', value: 'action' },
           ],
           previewImage: null,
          item:{
              name: "",
              registration:"",
              type_id:"",
              make_id:"",
              ownership_id:"",
              model_id:"",
              color:"",
              chasis:"",
              

              updatename: "",
              updateregistration:"",
              updatetype_id:"",
              updatemake_id:"",
              updateownership_id:"",
              updatemodel_id:"",
              updatecolor:"",
              updatechasis:"",

              updateID: "",
              _method:"patch"
          },
          isBusy: false,
          loading: false,
           disabled: false,
            updateModal: null,
            height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            tableloader: false,
            timeout: null 
        }
    },
    beforeMount() {
      if(!ability.can('vehicle-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    serverPrefetch () {
      return this.getVehicle()
    },
    mounted(){
      
      if(!this.allvehicles || this.allvehicles.length == 0 || this.vtype.length == 0 || this.vmake.length == 0 || this.vmodel.length == 0 || this.vownership.length == 0) {
        this.getVehicle()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {
      vehicles() {
        return this.$store.state.vehicles.filter(item => {

          this.start = 0

          return item.name.toLowerCase().includes(this.searchfilter.toLowerCase()) || item.type.toLowerCase().includes(this.searchfilter.toLowerCase()) || item.registration.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      allvehicles() {
        return this.vehicles
      },
      vtype() {
        return this.$store.state.vehicletype
      },
      vmake() {
        return this.$store.state.make
      },
      vmodel() {
        return this.$store.state.model
      },
      vownership() {
        return this.$store.state.ownership
      }
    },
    methods:{
      onChange(e) {
        if(e) {
          this.item.image = e;
          this.previewImage = URL.createObjectURL(this.item.image);
        }
      },
     getVehicle(){
        this.tableloader = true
        this.$store
          .dispatch('getVehicles')
          .then(() => {
            this.tableloader = false
          })
          .catch(() => {
            this.tableloader = false
          })
      
      },
      clear () {
          this.$refs.form.reset()
        },
       create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.name)
            fd.append('registration',this.item.registration)
            fd.append('type_id',this.item.type_id)
            fd.append('make_id',this.item.make_id)
            fd.append('ownership_id',this.item.ownership_id)
            fd.append('model_id',this.item.model_id)
            fd.append('color',this.item.color)
            fd.append('chasis',this.item.chasis)

            axios.post('/vehicle', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.$toast.success('Vehicle added successfully','Success',{position: 'topRight',timeout: 7000,})
              this.item.name = ''
              this.item.registration = ''
              this.item.type_id = ''
              this.item.make_id = ''
              this.item.ownership_id = ''
              this.item.model_id = ''
              this.item.color = ''
              this.item.chasis = ''
              
              this.clear()
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
       update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
             fd.append('name',this.item.updatename)
             fd.append('registration',this.item.updateregistration)
            fd.append('type_id',this.item.updatetype_id)
            fd.append('make_id',this.item.updatemake_id)
            fd.append('ownership_id',this.item.updateownership_id)
            fd.append('model_id',this.item.updatemodel_id)
            fd.append('color',this.item.updatecolor)
            fd.append('chasis',this.item.updatechasis)
            
            fd.append('_method',this.item._method)

            axios.post(`/vehicle/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
                this.$toast.success('Vehicle updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.updatedialog = false
              this.item.updatename = ''
              this.item.updateregistration = ''
              this.item.updatetype_id = ''
              this.item.updatemake_id = ''
              this.item.updateownership_id = ''
              this.item.updatemodel_id = ''
              this.item.updatecolor = ''
              this.item.updatechasis = ''
              
              this.item.updateID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      editOrg(id) {
        let index = this.allvehicles.filter(item => item.id === id );
          this.item.updatename = index[0].name
          this.item.updateregistration = index[0].registration
          this.item.updatetype_id = parseInt(index[0].type_id)
          this.item.updatemake_id = parseInt(index[0].make_id)
          this.item.updateownership_id = parseInt(index[0].ownership_id)
          this.item.updatemodel_id = parseInt(index[0].model_id)
          this.item.updatecolor = index[0].color
          this.item.updatechasis = index[0].chasis
          
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      deleteItem(id){
            if(confirm("Are you sure to delete vehicle ?")){
                axios.delete(`/vehicle/${id}`).then(response=>{
                  this.$toast.success('Vehicle deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
    }
}
</script>