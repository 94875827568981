<template>
    <!-- BEGIN: Content-->
    <div class="mt-3 profile">

      <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>

          </v-col>
        </v-row>

      <v-container fluid style="margin-top:1.5rem !important;padding-bottom:0 !important">
        <v-row
          no-gutters
          class=""
        >
          <v-col
            cols="12"
            md="12"
            lg="8"
            xl="7"
            sm="12"
            >
            <v-card class="card-shadow py-0" min-height="196px" tile>

              <v-fade-transition>
                <div class="classloader">
                   <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
                </div>
              </v-fade-transition>

              <v-card-text style="display:flex;align-items:end;justify-content:space-between">
                <div v-if="details !== null" style="display:flex;flex-direction:row;">
                    <v-avatar size="100" v-if="details.profile !== null">
                      <v-img :src="details.profile"
                        :alt="details.name"></v-img>
                    </v-avatar>
                    <v-avatar size="100" v-else>
                      <v-img src="@/assets/no_user.png"
                        alt="profile"></v-img>
                    </v-avatar>
                    <div class="ml-3" style="display:flex;flex-direction:column">
                      <h3>{{ details.name }}</h3>
                      <star-rating v-bind:increment="0.5"
                                   v-bind:max-rating="5"
                                   v-model:rating="rating"
                                   inactive-color="#c8c8c8"
                                   active-color="#F9780D"
                                   v-bind:star-size="25"></star-rating>
                      <table class="mt-3">
                        <tr><td>Telephone</td><td>{{ details.telephone }}</td></tr>
                        <!-- <tr><td>National ID</td><td>{{ details.idno }}</td></tr>
                        <tr><td>Email</td><td>{{ details.email }}</td></tr> -->
                        <tr><td>Vehicle</td><td>{{ details.vname }}</td></tr>
                        <tr><td>Registration</td><td>{{ details.registration }}</td></tr>
                        <tr><td>Organisation</td><td><div v-if="details.organisation !== null">{{ details.organisation.name }}</div><div v-else>N/A</div></td></tr>
                      </table>
                    </div>
                </div>

                <div v-if="details !== null" style="float:right">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title><div style="font-size:1.3rem;font-weight:600">{{ todaycommission | currency('',0) }}</div></v-list-item-title>
                      <v-list-item-subtitle>Today's Commission</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>

                <div v-if="details !== null" style="float:right">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title><div style="font-size:1.3rem;font-weight:600">{{ totalcommission | currency('',0) }}</div></v-list-item-title>
                      <v-list-item-subtitle>Total Commission</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="12"
            lg="8"
            xl="7"
            sm="12"
            class="mt-0"
          >
            <v-card class="card-shadow py-0" style="margin-top:.2rem;margin-bottom:1rem;background:transparent !important" tile>
              <v-tabs
                color="deep-purple accent-4"
                style="max-height:30.4rem"
                v-model="tab"
               >
                <v-tab class="push-left">Customer Ratings</v-tab>
                <v-tab>Documents</v-tab>
                <v-tab>Psv Expiry</v-tab>
                <v-tab>Commisions</v-tab>
                <v-tab>Displinary Record</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  class="mt-0"
                  :transition="false"
                  style="height: 100%"
                  eager
                 >
                  <Rating :driverid="parseInt(this.$route.params.id)"/>
                </v-tab-item>

                <v-tab-item
                  class="mt-0"
                  transition="fade"
                  style="height: 100%"
                 >
                  <v-container class="py-4" fluid v-if="details !== null">
                    <v-row>

                      <v-col
                          cols="12"
                          md="4"
                        >
                          <p style="font-size:.95rem !important" class="mx-1">Available Documents</p>
                          <v-list two-line>
                            <v-list-item-group
                            >
                              <template v-for="(document,index) in details.document">
                                <v-list-item :key="document.filename" @click="viewDoc(document.file, document.filename)">
                                  <template v-slot:default="{ active }">
                                    <v-list-item-avatar>
                                      <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>{{ document.filename }}</v-list-item-title>
                                      <v-list-item-subtitle>Document Name</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-divider
                                  v-if="index < details.document.length - 1"
                                  :key="index"
                                ></v-divider>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-col>

                      <v-col cols="12" md="8">
                        <p style="font-size:.95rem !important" class="mx-1">Update Documents</p>
                        <v-form  enctype="multipart/form-data" v-model="valid" ref="formDocument">
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-file-input
                                accept="application/pdf"
                                prepend-icon="mdi-file-pdf-box"
                                label="Select licence document"
                                required
                                :rules="fieldRules"
                                v-on:change="onLicenceChange($event)"
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-file-input
                                accept="application/pdf"
                                prepend-icon="mdi-file-pdf-box"
                                required
                                :rules="fieldRules"
                                label="Select good conduct document"
                                v-on:change="onGoodConductChange($event)"
                              ></v-file-input>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-btn
                                color="primary darken-1"
                                :loading="loading"
                                :disabled="!valid"
                                @click="updateDocument"
                                class="mb-5"
                              >
                                Update
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item
                  class="mt-0"
                  transition="fade"
                  style="height: 100%"
                  >
                  <v-container fluid class="mt-1">
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-form  enctype="multipart/form-data" v-model="valid" ref="formExpiry">
                          <v-row>
                            <v-col cols="12" md="12">
                              <span class="text-h6 ml-0" style="font-size:1rem !important">Update Psv Expiry</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"

                              >
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      prepend-icon="mdi-calendar"
                                      label="Set psv expiry"
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="fieldRules"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                    required
                                    :rules="fieldRules"
                                    @input="menu = false"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="#f9780d"
                                      @click="menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="#f9780d"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-btn
                                color="primary darken-1"
                                :loading="loading"
                                :disabled="!valid"
                                @click="updateExpiry"
                                class="mb-5"
                              >
                                Update
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item
                  class="mt-0"
                  transition="fade"
                  style="height: 100%"
                 >
                  <v-container fluid>
                    <Commission :driverid="parseInt(this.$route.params.id)"/>
                  </v-container>
                </v-tab-item>

                <v-tab-item
                  class="mt-0"
                  transition="fade"
                  style="height: 100%"
                 >
                  <Disciplinary :driverid="parseInt(this.$route.params.id)"/>
                </v-tab-item>

              </v-tabs-items>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </div>

</template>
<style>
  .profile .v-list-item__title{
    margin-bottom:.5rem !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__title, .profile .v-list-item__action-text{
    white-space: normal !important;
    text-transform: none !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__action-text{
    font-size:.8rem !important;
  }
  .v-list-item--link:before{
    background-color: #fff !important;
  }
  .v-pagination__item{
    box-shadow: none !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
      background: transparent !important;
    }
  /*  .v-slide-group__wrapper{
      margin-top: -3rem !important;
    }*/
    .v-pagination__navigation{
      box-shadow: none !important;
    }
    /*.v-window.v-item-group{
      margin-top:-3rem !important;
      padding:1rem !important;
    }*/
    .v-tabs-bar,.v-tabs-bar__content{
      background-color: #fff !important;
    }
    .v-tabs-items{
      background-color: #fff !important;
      margin-top:.3rem !important;
    }
</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }

    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }

    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    
    .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }
    thead th, tbody td{
      white-space: nowrap !important;
    }

    .v-tab{
      padding:.3rem 1.5rem !important;
      background:#FFFFFF;
      border-radius: 50px !important;
      height:2rem !important;
      margin:.4rem;
    }
    .v-tab.v-tab--active{
      background:#DEC7FF;
    }
    table td{
      padding-right: 1rem !important;
      font-size:12px !important;
      text-transform: capitalize !important;
    }
</style>
<script>
import axios from 'axios'
import StarRating from 'vue-star-rating'
import {ability} from '@/services/ability'

import Commission from '@/components/drivers/components/commissions'
import Rating from '@/components/drivers/components/ratings'
import Disciplinary from '@/components/drivers/components/disciplinary'
import Spinner from 'vue-spinkit'

export default {
    name:"DriverProfile",
    components: {
      StarRating,Commission,Rating,Disciplinary,Spinner
    },
    data(){
        return {
          overlay:false,
          tab: null,
          dialog: false,
          updatedialog: false,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          details: null,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Driver',
              disabled: false,
              href: '/drivers/list',
            },
            {
              text: 'Profile',
              disabled: false,
              href: '',
            },
          ],
          attrs: {
            // class: 'mb-6',
            boilerplate: true,
            elevation: 0,
          },
           rating:0,
           menu: false,
           date: "", 
           rowHeight: 20,
           previewImage: null,
           documents: null,
           valid: true,
           start: 0,
            timeout: null,
          item:{
                name:"",
                telephone:"",
                idno:"",
                email:"",
                username:"",
                organisation_id:"",
                vehicle_id:"",
                licence: null,
                goodconduct: null,
                profile: null,

                updatename:"",
                updatetelephone:"",
                updateidno:"",
                updateemail:"",
                updateusername:"",
                updateorganisation_id:"",
                updatevehicle_id:"",

                updateID: "",
                _method:"patch"
            },
            isBusy: false,
            loading: false,
           disabled: false,
           height: '100%' 
          }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.disciplinaries = []
      this.$store.state.ratings = []
      this.$store.state.commissions = []

      this.showDetails(this.$route.params.id)
      this.fetchcommissions(this.$route.params.id)
    },
    computed: {      
      todaycommission() {
        return this.$store.state.todaycommission
      },
      totalcommission() {
        return this.$store.state.totalcommission
      }
    },
    methods:{
      async viewDoc(file, name) {
        // const response = await axios.get(file, { responseType: "blob" });
        // const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        const fileLink = document.createElement('a');
        var filename = null;
        if(name == "Licence") {
          filename = "driver-licence";
        }else if(name == "Good Conduct") {
          filename = "driver-good-conduct";
        }
         fileLink.href = file;
         fileLink.setAttribute('download', filename+'.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
      },
      onGoodConductChange(e) {
        if(e) {
          this.item.goodconduct = e
        }else {
          this.item.goodconduct = null;
        }
      },
      onLicenceChange(e) {
        if(e) {
          this.item.licence = e
        }else {
          this.item.licence = null;
        }
      },
      updateExpiry(){

          if (this.$refs.formExpiry.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.$route.params.id)
            fd.append('expiry',this.date)

            axios.post('/update-expiry', fd, config).then(response=>{
                this.$toast.success('Psv expiry updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.clear()
                this.details = response.data.details
                this.date = this.details.expiry
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

            }

        },
        updateDocument(){

          if (this.$refs.formDocument.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.$route.params.id)
            fd.append('licence',this.item.licence)
            fd.append('goodconduct',this.item.goodconduct)

            axios.post('/update-documents', fd, config).then(response=>{
                this.$toast.success('Documents updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.clear()
                this.details = response.data.details
                this.date = this.details.expiry
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

            }

        },
      showDetails(id) {
        this.overlay = true
        axios.get(`/driver/${id}`).then(response=>{
            this.overlay = false
            this.details = response.data.details
            // this.date = this.details.expiry ? this.details.expiry != "null" : null;
            this.rating = parseInt(response.data.rating_avg)
            this.$store.state.commission_rates =  response.data.commissions
        }).catch(error=>{
            console.log(error)
            this.details = null        
        })
      },
      fetchcommissions(id) {
        this.$store
            .dispatch('fetchcommissions',id)
            .then(() => {
            })
            .catch(() => {
            })
      },      
      deleteItem(id){
            if(confirm("Are you sure to delete rate ?")){
                axios.delete(`/commissions/${id}`).then(response=>{
                  if(response.data.status === parseInt(200)) {
                    this.$toast.success("Rate deleted successfully",'Success',{position: 'topRight',timeout: 7000,})
                    let index = this.$store.state.commission_rates.findIndex(data => data.id == response.data.commission.id);
                    if (index > -1) {
                      this.$store.state.commission_rates.splice(index, 1);
                    }
                  }
                  
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
    }
}
</script>