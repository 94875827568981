import Vue from 'vue'
import VueRouter from 'vue-router'
import {ability} from '@/services/ability'
import { AbilityBuilder, Ability } from '@casl/ability'
const { can, cannot, build } = new AbilityBuilder(Ability)

Vue.use(VueRouter)

// const AppHome= () => import("@/components/AppHome");

import Settings from "@/components/settings/index.vue"
import ForgotPassword from "@/components/views/forgotpassword.vue"
import ResetPasswordForm from "@/components/views/resetpassword.vue"
import Profile from "@/components/profile/index.vue"
import driverProfile from "@/components/drivers/profile.vue"
import returnBooking from "@/components/bookings/returnbooking.vue"
import addBookingList from "@/components/bookings/newbooking.vue"
import todayBookingList from "@/components/bookings/today-bookings.vue"
import bookingList from "@/components/bookings/index.vue"
import bookingEdit from "@/components/bookings/edit.vue"

import Reports from "@/components/reports/index.vue"
import InvoiceReport from '@/components/reports/invoice.vue'
import VoucherReport from '@/components/reports/voucher.vue'
import organisationRating from "@/components/bookings/rating.vue"

import customerStatement from "@/components/finance/statements/index.vue"
import invoiceList from "@/components/finance/invoices/index.vue"
import invoiceDetail from "@/components/finance/invoices/details.vue"
import newInvoice from "@/components/finance/invoices/new.vue"
import showInvoice from "@/components/finance/invoices/show.vue"
import editInvoice from "@/components/finance/invoices/edit.vue"
import invoicePayment from "@/components/finance/invoices/payment.vue"
import paymentList from "@/components/finance/payments/index.vue"
import newPayment from "@/components/finance/payments/new.vue"
import showPayment from "@/components/finance/payments/show.vue"
import editPayment from "@/components/finance/payments/edit.vue"
import creditnotesList from "@/components/finance/credit_notes/index.vue"
import newCreditNote from "@/components/finance/credit_notes/new.vue"
import showCreditNote from "@/components/finance/credit_notes/show.vue"
import editCreditNote from "@/components/finance/credit_notes/edit.vue"
import debitnotesList from "@/components/finance/debit_notes/index.vue"
import newDebitNote from "@/components/finance/debit_notes/new.vue"
import showDebitNote from "@/components/finance/debit_notes/show.vue"
import editDebitNote from "@/components/finance/debit_notes/edit.vue"
import paymentReversalsList from "@/components/finance/reversals/index.vue"
import newPaymentReversal from "@/components/finance/reversals/new.vue"
import editPaymentReversal from "@/components/finance/reversals/edit.vue"

import clientsList from "@/components/clients/index.vue"

import Dashboard from "@/components/Dashboard.vue"
import Login from "@/components/views/Login.vue"
import Signup from "@/components/views/Signup.vue"

import orgTypeList from "@/components/org/type/index.vue"
import orgCategoryList from "@/components/org/category/index.vue"
import orgProjectList from "@/components/org/projects/index.vue"
import orgList from "@/components/org/list/index.vue"
import organisationRates from "@/components/org/rates/index.vue"

import vehicleMakeList from "@/components/vehicle/make/index.vue"
import vehicleTypeList from "@/components/vehicle/type/index.vue"
import vehicleOwnershipList from "@/components/vehicle/ownership/index.vue"
import vehicleList from "@/components/vehicle/list/index.vue"
import vehicleModelList from "@/components/vehicle/model/index.vue"

import customerList from "@/components/customers/index.vue"
import driverList from "@/components/drivers/index.vue"
import usersList from "@/components/config/users.vue"
import roleList from "@/components/config/roles.vue"
import permissionList from "@/components/config/permissions.vue"

import EntranceList from "@/components/expressway/entrance.vue"
import ExitList from "@/components/expressway/exit.vue"
import RateList from "@/components/expressway/rate.vue"

 const routes = [
 { 
    path: '/expressway/entrances', 
    name: 'EntranceList', 
    component: EntranceList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/expressway/exits', 
    name: 'ExitList', 
    component: ExitList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/expressway/rates', 
    name: 'RateList', 
    component: RateList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/settings', 
    name: 'Settings', 
    // component: () => import('../components/settings/index.vue'), 
    component: Settings, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/forgot/password', 
    name: 'ForgotPassword', 
    component: ForgotPassword, 
    // component: () => import('../components/views/forgotpassword.vue'), 
    meta: {
      auth: undefined
    }
  },
  { 
    path: '/reset/:token', 
    name: 'ResetPasswordForm', 
    component: ResetPasswordForm, 
    // component: () => import('../components/views/resetpassword.vue'), 
    meta: {
      auth: undefined
    } 
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: Profile,
    // component: () => import('../components/profile/index.vue'),
    meta: {
      auth: true
    }
  },
  {
      name: 'driverProfile',
      path: '/driver/:id/profile',
      component: driverProfile,
      // component: () => import('../components/drivers/profile.vue'),
      meta: {
        auth: true
      }
  },
  {
    path: '/new/return/booking',
    name: 'returnBooking',
    component: returnBooking,
    // component: () => import('../components/bookings/returnbooking.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/new/booking',
    name: 'addBookingList',
    component: addBookingList,
    // component: () => import('../components/bookings/newbooking.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/today-booking/list',
    name: 'todayBookingList',
    component: todayBookingList,
    // component: () => import('../components/bookings/today-bookings.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/booking/list',
    name: 'bookingList',
    component: bookingList,
    // component: () => import('../components/bookings/index.vue'),
    meta: {
      auth: true
    }
  },
  {
      name: 'bookingEdit',
      path: '/booking/:id/edit',
      component: bookingEdit,
      // component: () => import('../components/bookings/edit.vue'),
      meta: {
        auth: true
      }
  },
  {
    path: '/booking/reports',
    name: 'Reports',
    component: Reports,
    // component: () => import('../components/reports/index.vue'),
    meta: {
      auth: true
    }
    // beforeEnter: (to, from, next) => {
    //     if(ability.can('view-reports', 'all')) {
    //       next()
    //       return
    //     }else {
    //       next(false)
    //       return
    //     } 
        
    //   }
  },
  {
    path: '/booking/invoice',
    name: 'InvoiceReport',
    component: InvoiceReport,
    meta: {
      auth: true
    }
  },
  {
    path: '/booking/vouchers',
    name: 'VoucherReport',
    component: VoucherReport,
    meta: {
      auth: true
    }
  },
  // organisation rating
  {
    path: '/organisation/ratings',
    name: 'organisationRating',
    component: organisationRating,
    // component: () => import('../components/bookings/rating.vue'),
    meta: {
      auth: true
    }
  },
  // statements
  {
    path: '/organisation/statements',
    name: 'customerStatement',
    component:customerStatement,
    // component: () => import('../components/finance/statements/index.vue'),
    meta: {
      auth: true
    }
  },
  // invoices
  {
    path: '/organisation/invoices',
    name: 'invoiceList',
    component: invoiceList,
    // component: () => import('../components/finance/invoices/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/invoice-details',
    name: 'invoiceDetail',
    component:invoiceDetail,
    // component: () => import('../components/finance/invoices/details.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/invoice',
    name: 'newInvoice',
    component:newInvoice,
    // component: () => import('../components/finance/invoices/new.vue'),
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showInvoice',
      path: '/organisation/invoice/:id/details',
      component: showInvoice,
      // component: () => import('../components/finance/invoices/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editInvoice',
      path: '/organisation/invoice/:id/edit',
      component: editInvoice,
      // component: () => import('../components/finance/invoices/edit.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'invoicePayment',
      path: '/organisation/invoice-payment/:id/:organisation_id/edit',
      component: invoicePayment,
      // component: () => import('../components/finance/invoices/payment.vue'),
      meta: {
        auth: true
      }
  },
  // end invoices

  // payment
  {
    path: '/organisation/payments',
    name: 'paymentList',
    component:paymentList,
    // component: () => import('../components/finance/payments/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/payment',
    name: 'newPayment',
    component: newPayment,
    // component: () => import('../components/finance/payments/new.vue'),
    meta: {
      auth: true
    }
  }, 

   {
      name: 'showPayment',
      path: '/organisation/payment/:id/details',
      component: showPayment,
      // component: () => import('../components/finance/payments/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editPayment',
      path: '/organisation/payment/:id/edit',
      component:editPayment,
      // component: () => import('../components/finance/payments/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end payments

  // credit notes
  {
    path: '/organisation/credit-notes',
    name: 'creditnotesList',
    component:creditnotesList,
    // component: () => import('../components/finance/credit_notes/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/credit-note',
    name: 'newCreditNote',
    component: newCreditNote,
    // component: () => import('../components/finance/credit_notes/new.vue'),
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showCreditNote',
      path: '/organisation/credit-note/:id/details',
      component: showCreditNote,
      // component: () => import('../components/finance/credit_notes/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editCreditNote',
      path: '/organisation/credit-note/:id/edit',
      component: editCreditNote,
      // component: () => import('../components/finance/credit_notes/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end credit notes

  // debit notes
  {
    path: '/organisation/debit-notes',
    name: 'debitnotesList',
    component:debitnotesList,
    // component: () => import('../components/finance/debit_notes/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/debit-note',
    name: 'newDebitNote',
    component:newDebitNote,
    // component: () => import('../components/finance/debit_notes/new.vue'),
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showDebitNote',
      path: '/organisation/debit-note/:id/details',
      component:showDebitNote,
      // component: () => import('../components/finance/debit_notes/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editDebitNote',
      path: '/organisation/debit-note/:id/edit',
      component: editDebitNote,
      // component: () => import('../components/finance/debit_notes/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end credit notes

  // reversal payments
  {
    path: '/organisation/payment-reversals',
    name: 'paymentReversalsList',
    component: paymentReversalsList,
    // component: () => import('../components/finance/reversals/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/reversal',
    name: 'newPaymentReversal',
    component:newPaymentReversal,
    // component: () => import('../components/finance/reversals/new.vue'),
    meta: {
      auth: true
    }
  },
  {
      name: 'editPaymentReversal',
      path: '/organisation/payment-reversal/:id/edit',
      component: editPaymentReversal,
      // component: () => import('../components/finance/reversals/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end reversal payments

  {
    path: '/clients/list',
    name: 'clientsList',
    component: clientsList,
    // component: () => import('../components/clients/index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    // component: () => import('../components/Dashboard.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    // component: () => import('../components/views/Login.vue'),
    meta: {
      auth: undefined
    }
  },
  {
    path: '/auth/register',
    name: 'Signup',
    component: Signup
    // component: () => import('../components/views/Signup.vue')
  },
  {
      name: 'orgTypeList',
      path: '/organisation/type/list',
      component: orgTypeList,
      // component: () => import('../components/org/type/index.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'orgCategoryList',
      path: '/organisation/category/list',
      component: orgCategoryList,
      // component: () => import('../components/org/category/index.vue'),
      meta: {
        auth: true
      }
  },

  {
      name: 'orgProjectList',
      path: '/organisation/projects/list',
      component: orgProjectList,
      // component: () => import('../components/org/projects/index.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'orgList',
      path: '/organisation/list',
      component:orgList,
      // component: () => import('../components/org/list/index.vue'),
      meta: {
        auth: true
      }
  },

  {
    path: '/organisation/rates',
    name: 'organisationRates',
    component:organisationRates,
    // component: () => import('../components/org/rates/index.vue'),
    meta: {
      auth: true
    }
  },

// vehicles make
  {
      name: 'vehicleMakeList',
      path: '/vehicle/make/list',
      component:vehicleMakeList,
      // component: () => import('../components/vehicle/make/index.vue'),
      meta: {
        auth: true
      }

  },
  // vehicles type
  {
      name: 'vehicleTypeList',
      path: '/vehicle/type/list',
      component: vehicleTypeList,
      // component: () => import('../components/vehicle/type/index.vue'),
      meta: {
        auth: true
      }

  },
  // vehicles ownership
  {
      name: 'vehicleOwnershipList',
      path: '/vehicle/ownership/list',
      component:vehicleOwnershipList,
      // component: () => import('../components/vehicle/ownership/index.vue'),
      meta: {
        auth: true
      }

  },
  // vehicles list
  {
      name: 'vehicleList',
      path: '/vehicle/list',
      component: vehicleList,
      // component: () => import('../components/vehicle/list/index.vue'),
      meta: {
        auth: true
      },
  },
  // vehicles model
  {
      name: 'vehicleModelList',
      path: '/vehicle/model/list',
      component:vehicleModelList,
      // component: () => import('../components/vehicle/model/index.vue'),
      meta: {
        auth: true
      }
  },
  // customers
  {
      name: 'customerList',
      path: '/customers/list',
      component:customerList,
      // component: () => import('../components/customers/index.vue'),
      meta: {
        auth: true
      }
  },
  // drivers
  {
      name: 'driverList',
      path: '/drivers/list',
      component:driverList,
      // component: () => import('../components/drivers/index.vue'),
      meta: {
        auth: true
      }
  },
  // users
  {
      name: 'usersList',
      path: '/users/list',
      component:usersList,
      // component: () => import('../components/config/users.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'roleList',
      path: '/role/list',
      component: roleList,
      // component: () => import('../components/config/roles.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'permissionList',
      path: '/permission/list',
      component:permissionList,
      // component: () => import('../components/config/permissions.vue'),
      meta: {
        auth: true
      }
  }
]

export default routes