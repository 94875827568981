<template>
	<div>
		<v-container fluid>
      <v-row>
        <v-col
            cols="12"
            md="12"
            style="min-height:25rem"
          >
            <v-fade-transition>
              <div>
                <div class="classloader">
                   <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
                </div>
                <div class="empty-state" v-if="ratings.length === 0 && !overlay">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <v-img src="@/assets/icon.png" alt=""></v-img>
                    </div>
                    <div class="empty-state__message">No records available.</div>
                  </div>
                </div>
              </div>
            </v-fade-transition>

            <v-list two-line>
              <v-list-item-group
              >
                <template v-for="(item, index) in visiblePages">
                  <v-list-item>
                    <template v-slot:default="{ active }">
                      <v-list-item-avatar>
                        <v-img src="/assets/images/no_user.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="item.customer !== null">{{ item.customer.name }}</span>
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="item.comment == 'null' || item.comment == null">N/A</v-list-item-subtitle>
                        <v-list-item-subtitle v-text="item.comment" v-else></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>{{ moment(item.created_at).format('MMM Do YYYY, H:mm:ss A') }}</v-list-item-action-text>

                        <star-rating v-bind:increment="0.5"
                                   v-bind:max-rating="5"
                                   v-model:rating="item.stars"
                                   inactive-color="#c8c8c8"
                                   active-color="#F9780D"
                                   v-bind:star-size="15">
                      </star-rating>

                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < visiblePages.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-pagination
                    v-if="ratings.length > 0"
                    v-model="page"
                    :length="Math.ceil(ratings.length/perPage)"
                    circle
                  ></v-pagination>
          </v-col>
      </v-row>
    </v-container>
	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import StarRating from 'vue-star-rating'
import Spinner from 'vue-spinkit'
export default {
	name: 'ratings',
	components: {
    StarRating,Spinner
	},
	data: () => ({
    overlay:false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    cfields: [
       {
        text: 'Date',
        align: 'start',
        value: 'cdate',
      },
      { text: 'Fuel', value: 'fuel' },
      { text: 'Toll', value: 'toll' },
      { text: 'Miscellaneous', value: 'miscellaneous' },
      { text: 'Total Amt.', value: 'amount' },
      { text: 'Commision', value: 'commision' },
     ],
     page: 1,
     perPage: 6,

	}),
	methods: {
		fetchratings() {
      this.overlay = true
      this.$store
          .dispatch('fetchratings',this.driverid)
          .then(() => {
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })
      },
	},
	props: {
	     value: Boolean,
	     driverid: Number,
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      ratings () {
        return this.$store.state.ratings
      },
      visiblePages () {
        return this.ratings.slice((this.page - 1)* this.perPage, this.page* this.perPage)
      },
	  },
	mounted() {
    if(this.ratings.length == 0)
      this.fetchratings()
	},
	created() {
     
    }
}
</script>