<template>
	<div>
		<v-container fluid>
        <v-row>
          <v-col
          cols="12"
          md="12"
          class="mb-0 pb-0"
          >
            <v-btn
                style="float:right"
                color="#000000"
                class="ma-1 white--text"
                depressed
                @click.stop="disciplinarydialog = true"
              >
                Disciplinary
                <v-icon
                  right
                  dark
                >
                  mdi-plus
                </v-icon>
              </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="mt-0 pt-0"
            style="min-height:25rem"
            >

            <v-fade-transition>
              <div>
                <div class="classloader">
                   <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
                </div>
                <div class="empty-state" v-if="disciplinary.length === 0 && !overlay">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <v-img src="@/assets/icon.png" alt=""></v-img>
                    </div>
                    <div class="empty-state__message">No records available.</div>
                  </div>
                </div>
              </div>
            </v-fade-transition>

            <v-card class="py-3" style="box-shadow:none;">
              <v-card-title class="mx-0 px-1 pb-0 mb-0" style="display:flex;align-items:center;justify-content:end">
                <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                  <v-text-field
                    v-model="searchfilter"
                    label="Search"
                    solo
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-card-title>
              <v-card-text class="mx-0 px-0 my-0 py-0">
                <v-col
                    cols="12"
                    md="12"
                  >
                    <v-list two-line>
                      <v-list-item-group
                      >
                        <template v-for="(item, index) in visiblePages">
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span v-if="item.customer !== null" class="mb-3" style="font-size:.95rem !important;font-weight:600 !important">{{ item.title }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text>{{ moment(item.created_at).format('MMM Do YYYY') }}</v-list-item-action-text>

                                <div style="display:flex;">
                                  <v-icon
                                    small
                                    class="mr-4"
                                    @click="editDisciplinary(item.id)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon
                                    small
                                    @click="deleteDisciplinary(item.id)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </div>

                              </v-list-item-action>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < visiblePages.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-pagination
                            v-if="disciplinary.length > 0"
                            v-model="page"
                            :length="Math.ceil(disciplinary.length/perPage)"
                            circle
                          ></v-pagination>
                  </v-col>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

    </v-container>

    <!-- add disciplinary modal-->
    <v-dialog
      v-model="disciplinarydialog"
      width="450px"
      >
      <v-card>

        <v-form  enctype="multipart/form-data" v-model="valid" ref="disciplinaryform">

        <v-card-title>
          <span class="text-h6">Record Disciplinary</span>
        </v-card-title>

        <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  lg="12"
                  xl="12"
                >
                  <v-text-field
                    label="Summary*"
                    v-model="item.title"
                    required
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    lg="12"
                    xl="12"
                    >
                  <v-textarea
                    name="input-7-1"
                    label="Description*"
                    v-model="item.description"
                    required
                    :rules="fieldRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="disciplinarydialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="!valid"
              @click="createDisciplinary"
            >
              Save
            </v-btn>
        </v-card-actions>

      </v-form>
      </v-card>
    </v-dialog>
    <!-- end add disciplinary -->

    <!-- update disciplinary modal-->
    <v-dialog
      v-model="updatedisciplinarydialog"
      width="450px"
      >
      <v-card>

        <v-form  enctype="multipart/form-data" v-model="valid" ref="disciplinaryform">

        <v-card-title>
          <span class="text-h6">Update Disciplinary</span>
        </v-card-title>

        <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  lg="12"
                  xl="12"
                >
                  <v-text-field
                    label="Summary*"
                    v-model="item.updatetitle"
                    required
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    lg="12"
                    xl="12"
                  >
                  <v-textarea
                    name="input-7-1"
                    label="Description*"
                    v-model="item.updatedescription"
                    required
                    :rules="fieldRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="updatedisciplinarydialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="!valid"
              @click="updateDisciplinary"
            >
              Save
            </v-btn>
        </v-card-actions>

      </v-form>
      </v-card>
    </v-dialog>
    <!-- end add disciplinary -->

	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import StarRating from 'vue-star-rating'
import Spinner from 'vue-spinkit'
export default {
	name: 'disciplinary',
	components: {
    StarRating,Spinner
	},
	data: () => ({
    overlay:false,
    searchfilter:'',
    updatedisciplinarydialog: false,
    disciplinarydialog: false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    fields: [
       {
        text: '#',
        align: 'start',
        value: '#',
      },
      { text: 'Date', value: 'date' },
      { text: 'Summary', value: 'summary' },
      { text: 'Description', value: 'description' },
      { text: 'Action', value: 'action' },
     ],
     item:{
       title: "",
       description:"",
       updatetitle: "",
       updatedescription:"",
       disciplinaryID: "",
       _method:"patch"
     },
     page: 1,
     perPage: 6,

	}),
	methods: {
		fetchdisciplines() {
      this.overlay = true
      this.$store
          .dispatch('fetchdisciplines',this.driverid)
          .then(() => {
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })

      },
      createDisciplinary(){

          if (this.$refs.disciplinaryform.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.$route.params.id)
            fd.append('title',this.item.title)
            fd.append('description',this.item.description)

            axios.post('/disciplinary', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === parseInt(200))
                this.$toast.success("Record added successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.disciplinarydialog = false

                let found = this.$store.state.disciplinaries.find(data => data.id == response.data.disciplinary.id);
                if (!found) {
                  this.$store.state.disciplinaries.unshift(response.data.disciplinary)
                }

              this.item.title = ''
              this.item.description = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        updateDisciplinary(){

          if (this.$refs.disciplinaryform.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.$route.params.id)
            fd.append('title',this.item.updatetitle)
            fd.append('description',this.item.updatedescription)
            fd.append('_method',this.item._method)

            axios.post(`/disciplinary/${this.item.disciplinaryID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === parseInt(200))
                this.$toast.success("Record updated successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.updatedisciplinarydialog = false

                let index = this.$store.state.disciplinaries.findIndex(data => data.id == response.data.disciplinary.id);
                if (index > -1) {
                  this.$store.state.disciplinaries.splice(index, 1, response.data.disciplinary);
                }

              this.item.updatetitle = ''
              this.item.updatedescription = ''
              this.item.disciplinaryID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      editDisciplinary(id) {
        let index = this.disciplinary.filter(item => item.id === id );
        this.item.updatetitle = index[0].title
        this.item.updatedescription = index[0].description
        this.item.disciplinaryID = index[0].id
        this.updatedisciplinarydialog = true
      },
      deleteDisciplinary(id){
          if(confirm("Are you sure to delete record ?")){
              axios.delete(`/disciplinary/${id}`).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toast.success("Record deleted successfully",'Success',{position: 'topRight',timeout: 7000,})
                  let index = this.$store.state.disciplinaries.findIndex(data => data.id == response.data.disciplinary.id);
                  if (index > -1) {
                    this.$store.state.disciplinaries.splice(index, 1);
                  }
                }
                
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
	},
	props: {
	     value: Boolean,
	     driverid: Number,
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      disciplinary() {
        return this.$store.state.disciplinaries.filter(item => {

          this.start = 0

          let title = "",description = "";
          if(item.title != null && item.title != 'null') {title = item.title}
          if(item.description != null && item.description != 'null') {description = item.description}

            if(this.searchfilter == null || this.searchfilter == 'null') {
              this.searchfilter = ''
            }

          return title.toLowerCase().includes(this.searchfilter.toLowerCase()) || description.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      visiblePages () {
        return this.disciplinary.slice((this.page - 1)* this.perPage, this.page* this.perPage)
      },
	  },
	mounted() {
    if(this.disciplinary.length == 0)
      this.fetchdisciplines()
	},
	created() {
     
    }
}
</script>