<template>
    <!-- BEGIN: Content-->
    <div class="addBookingList mt-3">
      <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
        <keep-alive>
          <MapSection/>
        </keep-alive>
        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="7" xl="5" lg="7" sm="12" cols="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="mb-0 py-3 pr-4">
                <v-col md="12" sm="12" class="d-flex py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>

                  <v-icon v-if="isShow" @click="isShow = !isShow">mdi-chevron-up</v-icon>
                  <v-icon v-else @click="isShow = !isShow">mdi-chevron-down</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="7" xl="5" lg="7" sm="12" cols="12" class="my-1 py-0">
            <v-card class="card-shadow" v-if="isShow">
              <div style="width:100%;height:.3rem !important">
                  <v-progress-linear
                    color="#008000"
                    indeterminate
                    height="3"
                    :active="addbookingloader"
                  ></v-progress-linear>
                </div>
              <v-form class="pa-3"  enctype="multipart/form-data" v-model="valid" ref="form">
                <v-row class="py-4" style="padding-bottom:0 !important">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"

                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          type="tel" 
                          v-mask="'####-##-##'"
                          label="Pickup date(YYYY-MM-DD)*"
                          :rules="fieldRules"
                          required
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:clear="clearpickupdate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="menu = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="#f9780d"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="#f9780d"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    >
                    <v-text-field
                      v-model="time"
                      label="Pickup Time*"
                      type="tel" 
                      v-mask="'##:##'"
                      :rules="fieldRules"
                      required
                      list="data"
                      autocomplete="off"
                    ></v-text-field>
                    <datalist id="data">
                        <option v-for="time in $store.state.times" :value="time" />
                    </datalist>
                  </v-col>

                  <v-col cols="4">
                    <v-switch
                      v-model="isDelivery"
                      inset
                      label="Delivery"
                    ></v-switch>
                  </v-col>

                  <v-col cols="4">
                    <v-switch
                      v-model="bulkchecked"
                      inset
                      label="Bulk booking"
                    ></v-switch>
                  </v-col>

                  <v-col cols="4">
                    <v-switch
                      v-model="skipdays"
                      inset
                      label="Skip Days"
                    ></v-switch>
                  </v-col>

                  <v-row v-if="skipdays" class="mb-3 mx-2">
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipweekends"
                        label="Skip Weekends"
                        color="orange"
                        value="Weekends"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipmondays"
                        label="Skip Monday"
                        color="orange"
                        value="Monday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skiptuesdays"
                        label="Skip Tuesday"
                        color="orange"
                        value="Tuesday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipwednesdays"
                        label="Skip Wednesday"
                        color="orange"
                        value="Wednesday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipthursdays"
                        label="Skip Thursday"
                        color="orange"
                        value="Thursday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipfridays"
                        label="Skip Friday"
                        color="orange"
                        value="Friday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipsaturdays"
                        label="Skip Saturday"
                        color="orange"
                        value="Saturday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="item.skipsundays"
                        label="Skip Sunday"
                        color="orange"
                        value="Sunday"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-col sm="12" md="12" cols="12" v-if="bulkchecked">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-autocomplete
                          :items="['Daily', 'Weekly']"
                          label="Select frequency"
                          v-model="item.frequency"
                          :search-input.sync="searchfrequency"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"

                        >
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="enddate"
                              type="tel" 
                              v-mask="'####-##-##'"
                              label="Enddate(YYYY-MM-DD)*"
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="clearenddate"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="enddate"
                            no-title
                            scrollable
                            :min="new Date().toISOString().substr(0, 10)"
                            @input="menu3 = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu3 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu3.save(enddate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>

                      </v-col>
                    </v-row>
                  </v-col>

                  <v-row
                    v-for="(passenger, indx) in passengers" 
                      :key="indx"
                      style="display:flex;align-items:center;justify-content:space-between"
                      class="mx-2 my-2"
                    >
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                      >
                    
                          <v-autocomplete
                            v-if="passengers[indx]['isTextField'] === false"
                            :items="customers"
                            :label="Plabel"
                            v-model="passenger.id" 
                            :name="`passengers[${indx}][id]`"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            @change="(event) => updateCustomer(event,indx)"
                            hide-details
                            solo
                          >
                            
                            <div
                                slot="prepend-item"
                                class="grey--text px-4 pt-4 newcustomer"
                                @click="setText(indx)"
                                >
                              <p>New Customer</p>
                            </div>
                            <template slot="selection" slot-scope="{ item, selected }">
                                {{ item.name }}
                            </template>
                            <template slot="item" slot-scope="{ item, tile }">
                                {{ item.name }}
                            </template>

                          </v-autocomplete>
                          <v-text-field
                            v-if="passengers[indx]['isTextField'] === true"
                            append-icon="mdi-selection-ellipse-arrow-inside"
                            @click:append="setText(indx)"
                            :label="Plabel"
                            v-model="passenger.name" 
                            :name="`passengers[${indx}][name]`"
                            required
                            :rules="fieldRules"
                            hide-details
                          ></v-text-field>

                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          >
                          <v-text-field
                            label="Telephone*"
                            required
                            v-model="passenger.telephone" 
                            :name="`passengers[${indx}][telephone]`"
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          >
                          <v-autocomplete
                            :items="organisations"
                            label="Select organisation*"
                            v-model="passenger.organisation_id"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            :name="`passengers[${indx}][organisation_id]`"
                            @change="(event) => updateOrganisation(event,indx)"
                            hide-details
                            solo
                          >
                            <template slot="selection" slot-scope="{ item, selected }">
                                {{ item.name }}
                            </template>
                            <template slot="item" slot-scope="{ item, tile }">
                                {{ item.name }}
                            </template>
                          </v-autocomplete>
                        </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        >
                        <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                          <v-icon @click="addPassenger" class="mx-3">mdi-plus</v-icon>
                          <v-icon @click="removePassenger(indx)">mdi-close</v-icon>
                        </div>
                    </v-col>
                  </v-row>

                  <v-col 
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="!isDelivery"
                    >
                      <v-switch
                        v-model="useSubProjects"
                        inset
                        label="Use Sub Projects"
                        @change="onSwitchchange"
                      ></v-switch>
                  </v-col>

                  <v-row class="mb-3 mx-2" v-if="!useSubProjects && !isDelivery">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                     >
                      <v-autocomplete
                        :items="projects"
                        label="Select project"
                        v-model="item.project_id"
                        :value="item.project_id"
                        item-text="name"
                        item-value="id"
                        multiple
                        chips
                        deletable-chips
                        :search-input.sync="searchproject"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row class="mb-3 mx-2" v-if="useSubProjects && !isDelivery">
                    
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-for="(subproject,index) in projects"
                        :key="index"
                      >
                        <v-autocomplete
                          :items="subproject.subprojects"
                          :label="`Select ${capitalize(subproject.name.toLowerCase())}`"
                          :value="item.subproject_id"
                          item-text="name"
                          item-value="id"
                          chips
                          deletable-chips
                          @change="(event) => updateSubprojects(event, index, subproject.id)"
                        ></v-autocomplete>
                      </v-col>

                  </v-row>
                  
                  <v-col cols="12"
                      sm="12"
                      md="12">

                      <v-row>

                        <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          >
                          <label>Pick-up*</label>
                          <VueGooglePlaces
                            :api-key="apiKey"
                            country="ke"
                            @placechanged="onPlaceChangedFrom"
                            @noresult="onNoResult"
                            >

                              <v-text-field
                                label=""
                                required
                                :rules="fieldRules"
                                v-model="item.from" 
                              ></v-text-field>

                          </VueGooglePlaces>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          >
                          <label>Drop-off*</label>
                          <VueGooglePlaces
                            :api-key="apiKey"
                            country="ke"
                            @placechanged="onPlaceChangedTo"
                            @noresult="onNoResult"
                            >
                            <v-text-field
                                label=""
                                required
                                :rules="fieldRules"
                                v-model="item.to" 
                              ></v-text-field>

                          </VueGooglePlaces>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          style="justify-content: center !important; display: flex !important;align-items:center !important"
                          >
                          <v-icon class="mx-2" @click="shufflestops" style="width: 30px;height: 30px;">mdi-plus</v-icon>
                        </v-col>

                      </v-row>
                    
                  </v-col>

                  <v-col cols="12"
                      sm="12"
                      md="12"
                      v-show="hidestops"
                      >

                      <v-row>

                        <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          >
                          <label>Add Stop</label>
                          <VueGooglePlaces
                            :api-key="apiKey"
                            country="ke"
                            @placechanged="onPlaceChangedStop1"
                            @noresult="onNoResult"
                            >

                              <v-text-field
                                label=""
                                v-model="item.stop1" 
                              ></v-text-field>

                          </VueGooglePlaces>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          >
                          <label>Add Stop</label>
                          <VueGooglePlaces
                            :api-key="apiKey"
                            country="ke"
                            @placechanged="onPlaceChangedStop2"
                            @noresult="onNoResult"
                            >
                            <v-text-field
                                label=""
                                v-model="item.stop2" 
                              ></v-text-field>

                          </VueGooglePlaces>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          style="justify-content: center !important; display: flex !important;align-items:center !important"
                          >
                          <v-icon class="mx-2" @click="shufflestops" style="width: 30px;height: 30px;">mdi-close</v-icon>
                        </v-col>

                      </v-row>
                    
                  </v-col>

                  <v-col sm="6" md="6" cols="12" v-if="!isDelivery">
                    <label>Trip Type</label>
                    <v-radio-group
                      v-model="item.trip_type"
                      row
                      mandatory
                    >
                      <v-radio
                        label="One Way"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="Wait and Return"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col sm="6" md="6" cols="12" v-if="!isDelivery">
                    <label>Request Type</label>
                    <v-radio-group
                      v-model="item.request_type"
                      row
                      mandatory
                    >
                      <v-radio
                        label="Official"
                        value="Official"
                      ></v-radio>
                      <v-radio
                        label="Private"
                        value="Private"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="vehicletype"
                      label="Select vehicle type"
                      v-model="item.vehicle_type_id"
                      item-text="type"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchvehicle"
                    ></v-autocomplete>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Ordered by"
                      v-model="item.orderedby"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Flight details"
                      v-model="item.flight"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6" md="6"
                  >
                    <v-text-field
                      label="Remarks"
                      v-model="item.remarks"
                    ></v-text-field>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      depressed
                      color="primary darken-1"
                      :disabled="!valid"
                      @click="proceed"
                    >
                      Proceed
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
          <v-col md="4" lg="5" sm="12" cols="12">
          </v-col>
        </v-row>
      </v-container>

      <!--confirm details modal -->
      <v-dialog
        v-model="confirmdialog"
        max-width="420"
        >
          <v-card
            width="420"
            class="mx-auto"
            shaped
          >
            <v-card-title class="white--text pt-2">
              <div style="color:#000;font-size:1rem !important;font-weight:500 !important">
                Booking Details
              </div>
            </v-card-title>

            <v-card-text class="mt-2">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="white-space:nowrap;"><b>Date:</b></td>
                      <td>{{ date }}</td>
                    </tr>
                    <tr>
                      <td style="white-space:nowrap;"><b>Time:</b></td>
                      <td>{{ time }}</td>
                    </tr>
                    <tr>
                      <td style="white-space:nowrap;"><b>From:</b></td>
                      <td>{{ item.from }}</td>
                    </tr>
                    <tr v-if="item.stop1 !== ''">
                      <td style="white-space:nowrap;"><b>Stop:</b></td>
                      <td>{{ item.stop1 }}</td>
                    </tr>
                    <tr v-if="item.stop2 !== ''">
                      <td style="white-space:nowrap;"><b>Stop:</b></td>
                      <td>{{ item.stop2 }}</td>
                    </tr>
                    <tr>
                      <td style="white-space:nowrap;"><b>To:</b></td>
                      <td>{{ item.to }}</td>
                    </tr>
                    <tr>
                      <td style="white-space:nowrap;"><b>Distance:</b></td>
                      <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end)))) | currency('',2) }}Km</td>
                    </tr>
                    <tr>
                        <td style="white-space:nowrap;"><b>Time:</b></td>
                        <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1Time)+parseFloat(stop2Time)+parseFloat(endTime)))) | currency('',2) }}Min</td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  depressed
                  color="dark darken-1"
                  @click="confirmdialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  depressed
                  color="primary darken-1"
                  :loading="loading"
                  :disabled="!valid"
                  @click="create"
                >
                  Add Booking
                </v-btn>
            </v-card-actions>
          </v-card>

      </v-dialog>
      <!-- end confirm details -->

      <v-dialog
          v-model="progressdialog"
          hide-overlay
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

    </div>

</template>
<style scoped>
  .v-text-field input,.v-input input,.v-list-item {
    text-transform: uppercase !important;
    font-size:11.5px !important;
  }
</style>
<style>
.newcustomer{
  cursor: pointer;
}
.v-breadcrumbs a {
  color: #F9780D !important;
}
.dtable td{
  padding: .3rem!important;
}
.dtable td:nth-child(2) {
  white-space: normal !important;
}
  .v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
    font-size: 70px !important;
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important; 
  }
  .v-input--selection-controls {
     margin-top: 6px !important; 
     padding-top: 4px !important; 
  }
  .vue__time-picker-dropdown, .vue__time-picker .dropdown{
    z-index: 10001 !important;
  }
  .vue__time-picker input.display-time {
    flex: 1 1 auto !important;
    line-height: 20px !important;
    border: 0;
    border-bottom: 1px solid #949494 !important;
    min-width: 100% !important;
    width: 100% !important;
    height: 1.2em !important;
}
.vue__time-picker-dropdown, .vue__time-picker .dropdown{
  width: 60%;
}
.vue__time-picker-dropdown .select-list, .vue__time-picker .dropdown .select-list{
  width:100%;
}
input.display-time:focus{
  border-bottom: 1px solid #F97B12 !important;
  box-shadow: none !important;
  outline: none;
}
.vue__time-picker input.display-time {
    flex: 1 1 auto !important;
    line-height: 20px !important;
    border: 0 !important;
    border-bottom: 1px solid #949494 !important;
    min-width: 100% !important;
    width: 100% !important;
}
.vue__time-picker{
  width: 100% !important;
}
.vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker .dropdown ul li:not([disabled]).active:hover{
  background:  #F97B12;
}
</style>
<script>
import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import {ability} from '../../services/ability'
import VueTimepicker from 'vue2-timepicker'
import MapSection from '@/components/bookings/submodules/Map'
import { db } from '@/db'

export default {
    name:"addBookingList",
    components: { VueGooglePlaces,VueTimepicker,MapSection },
    data(){
        return {
          isShow:true,
          useSubProjects:false,
          sub_projects: [],
          isDelivery:false,
          bulkchecked:false,
          skipdays:false,
          ratechecked:false,
          country: 'ke',
          apiKey: 'AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc',
          // apiKey: 'AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8',
          searchResults: [],
          service: null,
          map: null,
          google:gmapApi,
          center: {lat:0, lng:0},
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          customer:null,
          valid: true,
          menu3:false,
          searchvehicle:null,
          searchproject:null,
          searchfrequency:null,
          dialog: false,
          updatedialog: false,
          progressdialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Today Bookings',
              disabled: false,
              href: '/today-booking/list',
            },
            {
              text: 'Add Booking',
              disabled: false,
              href: '',
            },
          ],
          menu: false,
          time: "",
          menu2: false,
          date: "", 
          enddate: "",
          isTextField: false,
          selectcustomer: null, 
          item:{
              distance: "",
              to: "",
              from: "",
              fromlat: "",
              fromlong: "",
              tolat: "",
              tolong: "",

              stop1: "",
              stop1lat: null,
              stop1long: null,
              stop2: "",
              stop2lat: null,
              stop2long: null,

              trip_type: "",
              request_type: "",
              project_id: [],
              name: "",
              customer_id:null,
              telephone:"",
              orderedby:"",
              pickupdate:"",
              pickuptime:"",
              organisation_id:null,
              vehicle_id:"",
              vehicle_type_id:2,
              flight:"",
              frequency:"",
              enddate:"",
              remarks:"",
              one_trip_rate:"",
              round_trip_rate:"",
              skipweekends: null,
              skipmondays: null,
              skiptuesdays: null,
              skipwednesdays: null,
              skipthursdays: null,
              skipfridays: null,
              skipsaturdays: null,
              skipsundays: null,
            },
            loading: false,
           disabled: false,
           orgCategory: [],
           isBusy: false,
           updateModal: null,
           confirmdialog: null,
           chooseVehicle:[],
           chooseOtherVehicle:[],
           choosevehicledialog: false,
           driverdetailsdialog: false,
           booking_id: "",
           driverdetails: null,
           coords: {lat: 0, lng: 0},
            destination: {lat: 0, lng: 0},
            labelstart: {
              text: "N/A",
              color: "#C70E20",
              fontWeight: "bold"
            },
            labelend: {
              text: "N/A",
              color: "#C70E20",
              fontWeight: "bold"
            },
            passengers: [
              {
                organisation_id: "",
                id: "",
                name: "",
                telephone: "",
                isTextField: false
              }
            ],
            icon: null,
            imperial: null,
            path: null,
            currentlat: null,
            currentlong: null,
            directionsService: null,
            directionsDisplay: null,
            hidestops:false,
        }
    },
    mounted(){

      this.setLocationLatLng()
      if(this.customers.length == 0 || this.organisations.length == 0 || this.vehicletype.length == 0){
        this.getAddBookings()
      }

      this.$store.state.stop1 = 0
      this.$store.state.stop2 = 0
      this.$store.state.end = 0

    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

    },
    computed: {
      Plabel() {
        return !this.isDelivery ? "Passenger Name*" : "Contact Name*"
      },
      projects() {
        if(this.passengers.length > 0){

          let res = []
          // let data = this.organisations.filter(organisation => organisation.id === parseInt(this.item.organisation_id) )
          let data = this.organisations.filter(organisation => this.passengers.some(item => parseInt(item.organisation_id) === organisation.id ))

          if(data.length > 0) {
            res = data[0].projects
          }
          return res
        }else{
          return []
        }
                
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      vehicletype() {
        return this.$store.state.bookingvehicletype
      },
      customers() {
        return this.$store.state.bookingcustomers
      },
      organisations() {
        return this.$store.state.bookingorganisations
      },
      distance() {
        return this.$store.state.distance

      },
      stop1() {
        return this.$store.state.stop1

      },
      stop2() {
        return this.$store.state.stop2

      },
      end() {
        return this.$store.state.end

      },
      distanceTime() {
        return this.$store.state.distanceTime
      },
      stop1Time() {
        return this.$store.state.stop1Time
      },
      stop2Time() {
        return this.$store.state.stop2Time
      },
      endTime() {
        return this.$store.state.endTime
      }
    },

    watch: {
        distance: function(newVal, oldVal) {
            this.$store.state.distance = newVal
        },
        stop1: function(newVal, oldVal) {
            this.$store.state.stop1 = newVal
        },
        stop2: function(newVal, oldVal) {
            this.$store.state.stop2 = newVal
        },
        end: function(newVal, oldVal) {
            this.$store.state.end = newVal
        }
    },
    serverPrefetch () {
      return this.getAddBookings()
    },
    methods:{
      clearpickupdate() {
        this.date = ""
      },
      clearenddate() {
        this.enddate = ""
      },
      addPassenger () {
        this.passengers.push({
          organisation_id: "",
          id: "",
          name: "",
          telephone: "",
          isTextField: false
        })

      },
      removePassenger (index) {
        this.passengers.splice(index, 1);
      },
      onSwitchchange($event) {
        if($event === true || $event === 'true' || $event === 'on') {
          this.item.project_id = []
        }else {
          this.sub_projects = []
        }
      },
      capitalize(name) {
        const finalSentence = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence
      },
      updateSubprojects(value, index, projectId) {

        if(value !== null) {

          const val = {
                        project_id: projectId,
                        subproject_id: value
                      }

          let index = this.sub_projects.findIndex(data => data.project_id == projectId && data.subproject_id == value);
          if (index > -1) {
            
            this.sub_projects.splice(index, 1, val);
          }else {
            this.sub_projects.unshift(val)
          }

        }else {

          let index = this.sub_projects.findIndex(data => data.project_id == projectId);
          if (index > -1) {
            this.sub_projects.splice(index, 1);
          }

        }
        
      },
      shufflestops() {
        this.hidestops = !this.hidestops
        this.item.stop1 = ""
        this.item.stop1lat = null
        this.item.stop1long = null
        this.item.stop2 = ""
        this.item.stop2lat = null
        this.item.stop2long = null

        this.$store.state.waypoints = []
      },
      setText(indx) {
        
        let find = this.passengers[indx];
        if (find) {
          const container = {};
          container.organisation_id = ''
          container.id = ''
          container.name = ''
          container.telephone = ''
          container.isTextField = !find.isTextField
          this.passengers.splice(indx, 1, container);
        }
        let found = this.passengers[indx];

        if(found.isTextField === true) {
          this.item.telephone = ''
          this.item.customer_id = null
          this.selectcustomer = null

          if (found) {
            const container = {};
            container.organisation_id = ''
            container.id = ''
            container.telephone = ''
            container.name = found.name
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }

        }
        if(found.isTextField === false) {
          this.item.name = ''
          if (found) {
            const container = {};
            container.organisation_id = ''
            container.id = ''
            container.telephone = ''
            container.name = ''
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }
        }
      },
      updateCustomer(value,indx) {
        if(value !== null) {

          let index = this.customers.filter(item => item.id === value )

          let found = this.passengers[indx];
          if (found) {
            found.organisation_id = index[0].organisation_id
            found.id = value
            found.name = index[0].name
            found.telephone = index[0].telephone
          }

        }
      },
      updateOrganisation(value,indx) {
        if(value !== null) {
          let found = this.passengers[indx];
          if (found) {
            found.organisation_id = value
          }
        }
      },
      setLocationLatLng: function() {
        navigator.geolocation.getCurrentPosition(geolocation => {
          this.center = {
            lat: geolocation.coords.latitude,
            lng: geolocation.coords.longitude
          };
        })
      },
      directionFilter (addressData) {
        this.direction = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onPlaceChangedFrom (addressData) {
        this.item.fromlat = addressData.latitude
        this.item.fromlong = addressData.longitude
        this.item.from = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.origin = { lat: addressData.latitude, lng: addressData.longitude }
      },
      onPlaceChangedTo (addressData) {
        this.item.tolat = addressData.latitude
        this.item.tolong = addressData.longitude
        this.item.to = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.destination = { lat: addressData.latitude, lng: addressData.longitude }
      },
      onPlaceChangedStop1 (addressData) {
        this.item.stop1lat = addressData.latitude
        this.item.stop1long = addressData.longitude
        this.item.stop1 = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });
      },
      onPlaceChangedStop2 (addressData) {
        this.item.stop2lat = addressData.latitude
        this.item.stop2long = addressData.longitude
        this.item.stop2 = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });
      },
      onNoResult () {},

     getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },

      proceed() {

        if(this.item.fromlat === null || this.item.fromlat === "" || this.item.tolat === null || this.item.tolat === "") {
              this.$toast.warning('Every location should be picked from google suggestions','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

        if(this.item.stop1 !== "" && this.item.stop2 !== "") {
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), 'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), 'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 !== "" && this.item.stop2 === "") {
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long),'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 !== "") {
          this.$store.state.stop1 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long),'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 === "") {
          this.$store.state.stop1 = 0
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');
        }

        this.confirmdialog = true

      },
       create(){

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data',"Access-Control-Allow-Origin": "*" } };
            let fd = new FormData();
            fd.append('customer_id',this.item.customer_id)
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('to',this.item.to)
            fd.append('from',this.item.from)
            fd.append('enddate',this.enddate)
            fd.append('frequency',this.item.frequency)
            fd.append('distance',(parseFloat(this.stop1) + parseFloat(this.stop2) + parseFloat(this.end)))
            fd.append('fromlat',this.item.fromlat)
            fd.append('fromlong',this.item.fromlong)
            fd.append('tolat',this.item.tolat)
            fd.append('tolong',this.item.tolong)

            fd.append('stop1lat',this.item.stop1lat)
            fd.append('stop1long',this.item.stop1long)
            fd.append('stop2lat',this.item.stop2lat)
            fd.append('stop2long',this.item.stop2long)
            fd.append('stop1',this.item.stop1)
            fd.append('stop2',this.item.stop2)

            fd.append('orderedby',this.item.orderedby)
            fd.append('pickupdate',this.date)
            fd.append('pickuptime',this.time)
            fd.append('flight',this.item.flight)
            fd.append('skipweekends',this.item.skipweekends)
            fd.append('skipmondays',this.item.skipmondays)
            fd.append('skiptuesdays',this.item.skiptuesdays)
            fd.append('skipwednesdays',this.item.skipwednesdays)
            fd.append('skipthursdays',this.item.skipthursdays)
            fd.append('skipfridays',this.item.skipfridays)
            fd.append('skipsaturdays',this.item.skipsaturdays)
            fd.append('skipsundays',this.item.skipsundays)
            fd.append('remarks',this.item.remarks)
            fd.append('one_trip_rate',this.item.one_trip_rate)
            fd.append('round_trip_rate',this.item.round_trip_rate)
            fd.append('organisation_id',this.item.organisation_id)
            fd.append('vehicle_type_id',this.item.vehicle_type_id)
            fd.append('project_id',JSON.stringify(this.item.project_id))
            fd.append('trip_type',this.item.trip_type)
            fd.append('request_type',this.item.request_type)
            fd.append('subprojects',JSON.stringify(this.sub_projects))
            fd.append('passengers',JSON.stringify(this.passengers))
            fd.append('useSubProjects',this.item.useSubProjects)
            fd.append('isDelivery',this.isDelivery)

            axios.post('/bookings', fd, config).then(response=>{

              this.confirmdialog = false

              if(response.data.status == parseInt(200)) {
                if(response.data.data !== null) {
                  // const booking = response.data.data 
                  // this.$store.dispatch('bookingCreated', booking)
                  // booking.status = "fromserver"                 
                  // db.collection('bookings').doc(`${response.data.data.id}-request`).set(booking)
                }
                this.$toast.success('Booking addded successfully','Success',{position: 'topRight',timeout: 7000,})
                // this.clear()
              }

              if(response.data.status == parseInt(201)) {
                this.$toast.success('Booking was successful','Success',{position: 'topRight',timeout: 7000,})
                // this.clear()
              }

              // this.$store.state.waypoints = []
              // this.$store.state.origin = null
              // this.$store.state.destination = null

              //   this.item.to= ""
              //   this.item.from= ""
              //   this.item.distance= ""
              //   this.item.fromlat= ""
              //   this.item.fromlong= ""
              //   this.item.tolat= ""
              //   this.item.frequency= ""
              //   this.item.enddate= ""
              //   this.item.tolong= ""
              //   this.item.name= ""
              //   this.item.telephone=""
              //   this.item.orderedby=""
              //   this.item.pickupdate=""
              //   this.item.pickuptime=""
              //   this.item.organisation_id=""
              //   this.item.vehicle_type_id=null
              //   this.item.flight=""
              //   this.item.remarks=""
              //   this.sub_projects = []
              //   this.item.project_id = []
              //   this.passengers = []

              //   this.time = ""
              //   this.date = ""

              //   this.passengers.push({
              //     organisation_id: "",
              //     id: "",
              //     name: "",
              //     telephone: "",
              //     isTextField: false
              //   })

              //   this.item.stop1 = ""
              //   this.item.stop1lat = null
              //   this.item.stop1long = null
              //   this.item.stop2 = ""
              //   this.item.stop2lat = null
              //   this.item.stop2long = null

                this.loading = false
                this.disabled = false
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

          }
      },
      clear () {
        this.$refs.form.reset()
      }

    }
      
}
</script>