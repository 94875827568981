<template>
    <!-- BEGIN: Content-->
    <div class="debitnotesList mt-3">
      <v-container fluid>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>

                  <div style="padding-top:0 !important;padding-bottom:0 !important;display:flex;justify-content:space-between;align-items:center">
                    <v-btn
                        color="#000000"
                        class="ma-1 white--text"
                        depressed
                        link
                        :to="{name:'newDebitNote'}"
                      >
                        Debit Note
                        <v-icon
                          right
                          dark
                        >
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="my-1 py-0">
            <v-card class="card-shadow py-0">
              <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#008000"
                      buffer-value="0"
                      stream
                      height="3"
                      :active="tableloader"
                    ></v-progress-linear>
                  </div>
              <v-card-title style="display:flex;align-items:center;justify-content:flex-end">
                <v-col md="2" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                  <v-text-field
                      v-model="searchfilter"
                      label="Search"
                      solo
                      hide-details="auto"
                    ></v-text-field>
                </v-col>
              </v-card-title>
              <v-card-text class="px-0 pb-0">
                <div class="classloader">
                   <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="tableloader"/>
                </div>
                <div class="empty-state" v-if="alldebitnotes.length === 0 && !tableloader">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <v-img src="@/assets/icon.png" alt=""></v-img>
                    </div>
                    <div class="empty-state__message">No records available.</div>
                  </div>
                </div>
                <v-data-table
                  dense
                  :headers="fields"
                  :items="alldebitnotes"
                  :item-class="getRowClass"
                  class="elevation-0"
                  loading-text="Loading... Please wait"

                  disable-pagination
                  hide-default-footer
                  item-key="id"
                  id="virtual-scroll-table"
                  v-scroll:#virtual-scroll-table="onScroll"
                  fixed-header
                >

                <template
                    v-if="start > 0"
                    v-slot:body.prepend
                  >
                    <tr>
                      <td
                        :colspan="fields.length"
                        :style="'padding-top:'+startHeight+'px !important'"
                      >
                      </td>
                    </tr>
                  </template>
                  <template
                    v-if="start + perPage < this.debitnotes.length"
                    v-slot:body.append
                  >
                    <tr>
                      <td
                        :colspan="fields.length"
                        :style="'padding-top:'+endHeight+'px !important'"
                      >
                      </td>
                    </tr>
                  </template>

                <template v-slot:body="{ items }">
                  <tbody class="finance">
                    <tr
                        v-for="item in items"
                        :key="item.id"
                        :search="search"
                        @mouseover="selectItem(item)"
                        @mouseleave="unSelectItem()"
                        style="cursor:pointer !important"
                        class="row-color"
                      >
                        <td>{{ moment(item.date).format('MMMM Do YYYY') }}</td>
                        <td>
                          <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                            :to='{name:"showDebitNote",params:{id:item.id}}'>{{ item.debit_note_no }}</RouterLink>
                        </td>
                        <td>
                          <span v-if="item.organisation !== null">{{ item.organisation.name }}</span>
                          <span v-else>Private</span>
                        </td>
                        <td>
                          <RouterLink v-if="item.invoice !== null" style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important" link
                            :to='{name:"showInvoice",params:{id:item.invoice.id}}'>{{ item.invoice.invoice_no }}</RouterLink>
                        </td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.notes }}</td>
                        <td>
                          KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span>
                        </td>
                        <td>

                          <div>
                            
                            <v-btn
                              color="#333333"
                              fab
                              x-small
                              dark
                              style="width:25px !important;height:25px !important"
                              v-if="item.invoice == null"
                              @click="linkcredit(item.id, item.debit_note_no)"
                            >
                              <v-icon size="17">mdi-link-variant</v-icon>
                            </v-btn>

                            <v-btn
                              color="secondary"
                              fab
                              x-small
                              dark
                              class="mx-2"
                              style="width:25px !important;height:25px !important"
                              link
                              :to='{name:"editDebitNote",params:{id:item.id}}'
                            >
                              <v-icon size="17">mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn
                              color="secondary"
                              fab
                              x-small
                              dark
                              style="width:25px !important;height:25px !important"
                              @click="deleteDebit(item.id)"
                            >
                              <v-icon size="17">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </td>
                    </tr>
                  </tbody>
                </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- link credit note -->
      <v-dialog
        v-model="linkpaymentdialog"
        width="400px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="linkform">

          <v-card-title>
            <span style="font-weight:normal !important;font-size:1.1rem !important">Link Debit Note <b>{{ this.item.tolinkreceipt }}</b></span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      :items="invoices" 
                      v-model="item.invoice_id"
                      label="Select Invoice"
                      item-text="invoice_no"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchinvoice"
                    ></v-autocomplete>
                  </v-col>
                  
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="linkpaymentdialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="linkForm"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end link credit note -->

    </div>

</template>
<style scoped>
  .v-data-table /deep/ .sticky-header {
    position: sticky !important;
    top: var(--toolbarHeight) !important;
  }

  .v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset !important;
  }

 #virtual-scroll-table {
    height: 83.5vh;
      max-height: 100%;
    overflow: auto;
  }

  .v-data-table__wrapper td, .v-data-table-header th{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th,.v-data-table-header > th{
      max-width: 170px !important;
      font-weight: 600;
      border:1px solid var(--border-color) !important;
      height:10px !important;
  }
  ::v-deep .v-data-table__empty-wrapper {
      display: none !important;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import Spinner from 'vue-spinkit'
export default {
    name:"debitnotesList",
    components: { Spinner},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          search:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          searchinvoice:null,
          tableloader: false,
          dialog: false,
          updatedialog: false,
          linkpaymentdialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Debit Notes',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'DEBIT NOTE#', value: 'debitnote' },
            { text: 'ORGANISATION', value: 'organisation' },
            { text: 'INVOICE NO', value: 'invoiceno' },
            { text: 'STATUS', value: 'status' },
            { text: 'NOTES', value: 'note' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{},
            isBusy: false,
            loading: false,
           disabled: false,
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            selectedItem: false,
            invoices: []
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      if(!this.alldebitnotes || this.alldebitnotes.length == 0) {
        this.getDebitNotes()
      }

    },
    serverPrefetch () {
      return this.getDebitNotes()
    },
    computed: {
      debitnotes() {
        return this.$store.state.debitnotes.filter(item => {

          this.start = 0

          let invoice_no = "";
          let debit_note_no = "";
          let date = "";
          if(item.date !== null) {
            date = item.date
          }
          if(item.debit_note_no !== null) {
            debit_note_no = item.debit_note_no
          }
          if(item.invoice !== null) {
            invoice_no = item.invoice.invoice_no
          }
          return debit_note_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter)
        })
      },
      alldebitnotes() {
        return this.debitnotes.slice(this.start, this.perPage+this.start)
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.debitnotes.length - this.start);
      }
    },
    methods:{
      getRowClass(item) {
        return 'row-color' 
      },
      selectItem (item) {
        this.selectedItem = item
      },
      unSelectItem (item) {
        this.selectedItem = false
      },
      onScroll(e) {
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.debitnotes.length ?
            this.debitnotes.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
 
    },
      async getDebitNotes(){
        this.tableloader = true
        this.$store
          .dispatch('getDebitNotes')
          .then(() => {
            this.tableloader = false
          })
          .catch(() => {
            this.tableloader = false
          })
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        },
      deleteDebit(id){
            if(confirm("Are you sure to delete debit note ?")){
                axios.delete(`/debit-notes/${id}`).then(response=>{
                  this.$toast.success('Debit note deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }).catch(error=>{
                    console.log(error)
                })
            }
        },

        linkcredit(id, creditNo) {
        let index = this.alldebitnotes.filter(item => item.id === id );
        this.invoices = index[0].organisation.invoices
        this.item.tolinkreceipt = creditNo
        this.item.id = id
        this.linkpaymentdialog = true
      },
      async linkForm() {
        if (this.$refs.linkform.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('id',this.item.id)
          fd.append('invoice_id',this.item.invoice_id)

          axios.post('/debit-note/link-invoice', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Invoice linked successfully','Success',{position: 'topRight',timeout: 7000,})
              this.clear()
              this.linkpaymentdialog = false
            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },
    }
}
</script>