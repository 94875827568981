<template>
    <!-- BEGIN: Content-->
    <div class="showCreditNote mt-3">
      <v-container fluid>
        <v-form class="pt-4"  enctype="multipart/form-data" v-model="valid" ref="form">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col cols="12" sm="12" style="padding-top:0 !important">
              <v-card class="card-shadow px-0 pb-0">
                <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-3 pr-4">
                  <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                    <v-breadcrumbs
                      :items="breadcrumbs"
                      divider="/"
                    ></v-breadcrumbs>

                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col cols="12" md="6" lg="5" xl="6" sm="12" class="my-1 py-0">
              <v-card class="card-shadow py-2 px-1 invoice">
                <v-list two-line>
                    <v-list-item-group
                    >
                      <template v-for="(item,index) in allcreditnotes">
                        <v-list-item :key="item.invoice_no" @click="viewPayment(item.id)">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>
                                <p class="text--primary mb-2" style="text-transform:uppercase;font-size:.75rem !important;width:100%;font-weight:500">
                                  {{ item.organisation.name }}
                                </p>
                              </v-list-item-title>
                              <v-list-item-subtitle><p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;width:100%;color:#212529 !important">
                              <span style="color:#F98525;">{{ item.credit_note_no }}</span> | {{ moment(item.date).format('D MMM YYYY') }}
                              </p></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-list-item-action-text>
                                <p class="text--primary mb-2" style="text-transform:uppercase;font-size:.85rem !important;width:100%;font-weight:500 !important;text-align:right;">
                                  KES{{ item.amount | currency(' ') }}
                                </p>
                                <p class="text--primary mb-0" style="color:#F98525 !important;text-align:right;text-transform:uppercase;font-size:.75rem !important;width:100%;">{{ item.status }}</p>
                              </v-list-item-action-text>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                          v-if="index < allcreditnotes.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-pagination
                    v-model="page"
                    :length="Math.ceil(allcreditnotes.length/perPage)"
                  ></v-pagination>
              </v-card>
            </v-col>
            <v-col  cols="12" md="6" lg="7" xl="6" sm="12">
              <v-card class="card-shadow details p-0 mx-0">
                <v-row class="mx-0" style="background:#f5f7f9 !important">
                  <v-col cols="12" style="justify-content:flex-end;display:flex">

                    <v-btn
                      color="#dedede"
                      class="mx-2 mb-3"
                      text
                      link
                      :to='{name:"editCreditNote",params:{id:item.id}}'
                    >
                    <v-icon>mdi-pencil</v-icon>
                    <span style="font-size:.75rem !important;color:#444 !important">Edit</span>
                  </v-btn>

                  <v-btn
                      color="#dedede"
                      class="mx-2 mb-3"
                      text
                      :loading="loadingpdf"
                      @click="downloadpdf"
                    >
                    <v-icon>mdi-file-pdf-box</v-icon>
                    <span style="font-size:.75rem !important;color:#444 !important">Pdf</span>
                  </v-btn>

                  </v-col>
                </v-row>
                <v-card-text class="py-5" v-if="details !== null" style="padding-right:2rem !important;padding-left:2rem !important">
                  <div class="ribbon ribbon-top-right">
                    <span>{{ details.status }}</span>
                  </div>
                  <v-row class="mt-4">
                    <v-col cols="12" md="6">
                      <img src="/assets/images/logo/logo.png" style="width:50% !important;">
                      <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                      Kandara Road, Hse 9, Kileleshwa</br>
                      Telephone: 0202011846/2011824</br>
                      Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                      Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                      PIN NO: P051199717F | VAT NO: P051199717F</p>

                      <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                      <p style="color:#F98525;font-size:.8rem !important;margin-top:-.8rem !important">{{ details.organisation.name }}</p>
                      <p style="color:#F98525;font-size:.75rem !important;margin-top:-.8rem !important">{{ details.organisation.address }}</p>
                    </v-col>
                    <v-col cols="12" md="6" style="text-align:right">
                      <h2 style="font-size:1.7rem !important">CREDIT NOTE</h2>
                      <p>#{{ details.credit_note_no }}</p>
                      <p style="margin-top:3rem !important;font-size:.75rem !important">Amount</p>
                      <p style="font-size:1.2rem !important;color:#333 !important;font-weight:500 !important;margin-top:-1.1rem !important">{{ details.amount | currency(' ') }}</p>
                      <p style="margin-top:5rem !important;display:flex;justify-content:end">
                        <v-simple-table>
                          <template v-slot:default>
                              <tr>
                                <td class="py-2" style="padding-right:3rem !important">Credit Date:</td>
                                <td class="py-2">{{ moment(details.date).format('Do MMM YYYY') }}</td>
                              </tr>
                          </template>
                        </v-simple-table>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row class="mt-5 pb-0">
                    <v-col cols="12" lg="12" class="mt-0">
                      <p>
                        <v-simple-table>
                          <template v-slot:default>
                              <tr style="background:#eff0f1">
                                <td style="padding:.5rem !important;font-size:.75rem !important">#</td>
                                <td style="padding:.5rem !important;font-size:.75rem !important">Description</td>
                                <td style="padding:.5rem !important;font-size:.85rem !important;text-align:right !important">Amount</td>
                              </tr>
                              <tr>
                                <td style="padding:.7rem !important;font-size:.75rem !important">1</td>
                                <td style="padding:.7rem !important;font-size:.75rem !important">{{ details.notes }}</td>
                                <td style="padding:.7rem !important;font-size:.75rem !important;text-align:right !important">KES{{ details.amount | currency(' ') }}</td>
                              </tr>
                          </template>
                        </v-simple-table>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 d-flex" style="justify-content:end !important">
                    <v-col
                        cols="12"
                        sm="8"
                        md="6"
                      >

                      <v-list two-line>
                          <v-list-item-group
                          >
                            <template>

                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <p class="text--primary mb-0" style="font-size:.85rem !important;width:100%">
                                        Sub Total
                                      </p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-action-text>
                                      <p class="text--primary mb-0" style="font-size:.85rem !important;width:100%;">
                                        KES {{ 

                                          details.amount | currency(' ')

                                         }}
                                      </p>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>
                              <v-divider
                              ></v-divider>
                              <v-divider
                              ></v-divider>
                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <p class="text--primary mb-0" style="font-size:.95rem !important;width:100%;font-weight:bold">
                                        Total (KES)
                                      </p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-action-text>
                                      <p class="text--primary mb-0" style="font-size:.95rem !important;width:100%;font-weight:bold">
                                        {{ 

                                          details.amount | currency(' ')

                                         }}
                                      </p>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>

                            </template>
                          </v-list-item-group>
                        </v-list>

                    </v-col>
                  </v-row>
                  
                  <v-row v-if="details.invoice !== null" class="mt-0 pb-2">
                    <v-col cols="12" lg="12">
                      <hr class="mt-2">
                    </v-col>
                    <v-col cols="12" lg="12" class="mt-5">
                      <h3 style="font-weight:500 !important" class="mb-2">Credit for</h3>
                      <p>
                        <v-simple-table>
                          <template v-slot:default>
                              <tr style="background:#eff0f1">
                                <td style="padding:.7rem !important;font-size:.75rem !important">Invoice Number</td>
                                <td style="padding:.7rem !important;font-size:.75rem !important">Invoice Date</td>
                                <td style="padding:.7rem !important;font-size:.75rem !important">Invoice Amount</td>
                              </tr>
                              <tr>
                                <td style="padding:.7rem !important;font-size:.75rem !important">{{ details.invoice.invoice_no }}</td>
                                <td style="padding:.7rem !important;font-size:.75rem !important">{{ moment(details.invoice.date).format('Do MMM YYYY') }}</td>
                                <td style="padding:.7rem !important;font-size:.75rem !important">KES{{ details.invoice.amount | currency(' ') }}</td>
                              </tr>
                          </template>
                        </v-simple-table>
                      </p>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-form>
      </v-container>
    </div>

</template>
<style scope>
.details .v-data-table__wrapper table td{
  font-size:.75rem !important;
}

.details .v-list--two-line .v-list-item, .details .v-list-item--two-line {
      min-height: 4px !important;
  }

  .invoice .col-12 {
    padding: 4px 12px !important;
  }
  .invoice .v-list--two-line .v-list-item, .invoice .v-list-item--two-line {
      min-height: 4px !important;
  }
  .invoice .v-list-item__content {
    padding-left: 0 !important;
  }
  .v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
    z-index: 99 !important;
  }
  .v-bottom-navigation{
    align-items: center !important;
  }
  .details .col-12 {
    padding: 4px 12px !important;
  }
  #virtual-scroll-table {
    max-height: 70vh;
    overflow: auto !important;
  }

  /*ribbon*/
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }

  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #F9A825;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 7px 0;
    background-color: #F9A825;
    box-shadow: 0 2px 1px rgba(0,0,0,.1);
    color: #fff;
    font: 600 14px/1 'Lato', sans-serif !important;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    right: 22px;
  }
  .ribbon-top-right::after {
    bottom: 22px;
    left: 0;
  }
  .ribbon-top-right span {
    right: -27px;
    top: 29px;
    transform: rotate(-45deg);
  }

  .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '../../../services/ability'

export default {
    name:"showCreditNote",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          page: 1,
          perPage: 10,
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:'',
          search:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Credit Notes',
              disabled: false,
              href: '/organisation/credit-notes',
            },
            {
              text: 'Credit Note Details',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Date', value: 'date' },
            { text: 'Description', value: 'description' },
            { text: 'Amount', value: 'amount' },
           ],
          item:{
             id: null
          },
            isBusy: false,
            loading: false,
            loading1: false,
            loadingpdf: false,
           disabled: false,
           vat: null
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      this.item.id = this.$route.params.id
      this.getDetails()
      if(!this.allcreditnotes || this.allcreditnotes.length == 0) {
        this.getCreditNotes()
      }

    },
    computed: {
      allcreditnotes() {
        return this.$store.state.showcreditnotes
      },
      details() {
        return this.$store.state.creditnotedetail
      },
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
    },
    methods:{

      viewPayment(id) {
        this.item.id = id
        this.getDetails()
      },
      async getCreditNotes(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowCreditNotes')
      },
      async getDetails() {
        this.$store.state.tableloader1 = true
        await axios.get(`/viewsinglecreditenote/${this.item.id}`).then(response=>{
          this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.creditnotedetail = response.data.creditnote
            }
        }).catch(error=>{
          this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/credit-note/download', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisation.name}-${this.details.credit_note_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },

      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);
          this.start = rows + this.perPage > this.bookings.length ?
            this.bookings.length - this.perPage: rows;
          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
  
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      }

    }
}
</script>