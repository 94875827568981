<template>
    <!-- BEGIN: Content-->
    <div class="permissionList mt-3">
      <v-container fluid>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" style="padding-top:0 !important">
            <v-card class="card-shadow px-0 pb-0">
              <v-row style="margin-top:0 !important;margin-bottom:0 !important" class="py-1 pr-4">
                <v-col md="12" sm="12" class="d-flex my-0 py-0" style="justify-content:space-between">
                  <v-breadcrumbs
                    :items="breadcrumbs"
                    divider="/"
                  ></v-breadcrumbs>

                  <v-btn
                    color="#000000"
                    class="ma-1 white--text"
                    depressed
                    @click.stop="dialog = true"
                  >
                    Permission
                    <v-icon
                      right
                      dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col md="12" sm="12" class="py-0 my-1">
            <v-card class="card-shadow py-3">
              <v-data-table
                dense
                :headers="fields"
                :item-class="getRowClass"
                :items="permission"
                class="elevation-0"
                loading-text="Loading... Please wait"

                disable-pagination
                hide-default-footer
                id="virtual-scroll-table"
                item-key="id"
                fixed-header
                :height="height"
                v-scroll:#virtual-scroll-table="onScroll"
              >
                <template v-slot:item.#="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            small
                            v-on="on">
                          <v-icon>ti-arrow-circle-down</v-icon>
                        </v-btn>
                      </template>
                      
                      <v-list-item-group
                        color="primary"
                        class="actions"
                        >
                          <v-list-item
                            @click="editType(item.id)"
                          >
                              <v-list-item-icon>
                                <v-icon color="#000000">mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item-content>
                          </v-list-item>

                        <v-list-item
                          link
                          @click="deleteItem(item.id)"
                          >
                              <v-list-item-icon>
                                <v-icon>mdi-delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Remove</v-list-item-title>
                              </v-list-item-content>
                        </v-list-item>

                      </v-list-item-group>
                    </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="450px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Permission</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Permission*"
                      v-model="item.permission"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="450px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Permission</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Permission*"
                      v-model="item.updatepermission"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>
<style scope>
  #virtual-scroll-table {
      height: 83.5vh;
      overflow: auto;
    }

    .v-data-table__wrapper td, .v-data-table-header th{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th,.v-data-table-header > th{
        max-width: 170px !important;
        font-weight: 500;
        border:1px solid var(--border-color) !important;
    }
</style>
<script>
import axios from 'axios'
import {ability} from '../../services/ability'

export default {
    name:"permissionList",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'User Config',
              disabled: false,
              href: '',
            },
            {
              text: 'Permissions',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Name', value: 'name' },
            { text: 'Action', value: 'action' },
           ],
          item:{
                permission:"",
                updatepermission: "",
                updateID: "",
                _method:"patch"
            },
            loading: false,
           disabled: false,
           permission: [],
           height: '100%',
           isBusy: false,
            updateModal: null
        }
    },
    beforeMount() {
      // if(!ability.can('user-management', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    mounted(){
      this.getPermission()
    },
    methods:{
      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   

        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.permission.length ?
            this.permission.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      getRowClass(item) {
        return 'row-color' 
      },
      async getPermission(){
        this.isBusy = true
        await axios.get('/permission').then(response=>{
            this.isBusy = false
            this.permission = response.data.permission
        }).catch(error=>{
            console.log(error)
            this.isBusy = false
        })
      },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('permission',this.item.permission)

            await axios.post('/permission', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.res = response.status
              if(this.res === parseInt(200))
                this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                this.getPermission()
                this.clear()
              this.item.permission = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

        }

        },
        clear () {
          this.$refs.form.reset()
        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('permission',this.item.updatepermission)
            fd.append('_method',this.item._method)

            await axios.post(`/permission/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.res = response.status
              if(this.res === parseInt(200))
                this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                this.updatedialog = false
                this.getPermission()
              this.item.updatepermission = ''
              this.item.updateID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async editType(id) {
        let index = this.permission.filter(item => item.id === id );
        this.item.updatepermission = index[0].name
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      deleteItem(id){
            if(confirm("Are you sure to delete permission ?")){
                axios.delete(`/permission/${id}`).then(response=>{
                  this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                    this.getPermission()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

    }
}
</script>