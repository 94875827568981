<template>
    <!-- BEGIN: Content-->
    <div class="customerStatement mt-3">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
           
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-0 mt-2">
              <v-row class="px-3 py-0">
                <v-col cols="12" ms="12">
                  <v-form class="py-3"  enctype="multipart/form-data" v-model="valid" ref="form">
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        xl="2"
                        class="py-0"
                        >
                      <date-range-picker
                          ref="picker"
                          :showWeekNumbers="showWeekNumbers"
                          :showDropdowns="showDropdowns"
                          :autoApply="autoApply"
                          opens="right"
                          :always-show-calendars="alwaysshowcalendars"
                          v-model="dateRange"
                          @select="updateValues"
                         >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                <v-text-field
                                  label="Select date range"
                                  :rules="fieldRules"
                                  v-model="date"
                                  solo
                                  hide-details
                                ></v-text-field>
                            </template>
                        </date-range-picker>
                    </v-col>

                    <v-col
                        cols="12"
                        md="3"
                        xl="2"
                        class="py-0"
                      >
                        <v-autocomplete
                            :items="organisations"
                            label="Select organisation"
                            v-model="item.organisation_id"
                            clearable
                            @change="selectOrg"
                            @click:clear="item.organisation_id = ''"
                            item-text="name"
                            item-value="id"
                            :search-input.sync="searchorganisation"
                            solo
                            hide-details
                          ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        xl="2"
                        class="py-0"
                      >
                        <v-autocomplete
                          :items="projects"
                          label="Select project"
                          v-model="item.project_id"
                          :value="item.project_id"
                          item-text="name"
                          item-value="id"
                          :search-input.sync="searchproject"
                          @change="selectProject"
                          @click:clear="item.project_id = ''"
                          solo
                          hide-details
                        ></v-autocomplete>
                      </v-col>

                    <v-col
                        cols="12"
                        md="1"
                        xl="3"
                        sm="12"
                        class="py-0"
                      >
                        <v-btn
                            depressed
                            color="primary darken-1"
                            :loading="loading"
                            :disabled="!valid"
                            @click="fetchreport"
                          >
                            Search
                          </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        md="2"
                        xl="3"
                        sm="12"
                        class="py-0"
                        style="text-align:right !important"
                      >

                      <v-btn
                            color="#000000"
                            class="mx-1 white--text"
                            depressed
                            :loading="loadingpdf"
                            @click="exportpdf"
                          >
                            <v-icon
                              dark
                            >
                              mdi-file-pdf-box
                            </v-icon>
                          </v-btn>

                      <v-btn
                        color="#dddddd"
                        class="black--text"
                        depressed
                        :loading="loadingexcel"
                        @click="exportexcel"
                      >
                        <v-icon
                          dark
                        >
                          mdi-file-excel-box
                        </v-icon>
                      </v-btn>
                        
                    </v-col>

                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row style="position:relative !important;z-index:1 !important">
          <v-col cols="12" class="my-1 py-0">
            <v-card class="card-shadow py-3 mt-3">
              <v-row class="mb-1 mt-1">
                
              </v-row>
              <v-row style="margin-bottom:0 !important" class="px-3 mt-2">
                <v-col md="12" cols="12" sm="12" class="px-1 filters" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;align-items:center;justify-content:space-between">

                  <div class="px-3">
                    <p style="font-size:1.2rem !important;font-weight:500 !important;margin-top:-1rem !important">Customer Statement</p>
                    <p style="font-size:.9rem !important;font-weight:400 !important" v-if="orgname == null">Absolute Cabs</p>
                    <p style="font-size:.9rem !important;font-weight:400 !important" v-else>{{ orgname }}</p>
                    <p style="font-size:.9rem !important;margin-top:-1rem !important;font-weight:400 !important;">From {{ moment(daterange.start).format('MMMM Do YYYY') }} To {{ moment(daterange.end).format('MMMM Do YYYY') }}</p>
                  </div>

                  <v-col md="2" cols="12" sm="12" class="px-1 cardheading" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                    <v-text-field
                      v-model="searchfilter"
                      label="Search"
                      solo
                    ></v-text-field>
                  </v-col>

                </v-col>
              </v-row>
              <v-data-table
                dense
                :headers="fields"
                :items="allinvoices"
                :item-class="getRowClass"
                class="elevation-0"
                :search="searchfilter"
                loading-text="Loading... Please wait"

                disable-pagination
                hide-default-footer
                item-key="id"
                id="virtual-scroll-table"
                v-scroll:#virtual-scroll-table="onScroll"
                fixed-header
                >

                <template
                  v-if="start > 0"
                  v-slot:body.prepend
                >
                  <tr>
                    <td
                      :colspan="fields.length"
                      :style="'padding-top:'+startHeight+'px !important'"
                    >
                    </td>
                  </tr>
                </template>
                <template
                  v-if="start + perPage < this.sinvoices.length"
                  v-slot:body.append
                >
                  <tr>
                    <td
                      :colspan="fields.length"
                      :style="'padding-top:'+endHeight+'px !important'"
                    >
                    </td>
                  </tr>
                </template>

                <template v-slot:body="{ items }">
                  <tbody class="finance">
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :search="search"
                      style="cursor:pointer !important"
                      class="row-color"
                      >
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;color:darkgrey !important">{{ moment(item.posting_date).format('MMMM Do YYYY') }}</p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;" v-if="item.invoice != null">{{ item.invoice.invoice_no }}</p>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;" v-if="item.payment != null">{{ item.payment.payment_no }}</p>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;" v-if="item.creditnote != null">{{ item.creditnote.credit_note_no }}</p>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;" v-if="item.debitnote != null">{{ item.debitnote.debit_note_no }}</p>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;" v-if="item.reversal != null">{{ item.reversal.reversal_no }}</p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" v-if="item.invoice != null" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ item.invoice.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" v-if="item.payment != null" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ item.payment.paidamount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" v-if="item.creditnote != null" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ item.creditnote.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" v-if="item.debitnote != null" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ item.debitnote.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" v-if="item.reversal != null" style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ item.reversal.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:.75rem !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:700 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            invoices.reduce( (acc, item) => 
                            {  

                              if(item.invoice != null) {
                                 acc += parseFloat(item.invoice.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                          </p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:700 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            invoices.reduce( (acc, item) => 
                            {  

                              if(item.payment != null) {
                                 acc += parseFloat(item.payment.paidamount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                          </p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:700 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            invoices.reduce( (acc, item) => 
                            {  

                              if(item.creditnote != null) {
                                 acc += parseFloat(item.creditnote.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                          </p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:700 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            invoices.reduce( (acc, item) => 
                            {  

                              if(item.debitnote != null) {
                                 acc += parseFloat(item.debitnote.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                          </p>
                        </td>
                        <td>
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:.75rem !important;font-weight:700 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            invoices.reduce( (acc, item) => 
                            {  

                              if(item.reversal != null) {
                                 acc += parseFloat(item.reversal.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                          </p>
                        </td>
                      </tr>
                  </tbody>
                </template>
              </v-data-table>
              <v-row class="mb-1 mt-1">
                <v-col cols="12" class="text-center mt-3 mb-3 pt-3" style="display:flex;justify-content:end !important;padding-right:4rem;flex-direction:column">
                  <p class="mb-0" style="font-size:.9rem !important;font-weight:400 !important;text-transform:uppercase;text-align:right">Overal Balance</p>

                  <p style="font-size:1rem !important;font-weight:700 !important;text-transform:uppercase;text-align:right">
                    KES{{ 
                        
                          invoices.reduce( (acc, item) => 
                          {  

                            if(item.invoice != null) {
                               acc += parseFloat(item.invoice.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0) 
                          -
                          (invoices.reduce( (acc, item) => 
                          {  
                            if(item.payment != null){
                               acc += parseFloat(item.payment.paidamount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0)
                          +
                          invoices.reduce( (acc, item) => 
                          {  
                            if(item.creditnote != null){
                               acc += parseFloat(item.creditnote.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0))
                          +
                          ((invoices.reduce( (acc, item) => 
                          {  
                            if(item.debitnote != null){
                               acc += parseFloat(item.debitnote.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0)
                          +
                          invoices.reduce( (acc, item) => 
                          {  
                            if(item.reversal != null){
                               acc += parseFloat(item.reversal.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0)))
                           | currency(' ')
                        }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>
</template>
<style scope>
    .spinner-border{
      margin-left: .8rem !important;
    }
    .btn-dark2{
      background: #F9780D;
      color: #fff;
    }
    .dark-layout .card-app-design .design-planning-wrapper .design-planning{
      background: #283046;
    }
    .v-select{
      width: 100%;
    }
    .design-planning .card-text{
      font-size: .8rem !important;
    }
    .range-date-input{
      background: transparent !important;
      font-size: .9rem;
    }
    .input-group-merge .input-group-text{
      background: transparent !important;
      border-right: none !important;
    }
    .picker.range-date-picker{
      position: fixed !important;
      margin-top: 2.4rem !important;
      z-index: 100 !important;
    }
    .fa-check{
      color: #35D080 !important;
    }
    .fa-car{
      color: #ffffff !important;
    }
    .fa-user{
      color: #7367F0 !important;
    }
    .fa-close{
      color: #24D4E9 !important;
    }
    .fa-edit{
      color: #FF9F43 !important;
    }
    .ti-trash{
      color: #F96E6F !important;
    }

    .fa-check, .fa-car, .fa-close, .fa-edit, .ti-trash, .fa-user{
      margin-left: .5rem;
      font-size: .8rem;
    }

    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }
    @media (max-width: 767px){
        .list-group-item {
          padding: 0.75rem 0rem !important;
      }
       .list-group a {
          padding: .8rem 0rem !important;
        }
        .bullet.bullet-sm {
          margin-left: .7rem!important;
        }
    }
    .dropdown-item{
      font-size: .85rem !important;
    }
    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }
    .table tr td{
      font-size: .77rem !important;
      font-weight: 400 !important;
    }
    .table tr th{
      font-size: .7rem !important;
      font-weight: 600 !important;
    }
    .mapmodal .vs__dropdown-toggle{
      border: none;
      box-shadow: 0 3px 8px 0 rgba(11,10,25,.49)!important;
    }
    .dropdown-toggle::after{
      background-image: none !important;
    }
    ::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
        font-size: 0 !important;
    }
    .nav-item.b-nav-dropdown.dropdown{
      display: flex;
    }
    .dropdown-menu.show{
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%);
      background:#161D31 !important;
    }
    .ecommerce-header-items .list-group a{
      white-space: nowrap;
      padding: .5rem 1.5rem;
      font-size: .8rem;
    }
    .ecommerce-header-items .list-group a:first-child{
      border-bottom-left-radius: .350rem !important;
      border-top-left-radius: .350rem !important;
    }
    .ecommerce-header-items .list-group a:last-child{
      border-bottom-right-radius: .350rem !important;
      border-top-right-radius: .350rem !important;
    }
    
    .v-data-table /deep/ .sticky-header {
      position: sticky !important;
      top: var(--toolbarHeight) !important;
    }

    .v-data-table /deep/ .v-data-table__wrapper {
      overflow: unset !important;
    }

   #virtual-scroll-table {
      height: 50vh;
      max-height: 100%;
      overflow: auto;
    }
    .v-data-table__wrapper td, .v-data-table-header th{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th,.v-data-table-header > th{
        max-width: 170px !important;
        font-weight: 600;
        border:1px solid var(--border-color) !important;
        height:10px !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import {ability} from '@/services/ability'
import { format, parseISO } from 'date-fns'
import moment from 'moment'

export default {
    name:"customerStatement",
    components: { DateRangePicker },
    data(){
        return {
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Statements',
              disabled: false,
              href: '/organisation/statements',
            },
            {
              text: 'Customer Statements',
              disabled: false,
              href: '',
            },
          ],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],

          dateRange: {
                      startDate: null,
                      endDate: null
                     },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",

          searchfilter:'',
          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           Drivers: [],
           item: {
            organisation_id: '',
            project_id: ''
           },
           fields: [
            { text: 'DATE',value: 'date' },
            { text: 'REF NO', value: 'refno' },
            { text: 'INVOICE AMOUNT', value: 'invoiceamount' },
            { text: 'PAYMENT AMOUNT', value: 'paymentamount' },
            { text: 'CREDIT AMOUNT', value: 'creditamount' },
            { text: 'DEBIT AMOUNT', value: 'debitamount' },
            { text: 'REVERSAL AMOUNT', value: 'reversalamount' },
            // { text: 'BALANCE', value: 'balance' },
           ],
            search:null,
            searchproject: null,
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            searchorganisation: null,
            searchproject: null,
            show: false,
            dates: {},
            daterange: {
              start: "",
              end: ""
            },
            allbookingreports: [],
            loadingexcel: false,
            loadingpdf: false,
            loadingsearch: false,
            invoices: [],
            orgname: null,
            rowHeight: 25,
            perPage: 200,
            start: 0,
            timeout: null,
            height: '100%'
 
        }
    },
    beforeMount() {
      if(!ability.can('view-reports', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    serverPrefetch () {
      return this.getBookings()
    },
    mounted(){

      this.getInvoices()

      if(!this.organisations || this.organisations == 0) {
        this.getOrganisation()
      }

    },
    computed: {
      organisations() {
        return this.$store.state.organisations
      },
      projects() {
        if(this.item.organisation_id){
          let data = this.organisations.filter(organisation => organisation.id === parseInt(this.item.organisation_id) )
          let res = data[0].projects
          return res
        }else{
          return []
        }
                
      },
      sinvoices () {
        return this.invoices
      },
      allinvoices () {
        return this.sinvoices.slice(this.start, this.perPage+this.start)
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.sinvoices.length - this.start);
      }
    },
    methods:{
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      getRowClass(item) {
        return 'row-color' 
      },
      async getOrganisation(){
        this.isBusy = true
        this.$store
          .dispatch('getOrganisation')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      onScroll(e) {
        this.timeout && clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            const { scrollTop } = e.target;
            const rows = Math.ceil(scrollTop / this.rowHeight);

            this.start = rows + this.perPage > this.sinvoices.length ?
              this.sinvoices.length - this.perPage: rows;

            this.$nextTick(() => {
              e.target.scrollTop = scrollTop;
            });
          }, 20);
   
      },
      getDate(data) {
        return Vue.filter('dateF')(data)
      },
      exportexcel(){
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('project_id',this.item.project_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/statement/export/fileexcel', fd, config).then(response=>{
          this.loadingexcel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'customer_statement.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('project_id',this.item.project_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/statement/export/filepdf', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'customer_statement.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
      getInvoices(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/organisations/statement', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      fetchreport(){
        if (this.$refs.form.validate()) {
          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('project_id',this.item.project_id)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

          axios.post('/organisations/statement', fd, config).then(response=>{
            this.loading = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.statements
            }
            
          })
          .catch(() => {
            this.loading = false
            console.log("Unexpected error occured")
          })
        }
      },
      async selectOrg(id) {
        let index = this.organisations.filter(item => item.id === id )
        if(index.length > 0) {
          this.orgname = index[0].name
        }else {
          this.orgname = null
        }
         
      },
      async selectProject(id) {

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('organisation_id',this.item.organisation_id)
        fd.append('project_id',id)

        await axios.post('/invoice/bookings', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {
              this.$store.dispatch('InvoiceBookings', response.data.bookings)
            }
        }).catch(error=>{
            console.log(error)
        })
      },

    }
}
</script>